.fact-figures {
    background: url(/assets/img/background/bg_zahlen-fakten.webp) center
        no-repeat;
    background-size: cover;
    color: $white;
    padding: 35px 0 40px 0;
    h3 {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 25px;
        @include brk-mobile {
            margin-bottom: 5px;
        }
    }
    .number-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ff-number {
            font-size: 3rem;
            margin: 20px 20px 6px 20px;
        }
        .ff-text {
            font-size: 14px;
            font-weight: 400;
        }
    }
}
