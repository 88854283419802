.apply-now-banner {
    background-color: $light-blue;
    padding: 20px 0;
    // &:focus * {
    //     outline: 5px auto $pink;
    // }
    &.layout2menu {
        background: url($img-path+'/background/bg_sub_onlinetest.webp') no-repeat
            center;
        background-size: cover;
        @include brk-desktop {
            margin-bottom: 20px;
        }
    }
    &.layout1main {
        .btn-text {
            display: flex;
            justify-content: center;
            @include brk-mobile {
                flex-direction: column;
            }
        }
        span {
            @include brk-desktop {
                margin-right: 10px;
            }
        }
    }
    .btn-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include brk-mobile {
            margin-top: 10px;
            justify-content: center;
        }
        &.layout2menu {
            margin-top: 10px;
            justify-content: center;
        }
        .btn {
            color: $light-blue;
        }
    }
    span {
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        &.layout2menu {
            font-size: 28px;
            font-weight: 500;
        }
    }
    .sub-hedline {
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 5px;
    }
}
