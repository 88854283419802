.empty-spacing {
    &.mittel {
        padding-top: 30px;
        @include brk-mobile {
            padding-top: 20px;
        }
    }
    &.gross {
        padding-top: 60px;
        @include brk-mobile {
            padding-top: 40px;
        }
    }
}
