.body-links {
    overflow: hidden;
    .container {
        .content-box {
            .headLine {
                height: 32px;
                color: $blue70;
                font-size: 26px;
                font-weight: 300;
                line-height: 30px;
                margin-bottom: 15px;
            }
            .linkText {
                height: 41px;
                // width: 384px;
                color: #00a0e5;
                font-size: 16px;
                font-weight: 500;
                line-height: 43px;
                background-image: url(/assets/img/elements/ico_nxt_gray.webp);
                background-position: center right;
                background-repeat: no-repeat;
                border-bottom: 1px solid $light-grey;
                .SubText {
                    display: inline-block;
                    color: #00a0e5;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 43px;
                }
                .SubText:hover {
                    text-decoration: underline;
                }
            }

            .subOutside {
                // height: 162px;
                // width: 380px;
                padding: 5px;
                border: 1px solid $light-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                .subRow {
                    // height: 142px;
                    width: 100%;
                    // width: 360px;
                    border: 1px solid $light-grey;
                    // opacity: 0.4;
                    background-color: $light-grey;
                    margin: 0;
                    .plainText {
                        // height: 53px;
                        // width: 191px;
                        color: #00a0e5;
                        font-family: Roboto;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        margin: auto;
                        padding: 25px;
                    }
                    .subLineBox {
                        // height: 142px;
                        // width: 142px;
                        padding: 25px;
                        background: $light-blue url(/assets/img/background/bg-sub.bewerben.webp) no-repeat center;
                        display: flex;
                        background-size: cover;
                        align-items: center;
                        justify-content: center;
                        .subLine {
                            // height: 66px;
                            width:100%;
                            color: $white;       
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 22px;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }

            .buttonBox {
                height: 162px;
                // width: 380px;
                background-color: $light-blue;
                margin-bottom: 20px;
                h2 {
                    color: $white;
                    font-family: Roboto;
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 33px;
                    text-align: center;
                    padding-top: 30px;
                }
                h4 {
                    color: $white;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 19px;
                    text-align: center;
                    margin-top: 20px;
                }
                .bt-div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    .Linkbutton {
                        cursor: pointer;
                        border: 0.8px solid $white;
                        border-radius: 15px;
                        background-color: $white;
                        text-align: center;
                        position: center;
                        .buttonCaption {
                            color: #00a0e5;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.8px;
                            line-height: 16px;
                            text-align: center;
                        }
                    }
                    .Linkbutton:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

@include brk-mobile {
    .body-links {
        .container {
            .content-box {
                background-color: $light-blue;
                .subOutside {
                    display: none;
                }
                .buttonBox {
                    display: none;
                }
                .headLine {
                    color: $white;
                    margin: 0 20px;
                }
                .linkText {
                    color: $white;
                    margin: 0 20px;
                    .SubText {
                        color: $white;
                    }
                }
            }
        }
    }
}
