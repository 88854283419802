.three-column-text{
.container{
.row{
.Column{
                  .title{
                          height: 39px;
                          width: 380px;
                          color: $blue70;
                          font-family: Roboto;
                          font-size: 18px;
                          font-weight: 500;
                          letter-spacing: 0.12px;
                          line-height: 24px;
                   }
                   .content{
                            height: 100%;
                            width: 100%;
                            font-family: Roboto;
                            font-size: 16px;
                            line-height: 24px;}}}}}

