.career-opportunities {
    padding: 40px 0 15px 0;

    .career-image img {
        width: 100%;
    }
    .btn-block-right {
        margin-top: 5px;
    }
    .btn-mobile {
        margin-top: 0px;
        overflow: hidden;
    }
    .career-opportunity {
        text-align: left;
        max-width: 376px;
        margin-left: auto;
        margin-right: auto;
        @include brk-mobile {
            padding-bottom: 10px;
        }
        h3{
            margin-top: 15px;
            * {
                font-size: 24px;
                line-height: 28px;
                color: $blue70;
            }
        } 
        
        .career-opportunity-position {
            margin-bottom: 10px;
            p {
                line-height: 18px;
            }
        }
        p {
            color: $grey;
            font-size: 14px;
            line-height: 18px;
        }
        .career-opportunity-image {
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .collapse {
            margin: 10px 0;
            .collapsible-btn {
                img {
                    width: 15px;
                }
            }
            h4 {
                display: flex;
                justify-content: space-between;
                button {
                    border: none;
                    background: transparent;
                    transition: all 300ms;
                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }
            .collapse-content {
                display: none;
                color: $grey;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
}
