.one-column-text {
    // padding: 20px 0;

    .mainTitle {
        text-align: left;
        height: 39px;
        color: $blue70;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.12px;
        line-height: 21px;
    }

    .mainText {
        color: $grey;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
    }
    .headline-styles{
        .headline2,.headline3{
            text-align: left;
            margin-bottom: 15px;
        }
    }
   
}

@include brk-mobile {
    .one-column-text {
        .mainTitle {
            margin-bottom: 10px;
        }
    }
}
