.ft-black-strip {
    @include focus-pink-outline;
    height: 50px;
    background: $blue57;
    nav{
        height: 100%;
        ul {
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .ico {
                margin: 0 10px;
            }
        }
    }
}

.ft-white-strip {
    @include focus-pink-outline;
    background: #0b4383;
    overflow: hidden;
    padding: 10px 0;
    .ft-box {
        position: relative;
        display: flex;
        align-items: center;
        .ft-triangle {
            width: 2650px;
            height: 470px;
            position: absolute;
            right: 90%;
            top: -18px;
            background: linear-gradient(
                to bottom right,
                $blue70 49.8%,
                transparent 50%
            );
        }
        span {
            margin-right: 10px;
            color: $white;
            font-size: 14px;
            margin-left: 6%;
            @include brk-mobile {
                margin-left: 0;
            }
        }
    }
    .ft-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
        }
    }
}

.ft-blue-strip {
    @include focus-pink-outline;
    background: $blue70;
    .container {
        padding-bottom: 33px;
        padding-top: 37px;
        .ft-logo,
        .address {
            align-self: center;
            img {
                width: 100%;
                max-width: 200px;
                height: auto;
            }
        }
        .waca-logo{
            align-items: center;
            display: flex;

            @include brk-mobile {
                padding-top: 15px;
            }
            img{
                max-width: 440px;
                width: 100%;
            }
        }
        .privacy-settings-cont{
            margin-top: 10px;
            .privacy-settings{
                color: $white;
                cursor: pointer;
                font-size: 14px;
            }
        }
        .address ul {
            margin-top: 15px;
            margin-right: 50px;
            li {
                color: $white;
                font-size: 16px;
                line-height: 24px;
                a {
                    color: $white;
                    text-decoration: none;
                }
                .email {
                    text-decoration: underline;
                }
            }
        }
        .services-info {
            display: flex;
            justify-content: space-around;
        }
        .services,
        .info,
        .newsletter {
            position: relative;
            color: $white;
            h3 {
                text-transform: uppercase;
                font-size: 24px;
                margin-bottom: 24px;
            }
            li {
                font-size: 14px;
                line-height: 24px;
                a {
                    text-decoration: none;
                    color: $white;

                }
            }

            &:before {
                content: '';
                background-color: #024289;
                position: absolute;
                height: 120%;
                width: 1px;
                top: -10px;
                left: -20px;
            }
        }
        // .info:after {
        //         content: '';
        //         background-color: #024289;
        //         position: absolute;
        //         height: 120%;
        //         width: 1px;
        //         top: -10px;
        //         right: -20px;

        // }
        .newsletter {
            height: 100%;
            .newsletter-input-box {
                position: relative;
                max-width: 240px;
            }
            .newsletter-input {
                @include input;
                padding-right: 38px;
            }
            button {
                position: absolute;
                top: 10px;
                right: 5px;
                border: none;
                background: transparent;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            .checkbox {
                margin-top: 15px;
                @include checkbox;
            }
        }
        .services::before {
            width: 2px;
        }
    }
}
