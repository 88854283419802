.news-details {
    max-width: 783px;
    margin: 0 auto;

    ul.breadcrumb {
        padding: 10px 10%;
        list-style: none;
    }

    ul.breadcrumb li {
        display: inline;
        font-size: 18px;
    }

    ul.breadcrumb li + li:before {
        padding: 8px;
        color: $blue70;
        @include font-roboto;
        font-size: 14px;
        letter-spacing: 0.12px;
        line-height: 16px;
        content: ">>\00a0";
    }

    ul.breadcrumb li a {
        color: $blue70;
        @include font-roboto;
        font-size: 14px;
        letter-spacing: 0.12px;
        line-height: 16px;
        text-decoration: none;
    }

    ul.breadcrumb li a:hover {
        color: $blue70;
        text-decoration: underline;
    }

    .newstitle {
        width: 100%;
        color: $blue70;
        @include font-roboto;
        font-size: 26px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 33px;
        padding-top: 30px;
    }

    .blog-block-text {
        width: 100%;
        color: $grey;
        @include font-roboto;
        font-size: 16px;
        line-height: 24px;
        display: block;
        padding-top: 30px;
        padding-left: 10px;
        padding-right: 10px;

        img {
            max-width: 100%;
        }
    }

    .interestpart {
        padding: 30px 0 0 0;
    }

    .recentsevents {
        padding: 30px 0 0 0;
    }
}
