.body-download-links {
    padding: 10px 0;
    .container {
        .headLine {
            padding-bottom: 5px;
            padding-top:5px;
            color: $blue70;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0.21px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .collapsible-button img{
              width: 18px;
            }
        }
        .name-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px 0.5rem 10px 1.5rem;
            &:after {
                content: '';
                width: 100%;
                background: $light-grey;
                height: 1px;
                margin-top: 10px;
            }
            .fileName {
                color: $grey;
                font-size: 16px;
            }
            .a-url {
                .iconDownload {
                    width: 100%;
                    background-image: url(/assets/img/elements/ico_download@3x.webp);
                    background-position: center;
                    background-repeat: no-repeat;
                    padding-left: 16.31px;
                    box-sizing: border-box;
                    height: 6.62px;
                    width: 16.31px;
                    background-size: 100%;
                }
                .fileExtension {
                    color: $pink;
                    font-size: 14px;
                    display: inline-block;
                    text-decoration: underline;
                    text-transform: uppercase;
                    text-align: left;
                    padding-left: 2px;
                }
            }
        }
        .collapsible-btn {
            border: none;
            background: transparent;
            transform-origin: center 35%;
            transition: transform 0.6s;
            &.active {
                transform: rotate(180deg);
            }
        }
        .collapsed{
            display: none;
        }
    }
}

@include brk-mobile {
    .body-download-links {
        .container {
            .fileName {
                flex-basis: 100%;
                margin-bottom: 10px;
            }

            .collapse-content{
                display: none;
            }
        }
    }
}
