.head-image-slider {
    margin-bottom: 270px;

    .container-fluid {
        padding: 0;
        margin: 0;
    }

    .prev,
    .next {
        z-index: 10;
        display: none; //hidden for now
    }

    .mySlides {
        background-position-x: center;
        margin-bottom: -20px;
        @include focus-pink-outline;
        outline-offset: -5px;
    }

    .prev {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 48px 16px;
        margin-top: -22px;
        color: white;
        font-weight: bold;
        font-size: 48px;
        transition: 0.6s ease;
        border-radius: 0 10px 10px 0;
        user-select: none;
        opacity: 0.3;
    }

    /* Position the "next button" to the right */
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 48px 16px;
        margin-top: -22px;
        color: white;
        font-weight: bold;
        font-size: 48px;
        transition: 0.6s ease;
        border-radius: 10px 0 0 10px;
        user-select: none;
        opacity: 0.3;
        right: 0;
    }

    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    /* The dots/bullets/indicators */
    .dots {
        display: none; //hidden for now
        top: 20%;
        position: absolute;
        /*display: inline-block;*/
        text-align: center;
        margin: auto;
        right: 20%;
        background-color: $grey;
        width: 40px;
        padding: 10px;
        border-radius: 20px;
        opacity: 0.2;

        .dot {
            cursor: pointer;
            height: 15px;
            width: 15px;
            margin: 4px 0;
            background-color: $grey;
            border-radius: 50%;
            display: inline-block;
            border: solid 2px $white;
            transition: background-color 0.6s ease;
        }

        .active,
        .dot:hover {
            background-color: $white;
        }
    }

    .fade {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 724px;
        min-height: 724px;
        background-size: cover;
    }

    .facettes-box {
        shape-outside: polygon(100% 0, 100% 70.5%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 70.5%, 0 100%, 0 0);
        background-color: rgba(02, 36, 70, 0.2);
        position: absolute;
        z-index: 1;
        top: 0;
        height: 205px;
        width: 344px;
        left: 16%;
        padding-left: 14px;

        @media only screen and (max-width: 1725px) {
            display: none;
        }

        .facettes {
            color: $white;
            font-size: 30px;
            font-weight: 400;
            line-height: 35px;
            position: absolute;
            padding: 20px 20px 20px 10px;
            @include font-roboto;
            text-shadow: 1px 1px 1px rgba($blue70, 0.8);
        }
    }

    .facettes-box-outer {
        display: none;
        top: initial;
        bottom: 12vw;

        @media only screen and (max-width: 1725px) {
            display: block;
        }

        @media only screen and (min-width: 1220px) {
            bottom: 8vw;
        }
    }

    .box {
        position: absolute;
        bottom: -233px;
        right: 0;
        width: 100%;
        overflow-x: hidden;
        display: flex;
        justify-content: center;

        .inner-box {
            z-index: 2;
            position: relative;
            margin: 0 auto;
            width: 100%;

            &:after {
                background-color: $blue70;
                content: " ";
                width: 100%;
                position: absolute;
                top: 0;
                right: -88%;
                height: 300px;
                transform: translateX(-1px);
                @media only screen and (min-width: 2135px) {
                    right: -77%;
                }
                @media only screen and (min-width: 2445px) {
                    right: -67%;
                }
                @media only screen and (min-width: 2815px) {
                    right: -59%;
                }
                @media only screen and (min-width: 3195px) {
                    right: -52%;
                }
                @media only screen and (min-width: 3625px) {
                    right: -46%;
                }
            }
        }

        .facette-text {
            position: absolute;
            left: 60%;
            width: 25vw;
            height: auto;
            top: 32px;
            max-width: 340px;
            min-width: 250px;
            @media only screen and (min-width: 1000px) {
                top: 40px;
            }
            @media only screen and (min-width: 1300px) {
                top: 50px;
            }
            @media only screen and (min-width: 1450px) {
                top: 65px;
            }
            @media only screen and (min-width: 1870px) {
                left: 1280px;
            }
        }

        &:before {
            background-color: $blue70;
            content: " ";
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 251px;
            z-index: 2;
        }

        img {
            display: block;
            width: 100%;
            min-height: auto;
            max-height: none;
            max-width: 1885px;
            z-index: 1;
        }
    }

    @media only screen and (max-width: 1870px) {
        margin-bottom: 240px;

        .box {
            bottom: -208px;

            &:before {
                display: none;
            }

            .inner-box:after {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 1755px) {
        .box .facettes-box {
            top: 0;
        }
    }

    @media only screen and (max-width: 1714px) {
        margin-bottom: 200px;

        .box {
            bottom: -165px;
        }
    }

    @media only screen and (max-width: 1602px) {
        .box .facettes-box {
            top: 0;
        }
    }

    @media only screen and (max-width: 1565px) {
        margin-bottom: 160px;

        .box {
            bottom: -120px;
        }
    }

    @media only screen and (max-width: 1220px) {
        .box {
            bottom: -68px;
        }
    }

    @media only screen and (max-width: 920px) {
        .box {
            bottom: -42px;
        }
    }

    @media only screen and (max-width: 720px) {
        .box {
            bottom: -20px;
            .facette-text {
                top: 26px;
                right: 22px;
                left: unset;
            }
        }
    }
    @media only screen and (max-width: 560px) {
        .box {
            bottom: -14px;
            .facette-text {
                top: 20px;
                right: 18px;
                min-width: 240px;
            }
        }
    }
    @media only screen and (max-width: 460px) {
        .box {
            bottom: -8px;
            .facette-text {
                top: 14px;
                right: 16px;
                min-width: 230px;
            }
        }
    }
    @media only screen and (max-width: 400px) {
        .box {
            bottom: 0px;
            .facette-text {
                top: 12px;
                right: 11px;
                min-width: 220px;
            }
        }
    }
    @media only screen and (max-width: 370px) {
        .box {
            // bottom: -4px;
            .facette-text {
                top: 10px;
                right: 10px;
                min-width: 200px;
            }
        }
    }

    .slogan {
        color: $white;
        position: absolute;
        bottom: 15%;
        right: 0;
        width: 15%;
        z-index: 9;
        font-size: 30px;
        font-weight: 500;
        margin-right: 15%;
        line-height: 35px;

        img {
            height: auto;
            width: 200px;
            max-height: initial;
            max-width: initial;
            min-height: initial;
        }
    }

    img {
        width: 100%;
        height: 100%;
        max-height: 724px;
        min-height: 724px;
        bottom: 0;
        display: block;
        overflow: hidden;
    }

    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    /* On larger screens, higher the button */
    @media only screen and (max-width: 970px) {
        .prev,
        .next {
            top: 35%;
        }
    }

    /* On smaller screens, decrease text size */
    @media only screen and (max-width: 750px) {
        .prev,
        .next {
            top: 30%;
            font-size: 24px;
            padding: 24px 12px;
        }
    }

    /* On large screens,normal the button position */
    @media only screen and (max-width: 320px) {
        .prev,
        .next {
            top: 20%;
            font-size: 24px;
            padding: 24px 12px;
        }
    }
}

@include brk-mobile {
    .head-image-slider {
        .fade {
            max-height: 420px;
            min-height: 420px;
        }
        .facettes-box {
            left: 15%;
            width: 36%;
            .facettes {
                font-size: 24px;
                line-height: 30px;
            }
        }

        .slogan {
            bottom: 10%;
        }
    }
}

@media only screen and (max-width: 560px) {
    .head-image-slider {
        .facettes-box {
            width: 38%;
            .facettes {
                font-size: 22px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 460px) {
    .head-image-slider {
        .facettes-box {
            width: 42%;
            .facettes {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .head-image-slider {
        .facettes-box {
            width: 46%;
        }
        // .facette-text {
        //     right: 12px;
        //     left: unset;
        // }
    }
}
