@mixin font-roboto {
    font-family: 'Roboto', sans-serif;
}

@mixin clear {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin brk-s-mobile {
    @media (max-width: $brk-s-mobile) {
        @content;
    }
}

@mixin brk-small {
    @media (max-width: $brk-small) {
        @content;
    }
}

@mixin brk-mobile {
    @media (max-width: $brk-mobile) {
        @content;
    }
}

@mixin brk-desktop {
    @media (min-width: $brk-mobile+1px) {
        @content;
    }
}

@mixin input {
    border-radius: 8px;
    padding: 8px;
    border: $white;
    width: 100%;
    border: none;
    height: 36px;
    font-size: 14px;
}

@mixin checkbox {
    position: relative;
    input {
        opacity: 0;
        position: absolute;
        width: 5px;
    }
    label {
        position: relative;
        display: inline-block;
        padding-left: 24px;
        font-size: 12px;
        line-height: 1.5;

        a {
            text-decoration: underline;
        }

        &:after,
        &:before {
            position: absolute;
            content: '';
            display: inline-block;
        }

        &:before {
            height: 16px;
            width: 16px;
            left: 0px;
            background: $white;
        }

        &:after {
            height: 4px;
            width: 8px;
            border-left: 2px solid $light-blue;
            border-bottom: 2px solid $light-blue;
            transform: rotate(-55deg);
            left: 4px;
            top: 4px;
        }
    }
    input[type='checkbox'] + label::after {
        content: none;
    }
    input[type='checkbox']:checked + label::after {
        content: '';
    }
    input[type='checkbox']:focus + label::before {
        outline: $bright-pink auto 5px;
    }
}

@mixin preload-images($element, $images) {
    $urls: '';
    @each $image in $images {
        $urls: $urls + 'url(#{$image}) ';
    }

    #{$element}:after {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        visibility: hidden;
        content: unquote($urls);
    }
}

@mixin headline-underlined {
    font-size: 28px;
    color: $blue70;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 1.2;
    transform: translateX(-50%);
    left: 50%;

    a {
        color: $blue70;
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    &::after {
        content: '';
        width: 60%;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background: $light-blue;
        bottom: -13px;
        position: absolute;
    }

    &.hide-underline {
        &:after {
            display: none;
        }
    }

    @include brk-mobile{
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 20px;
        &:after{
            content: none;
        }
    }
}

@mixin focus-pink-outline{
    &:focus {
        outline: 5px solid $bright-pink;
    }
    > *:focus {
        outline: 5px solid $bright-pink;
    }
}

@mixin infobox{
    background-color: $light-blue;
    &.layout2menu{
        @include brk-desktop{
            margin-top:20px;
            margin-bottom: 20px;
            padding: 10px;
        }
    }
    .container {
        .headLine {
            width: 100%;
            color: $white;
            font-size: 26px;
            line-height: 1.15;
            padding-bottom: 10px;
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .info {
            width: 100%;
            color: $white;
            font-size: 16px;
            line-height: 1.4;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            padding-bottom: 10px;
            padding-top: 10px;
            &:last-child{
                border:none;
            }
            h4 {
                font-size: 14px;
                font-weight: 500;
            }
            .rich-text {
                color: $white;
                ul {
                    list-style-image: url('/assets/img/elements/bulletpoint_white.webp');
                }
                a {
                    color: $white;
                }
            }
        }
    }
}

@mixin flash-messages{
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-color: $light-blue;
    color: $white;
    padding: 15px 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    @include font-roboto;

    &.flash-message-error {
        background-color: $red;
    }
}


@mixin rich-text-styles {
    color: $grey;
    font-size: 16px;

    span.lang-text{
        color: $grey;
    }

    .quote-text span.lang-text {
        color: $pink;
    }

    strong {
        font-weight: 500;
    }

    em, blockquote {
        font-style: italic;
    }

    a {
        font-weight: 300;
        color: $pink;
        span.lang-text{
            color: $pink;
        }
    }
    img{
        max-width: 100%;
    }
    ol,
    ul {
        margin-bottom: 20px;
        padding-left: 15px;
        list-style-type: decimal;

        li {
            margin-bottom: 10px;
            line-height: 1.5em;
        }

        ol {
            margin-top: 15px;
            list-style-type: lower-alpha;

            ol {
                list-style-type: lower-roman;
            }
        }

        ul {
            margin-top: 15px;
        }

    }
    ul {
        list-style-image: url('/assets/img/elements/ico_bulletpoint.webp');
    }
    p {
        margin-bottom: 15px;
        line-height: 1.5em;
    }
    h3, h4, h5, h6 {
        color: $blue70;
        margin-bottom: 12px;
        text-align: left;
        line-height: 1.2;
    }
    h3 {
        font-size: 26px;
        margin-bottom: 18px;
        font-weight: 300;
    }
    h4 {
        font-size: 24px;
    }
    h5 {
        font-size: 18px;
        font-weight: 500;
    }
    h6 {
        font-size: 16px;
    }
    h2 {
        @include headline-underlined;
    }
    *:last-child {
        margin-bottom: 0;
    }
    table{
        td,th{
            padding-right: 8px;
            &:last-child{
                padding-right: 0;
            }
        }
        th{
            font-weight: 500;
        }
    }
}
