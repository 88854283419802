.select-wrapper {
    display: flex;
    flex-direction: column;

    label {
        padding-top: 5px;
        padding-bottom: 5px;
        color: $grey;
        font-size: 14px;
    }

    .choices__list--dropdown .choices__item--selectable {
        padding-right: 10px;

        .is-highlighted {
            @include focus-pink-outline;
        }
    }

    select {
        width: 280px;
        border: 1px solid #dddddd;
        min-height: 30px;
        padding: 0 10px;
        border-radius: 2.5px;
        background-color: $very-light-grey;
    }

    .choices {
        width: 280px;
        border: 10.4px;

        .choices__inner {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 30px;
            display: flex;
            align-items: center;
            background-color: $very-light-grey;
            color: $grey;
        }
    }
}


