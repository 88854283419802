.modal-container {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    .modal {
        line-height: 30px;
        z-index: 101;
        width: 450px;
        position: fixed;
        max-width: 90vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 15px 30px 30px 30px;
        .times {
            float: right;
            cursor: pointer;
            font-size: 30px;
        }
        .content {
            padding: 8px 0;
            margin-top: 29px;
            text-align: center;
            h2 {
                font-size: 22px;
                font-weight: 500;
            }
        }
        .footer {
            padding-top: 8px;
        }

        &.bg-blue {
            background-color: #009de0;
            color: #ffffff;
        }
    }
}
