.content-image-small{
    .container{
        .mainImage {
                   height: 252px;
                   width: 380px;
                   img{
                       width:100%;
                       height:100%;
                      }
         }
        .imageText{
                  padding-left:10px;
                  height:100%;
                  .headLine {
                             height: 100%;
                             width: 100%;
                             color: $blue70;
                             font-family: Roboto;
                             font-size: 26px;
                             letter-spacing: 0.74px;
                             line-height: 30px;
                  }
                 .richText {
                            height: 100%;
                            width: 100%;
                            color: $grey;
                            font-family: Roboto;
                            font-size: 16px;
                            line-height: 22px;
                            padding-top: 10px;
                  }
        }
     }
}

@include brk-mobile {
    .content-image-small{
                         .container {
                                    padding:0px;
                                    .imageText {
                                                .headLine{
                                                          padding:25px 25px 0px 25px;
                                                 }
                                                .richText{
                                                             padding:25px;
                                                 }

                                      }
                          }
   }
}
