.headline-text-image {
    padding: 20px 0;
    .container {
        .headline {
            height: 30px;
            color: $blue70;
            font-size: 26px;
            letter-spacing: 0.74px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        img {
            height: 200px;
            width: 100%;
        }
        .subtext {
            height: 200px;
            color: $grey;
            font-size: 16px;
            line-height: 22px;
            float: left;
        }
        .maintext {
            color: $grey;
            font-size: 16px;
            line-height: 22px;
        }
    }
}
