.apply-now {
    padding: 40px 0;
    .container {
        .content-box {
            max-width:800px;
            padding: 5px;
            margin: auto;
            .row {
                opacity:1;
                margin: auto;
                .part1 {
                    height: 100%;
                    padding: 15px;
                    .title {
                        color: $light-blue;
                        padding: 20px 0;
                        font-weight:500;
                    }
                    .content {
                        height: 100%;
                        width: 100%;
                        color: $grey;
                        font-family: Roboto;
                        font-size: 16px;
                        @include brk-mobile{
                            font-size: 14px;
                        }
                    }
                }

                .part2 {
                    background-color: $light-blue;
                    .card {
                        color: $white;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.4;
                        margin: 0px;
                        padding: 60px 23px 25px 23px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        h2 {
                            text-align: left;
                        }
                        .btnbox {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 30px;

                            .applynowbutton:hover {
                                background-color: #ececec;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include brk-mobile {
    .apply-now {
        .container {
            .content-box {
                width: 100%;
                padding: 10px;
            }
        }
    }
}
