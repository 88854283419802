.search-results {
    padding: 20px 0;
    ul .row {
        position: relative;
        margin-bottom: 24px;
        @include brk-desktop{
            margin-left: 10px;
            margin-right: 10px;
        }
        &:last-child{
            margin-bottom: 15px;
        }
        &:after {
            content: '';
            background: $light-grey;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -10px;
        }
    }
    .search-results-item {
        text-align: left;
        .breadcrumbs {
            padding: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            @include brk-mobile {
                &.container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .entry-name {
            color: $pink;
            font-size: 18px;
            text-decoration: none;
            position: relative;
            &:after{
                content: url($img-path +'/elements/pfeil.webp');
                position: absolute;
                right: -15px;
                width: 6px;
            }
        }
    }
    .page-numbers{
        a, span{
            text-decoration: none;
            color: lighten( $blue57, 70% );
            font-size: 14px;
            font-weight: 400;
            margin-left: 4px;
            img{
                width: 8px;
            }
        }
        .current{
            color: $blue57;
        }
    }

}

@include brk-mobile {

    .search-results .underline-headline {
        font-weight: 300;
        text-align: left;
        font-size: 24px;
        margin-bottom: 25px;
        left: unset;
        transform: none;
        &:after {
            content: none;
        }
    }
}


