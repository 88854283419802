.add-to-calendar{
  padding: 20px 0;

  .btn{
    color: $white;
    text-transform: none;
    font-weight: 500;
    position: relative;
    padding-left: 45px;
    display: inline-block;
    img{
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      left:15px;
    }
  }

  .rich-text{
    text-align: left;
    p{
      margin-bottom:20px;
    }
  }

  &.layout2menu{
    background: $light-blue;
    
    p,h3,h4,h4 *{
      color: $white;
    }
    h4,p{
      font-weight: 500;
      font-size:14px;
    }
    .time{
      font-weight:300;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    h4, h4 p{
      margin-bottom: 10px;
    }
    .btn{
      background: $white;
      color:$blue70;
    }
    .date{
      border-bottom: 1px solid #68D1FF;
      padding-bottom: 25px;
      &:last-of-type{
        border: none;
      }
    }
  }
}