/**
For your own safety, please never touch this code. You WILL end up like Max.
**/


.uc-banner-content{
    background-color: #24b0fc !important;
    &.darker-bg{
        background: #007db3!important;

        #uc-btn-accept-banner{
            color: #007db3!important;
        }

        #uc-btn-more-info-banner{
            background: #007db3!important;
            border: 1px solid $white;
        }
    }
}

.uc-optin-description {
    font-family: "Nova-Regular", sans-serif;
    font-size: 95%;
    line-height: 148%;
    margin: 0 auto;
}


.btn-list uc-col-lg-12 uc-col-md-12 uc-col-sm-12 uc-col-12 uc-overflow-hidden show-deny-btn show-more-btn {
    width: 62%;
}


.uc-powered-by-footer {
    font-size: 70%;
    width: 60.5%;
}


#uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny {
    font-family: "Nova-Regular", sans-serif;
    font-weight: bold;
    background-color: transparent;
    color: white;
    padding: 0.8%;
    text-align: center;
    text-decoration: none;
    border-radius: 20px !important;
    border: solid white 1px;
    width: 15% !important;
    box-shadow: none !important;
}

#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny,
#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-more-info-banner.uc-btn.uc-btn-default.more-info-button{
    width: 15% !important;
}



#uc-btn-accept-banner {
    font-family: "Nova-Regular", sans-serif;
    font-weight: bold;
    background-color: white;
    border: none;
    color: dodgerblue;
    padding: 0.8%;
    text-align: center;
    text-decoration: none;
    border-radius: 20px !important;
    width: 15% !important;
    box-shadow: none !important;
}

#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner.uc-btn.uc-btn-primary {
    width: 65% !important;
}

#uc-btn-more-info-banner {
    font-family: "Nova-Regular", sans-serif;
    font-weight: bold;
    background-color: #1f9ee2;
    border: none;
    color: white;
    padding: 0.8%;
    text-align: center;
    text-decoration: none;
    border-radius: 20px !important;
    width: 15% !important;
    box-shadow: none !important;
}

.uc-banner-content>div>.uc-powered-by-footer{

    width: auto !important;
    margin: 0 auto !important;
    text-align: right !important;
}

.uc-banner-content > div > .uc-powered-by-footer span{
    color: #ffffff !important;
}

#uc-main-banner .uc-banner-content > div {
    width: 70% !important;
    margin: 0 auto !important;
    float: none !important;
}
.uc-banner-content div.btn-list.uc-overflow-hidden{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}

@media screen and (max-width: 640px){
    #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny,
    #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-more-info-banner.uc-btn.uc-btn-default.more-info-button,
    #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner.uc-btn.uc-btn-primary {
        width: 100% !important;
    }

    .uc-banner-content div.btn-list.uc-overflow-hidden{
        flex-direction: column !important;

    }
    #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny {
        border: transparent !important;
    }

    #uc-main-banner .uc-banner-content > div {
        width: 95% !important;
    }

    .uc-optin-description{
        display: inline-block;
        height: 112px !important;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .uc-banner-content ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 13px;
        background-color: rgba(69, 158, 226, 0.9);
    }

}
