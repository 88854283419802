.people{
  padding: 20px 0;

  .dept{
    padding: 4px 0;
    color: $blue70;
    font-size: 20px;
    border-bottom: 1px solid $darker-light-grey;
    .dept-title{
      flex: 1;
    }
    .dept-row{
      padding: 10px 0;
    }
    .dept-collapse-content{
      margin-top:10px;
    }
    .person{
      padding: 15px 0;
      color: $grey;
      font-size: 16px;
      border-top: 1px solid $very-light-grey;

      &-title{
        cursor: pointer;
      }
     
      h4{
        color: $light-blue;
        font-weight: 400;
        margin-bottom: 5px;
      }
      .close-btn{
        display: none;
        border:none;
        background: transparent;
        font-size: 16px;
        font-weight: 500;
        color: $light-blue;
      }
      .title-btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .person-card{
    display: flex;
    flex-wrap: wrap;
    background: $very-light-grey;
    padding: 20px;
    margin: 25px 0;
    .picture{
      flex:1;
      height: 160px;
      overflow: hidden;
      position: relative;
      min-width: 100px;
      max-width: 120px;
      img{
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .name-prof{
      flex:1;
    }
    .contact-info{
      flex:4;
      line-height: 1.2;
      padding-top:5px;
      padding-left: 20px;
      h5{
        font-weight: 400;
        margin-bottom: 0;
      }
      p{
        margin-bottom: 20px;
      }
      .tel,.email{
        display: flex;
        span,a{
          flex: 6;
          font-size: 16px;
        }
        span:first-child{
          flex: 1;
          margin-right: 10px;
        }
      }
      .email span:last-child{
        color:$pink;
      }
    }
  }

  button.collapsible-btn{
    width: auto;
    border:none;
    background: transparent;
    align-self: center;
    margin: 0 4px;
    transition: all 300ms;
    padding: 10px 0;
    transform: rotate(180deg);
    &.active{
      transform: rotate(0);
    }
    &.person-btn img{
      width: 15px;
    }
  }
  .collapse-content{
    display: none;
    &.open{
      display: block;
    }
  }
}