.job-listings {
    background: url(/assets/img/background/bg_quote.webp);
    padding: 50px;
    h3 {
        font-size: 28px;
        line-height: 33px;
        color: $white;
        letter-spacing: 0.8px;
        font-weight: 400;
    }
    input {
        @include input;
        margin: 25px 0 10px 0;
    }
    a {
        color: $white;
    }
    &-button {
        text-align: center;
        margin-top: 15px;
        a {
            color: $blue57;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}
