.toggle {
    width: 100%;
    color: $grey;
    font-size: 24px;
    position: relative;
    height: 50px;
    line-height: 50px;
    padding-left: 0;
    padding-right: 0;

    &:after {
        position: absolute;
        content: " ";
        right: 0;
        top: 50%;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        background-image: url($img-path + '/elements/arrow-grey.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: none;

        @include brk-mobile {
            display: block;
        }
    }

    &.toggled {
        &:after {
            transform: rotate(180deg);
        }
    }
}
