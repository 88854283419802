.benefits {
    @include focus-pink-outline;
    background: url(/assets/img/background/bg_quote.webp);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 30px 0;
    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-text {
        color: $white;
        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        ul {
            li {
                margin-bottom: 10px;
                font-size: 18px;
                position: relative;
                padding-left: 20px;
                line-height: 1.35;
                &::before {
                    content: url('/assets/img/elements/bulletpoint_white.webp');
                    position: absolute;
                    left: 0;
                    top: -3px;
                }
            }
        }
    }
}
