.news-recents {
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    [class*='col-'] {
        margin-bottom: 20px;
    }

    .cardbox {
        border: 1px solid $light-grey;
        height: 380px;
        width: 280px;
        justify-content: space-between;
        flex-direction: column;
        display: inline-flex;
        margin-right: 6px;
        margin-left: 6px;
        margin-bottom: 20px;
        text-align: left;

        img {
            width: 100%;
            height: auto;
        }

        .blogtext {
            color: $blue70;
            font-size: 16px;
            line-height: 22px;
            padding: 5px 10px 10px 10px;
        }

        .datetime {
            color: grey;
            font-size: 16px;
            line-height: 22px;
            padding: 5px 10px 0px 10px;
        }

        .bottom {
            display: inline-block;
            padding-left: 10px;
            padding-bottom: 30px;

            .btn-white {
                border: 0.8px solid $blue70;
            }
        }

        @include brk-mobile {
            margin-left: auto !important;
            margin-right: auto !important;
            display: flex;
        }
    }

    .more-wrapper {
        display: flex;
        flex-basis: 100%;

        a.btn-more {
            position: relative;
            margin-left: auto;
            padding-right: 10px;
            margin-right: 10px;
        }
    }
}
