.infobox {
    @include infobox;
    &.collapse {
        .collapse-content {
            @include  brk-mobile{
                display: none;
            }
        }
        .collapsible-btn {
            border: none;
            background: transparent;
            transform-origin: center 35%;
            transition: transform 0.6s;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    @include  brk-mobile{
        display: none;
    }
    &-mobile{
        display: none;
        @include  brk-mobile{
            display: block;
            margin-top: 0;
        }
    }
}