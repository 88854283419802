.featured-blog-post {
    padding: 20px 0;
    @include focus-pink-outline;
    .img {
        min-height: 280px;
        outline: 1px solid white;
        outline-offset: -10px;
    }
    .text-box {
        padding: 35px;
        border: 1px solid #ececec;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        h3 {
            font-size: 26px;
            color: $blue70;
            margin-bottom: 20px;
        }
        .post-text {
            margin-bottom: 20px;
            color: $grey;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@include brk-mobile {
    .featured-blog-post {
        .img {
        }
        .text-box {
            border: none;
            padding: 15px;
        }
    }
}

@include brk-desktop {
    .hidden-desktop {
        display: none;
    }
}
