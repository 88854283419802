.slider {
    padding: 20px 0;
    .slider-wrapper {
        position: relative;
        height: 500px;
        width: 100%;
        overflow: hidden;
        background: $white;
        &.threeImage .slidersImage {
            width: calc(100% / 3);
        }
    }
    .slidersImage {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.5s ease-in-out;
        @include focus-pink-outline;
        outline-offset: -5px;
        z-index: -1;
        &.active {
            z-index: 1;
        }
    }
    .row {
        position: relative;
        .lightbox {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            opacity: 0;
            display:none;
            transition: all 0.3s ease-in;
            &.show {
                transform: translate(-50%, -50%) scale(1);
                display: block;
                opacity: 1;
                max-width: 80%;
                max-height: 90vh;
                z-index: 10;
                @include brk-mobile {
                    max-width: 94%;
                }
            }
            .lightbox-focus{
                outline: none;
            }
            .lightbox-close {
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 3;
                width: 40px;
                height: 40px;
                padding: 5px;
                cursor: pointer;
                span {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    &::before,
                    &::after {
                        background: $white;
                        content: '';
                        top: 12px;
                        display: block;
                        height: 3px;
                        position: absolute;
                        width: 100%;
                        transform: rotate(45deg);
                        border-radius: 4px;
                        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
            img {
                width: 100%;
            }
        }
    }
    .prv-col,
    .nxt-col {
        text-align: center;
        margin: auto;
    }
    .nxt,
    .prv {
        max-width: 50px;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.3s;
        transform: rotate(-90deg);
    }
    .prv {
        transform: rotate(90deg);
    }
    .prv:hover,
    .nxt:hover {
        opacity: 1;
    }
}

@include brk-mobile {
    .slider {
        .slider-wrapper {
            height: 280px;
            &.threeImage .slidersImage {
                width: 50%;
            }
        }
        .nxt,
        .prv {
            width: 100%;
        }
    }
}

@media (max-width: 450px) {
    .slider .slider-wrapper {
        height: 210px;
    }
}
