.intro-text {
    padding: 30px 0;
    .intro {
        height: 100%;
        width: 778px;
        color: $grey;
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
    }
    .intro-text-image {
        background-size: cover;
        height: 280px;
        width: 100%;
    }
}

@include brk-mobile {
    .intro-text {
        padding-bottom: 0;
        .underline-headline {
            font-weight: 300;
            text-align: left;
            font-size: 24px;
            margin-bottom: 25px;
            left: unset;
            transform: none;
            &:after {
                content: none;
            }
        }
        .intro-text-image {
            margin-top: 20px;
            width: calc(100% + 20px + 1rem);
            margin-left: calc(-10px - 0.5rem);
            // margin-left: $container-padding;
        }
    }
}
