.job-listing{
  padding:20px 0;
  ul{
    li{
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid $light-grey;
      &:first-child{
        border-bottom: none;
      }
      a{
        color: $blue70;
        text-decoration: none;
        font-weight: 300;
      }
      span{
        color: $grey;
      }
      h4{
        color: $grey;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}