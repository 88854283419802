.contact-image-left {
    padding: 20px 0;

    .container {
        @include brk-mobile {
            padding: 0;
        }
    }

    .contact-image-left-contact {
        background: $light-blue url($img-path + "/background/bg_sub_ansprechpartner.webp") center;
        background-size: cover;
        min-height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        color: $white;
        text-align: center;

        p{
            line-height: 22px;
        }

        h3 {
            font-size: 26px;
            line-height: 34px;
        }

        ul {
            line-height: 22px;
            font-size: 16px;
            margin: 30px 0 20px 0;

            li:first-child {
                font-weight: 400;
            }
        }

        a {
            line-height: 22px;
            color: $white;
        }
    }

    .contact-image-left-image {
        min-height: 286px;
        background-size: cover;
        border-right: 20px solid $white;

        @include brk-mobile {
            border: none;
        }
    }
}
