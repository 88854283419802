.page-newsroom {
    .pagination {
        padding-bottom: 20px;
        padding-top: 20px;
        text-align: right;

        a {
            font-weight: 300;
            color: $pink;
            padding-left: 10px;
        }
    }

    .news-filter {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .select-wrapper {
        margin-right: 20px;
        .choices:focus{
            outline: 5px auto $bright-pink;
        }
    }

    .button-wrapper {
        align-items: flex-end;
        display: flex;
    }

    @include brk-mobile {
        .news-recents {
            margin: 0 auto;
        }

        form {
            margin: 0 auto;
            width: 100%;
            display: none;
            padding: 0;

            .select-wrapper {
                margin-right: 0;
            }

            .choices {
                width: 100%;
            }

            &.visible {
                display: block;
            }

            .button-wrapper {
                justify-content: flex-end;
            }
        }

        .news-filter {
            flex-direction: column;

            .select-wrapper {
                margin-bottom: 15px;
            }
        }

        h2.underline-headline {
            font-size: 18px
        }
    }
}
