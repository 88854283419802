.past-events{
      [class*="col-"]{
                      margin-bottom: 20px;
                     .cardbox{
                              border: 1px solid $light-grey;
                              height:100%;
                              img{
                                  width:100%;
                                  height: 236.25px;
                               }
                             .blogtext {
                                        color: $blue70;
                                        font-size: 16px;
                                        line-height: 22px;
                                        padding:5px 10px 10px 10px;
                             }

                             .datetime{
                                        color:grey;
                                        font-size: 16px;
                                        line-height: 22px;
                                        padding:5px 10px 0px 10px;
                             }
                          .bottom{
                                  display: inline-block;
                                  padding-left:10px;
                                  padding-bottom:30px;
                                  .btn-white{
                                             border: 0.8px solid $blue70;
                                  }
                           }



                     }
      }
}
