.events-outer-wrapper {
    .event {
        background: $white;
        min-width: 272px;
        max-width: 272px;

        .event-text {
            background: $blue70;
            clip-path: polygon(100% 0, 100% 86%, 0 100%, 0 0);
            shape-outside: polygon(100% 0, 100% 86%, 0 100%, 0 0);
            padding: 35px 20px 70px 20px;
            text-align: left;
            color: $white;
            height: 340px;
            position: relative;
            margin-bottom: 10px;

            img {
                display: inline-block;
            }

            .date-time {
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 30px;
                position: relative;
                padding-left: 30px;
                font-weight: bold;

                &:before {
                    content: url($img-path + '/elements/ico_calendar.webp');
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            .event-title {
                font-size: 24px;
                margin-bottom: 30px;
                line-height: 1.1;
                p{
                    font-size: 24px;
                    line-height: 1.1;
                }
            }

            h2 {
                font-size: 24px;
                margin-bottom: 30px;
                line-height: 1.1;
                p{
                    font-size: 24px;
                    line-height: 1.1;
                }
            }

            p {
                font-size: 14px;
            }
        }

        .button-block {
            padding: 0 35px 35px 35px;
            text-align: right;
            background: $white;
            @include focus-pink-outline;
        }
    }

    @media (max-width: 1160px) {
        .events-row {
            flex-direction: column;
            align-items: center;
        }

        .event-wrapper {
            margin-bottom: 30px;
            width: 100%;
            max-width: 272px;
            padding: 0;
        }

        .event-wrapper:last-child {
            margin-bottom: 0;
        }
    }

    .event-wrapper {
        padding: 5px;
        box-sizing: content-box;
        margin-bottom: 15px;
    }

    .events-container {
        background-color: $light-grey;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    @include brk-mobile {
        .event-wrapper {
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
}

.upcoming-events {
    background: $light-grey;
    padding: 30px 0;
    padding-bottom: 60px;

    .events-outer-wrapper {
        display: flex;
        justify-content: center;

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .event-wrapper {
        margin-bottom: 0;
    }
}

.event-wrapper {
    position: relative;

    .btn-more {
        margin-left: auto;
    }

    .more-wrapper {
        display: flex;
        margin-top: 20px;
        position: absolute;
        right: 5px;
    }
}
