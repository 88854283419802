.alumni-benefits{
  .container {
    height:100%;
    width:100%;
    .row{
      grid-column-gap: 10px;
      .headLine {
        height: 100%;
        width: 100%;
        color: $blue70;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.8px;
        line-height: 28px;
        display: inline-block;
      }
      .richText {
        height: 64.39px;
        width: 100%;
        color: $grey;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.8px;
        line-height: 16px;
      }
      .Column{
        height:100%;
        width:100%;
        .imageBox{
          height:180px;
          width:100%;
          border: 1px solid $light-grey;
          display: flex; align-items: center; justify-content: center;
        }
        .textlink {
          height:36px;
          width: 100%;
          line-height: 16px;
          text-align: right;
          a:link{
            color: $pink;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.8px;
            text-decoration: none;
            text-transform: uppercase;
          }
          a{
            color:$pink;
          }
          a:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@include brk-mobile {
    .alumni-benefits .container .pagetitle,.alumni-benefits .container hr{display:none;}
    .alumni-benefits .container .row .Column2,.alumni-benefits .container .row .Column3 {display:none;}
    .alumni-benefits .container .row .Column1 .headLine { margin: 20px 0px;}
}
