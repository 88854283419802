.contact-form {
    padding: 20px 0;

    .captcha{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;

        @include brk-mobile{
            justify-content: center;
        }
    }

    #h-email, #h-name, #h-body {
        position: absolute;
        opacity: 0;
        top:0;
        left:0;
        z-index: -1;
    }

    input:not([type="submit"]),
    textarea {
        width: 100%;
        padding: 8px 14px;
        font-size: 18px;
        border-radius: 8px;
        background: $light-grey;
        border: none;
        color: $grey;
        @include font-roboto;

        @include brk-mobile {
            font-size: 14px;
        }
    }

    input[type="submit"] {
        line-height: 1;
        width: 100%;
        max-width: 220px;
    }

    .contact-message {
        margin-bottom: 10px;
    }

    .checkbox {
        @include checkbox;

        label {
            text-align: left;

            p {
                line-height: 18px;
                font-size: 14px;
                @include brk-mobile {
                    font-size: 12px;
                }
            }

            &:before {
                background: $light-grey;
            }
        }
    }

    .checkbox-contact-input,
    .name-contact-input,
    .email-contact-input,
    .message-contact-input {
        margin-bottom: 18px;
        position: relative;
        text-align: left;

        input {
            text-align: center;
        }

        &.error {
            input {
                border: 1px solid $red;
            }

            .error-message {
                display: block;
            }

            .checkbox label:before {
                border: 1px solid $red;
            }
        }

        .error-message {
            display: none;
            position: absolute;
            bottom: calc(100% + 20px);
            background: $red;
            padding: 12px 30px;
            color: $white;
            text-align: center;
            line-height: 16px;
            font-size: 14px;
            border-radius: 8px;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9999;
            // animation: errorOpen 300ms 4s forwards;

            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                left: 50%;
                top: 100%;
                z-index: 2;
                transform: translateX(-50%);
                border-top: 20px solid $red;
            }
        }
    }

    .flash-message {
        @include flash-messages;
    }
}

@keyframes errorOpen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes errorClose {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
