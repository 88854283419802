@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 8.3333333333%;
  -moz-flex-basis: 8.3333333333%;
  -ms-flex-preferred-size: 8.3333333333%;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 16.6666666667%;
  -moz-flex-basis: 16.6666666667%;
  -ms-flex-preferred-size: 16.6666666667%;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 33.3333333333%;
  -moz-flex-basis: 33.3333333333%;
  -ms-flex-preferred-size: 33.3333333333%;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 41.6666666667%;
  -moz-flex-basis: 41.6666666667%;
  -ms-flex-preferred-size: 41.6666666667%;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 58.3333333333%;
  -moz-flex-basis: 58.3333333333%;
  -ms-flex-preferred-size: 58.3333333333%;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 66.6666666667%;
  -moz-flex-basis: 66.6666666667%;
  -ms-flex-preferred-size: 66.6666666667%;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 83.3333333333%;
  -moz-flex-basis: 83.3333333333%;
  -ms-flex-preferred-size: 83.3333333333%;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 91.6666666667%;
  -moz-flex-basis: 91.6666666667%;
  -ms-flex-preferred-size: 91.6666666667%;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%;
}

.col-xs-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%;
}

.col-xs-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%;
}

.col-xs-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%;
}

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
}

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #EAEAEA;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*=select-one] .choices__button:hover, .choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00BCD4;
}
.choices[data-type*=select-one]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open:after {
  border-color: transparent transparent #333333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00BCD4;
  border: 1px solid #00a5bb;
  color: #FFFFFF;
  word-break: break-all;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
}
.choices__list--dropdown.is-active {
  display: block;
}
.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

.choices__input.is-hidden,
.choices[data-type*=select-one] .choices__input.is-hidden,
.choices[data-type*=select-multiple] .choices__input.is-hidden {
  display: none;
}

/*=====  End of Choices  ======*/
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/roboto-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-300.woff2") format("woff2"), url("../fonts/roboto-v18-latin-300.woff") format("woff"), url("../fonts/roboto-v18-latin-300.ttf") format("truetype"), url("../fonts/roboto-v18-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v18-latin-regular.woff") format("woff"), url("../fonts/roboto-v18-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v18-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/roboto-v18-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-latin-500.woff") format("woff"), url("../fonts/roboto-v18-latin-500.ttf") format("truetype"), url("../fonts/roboto-v18-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-700.woff2") format("woff2"), url("../fonts/roboto-v18-latin-700.woff") format("woff"), url("../fonts/roboto-v18-latin-700.ttf") format("truetype"), url("../fonts/roboto-v18-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
.select-wrapper {
  display: flex;
  flex-direction: column;
}
.select-wrapper label {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #555659;
  font-size: 14px;
}
.select-wrapper .choices__list--dropdown .choices__item--selectable {
  padding-right: 10px;
}
.select-wrapper .choices__list--dropdown .choices__item--selectable .is-highlighted:focus {
  outline: 5px solid #e500a1;
}
.select-wrapper .choices__list--dropdown .choices__item--selectable .is-highlighted > *:focus {
  outline: 5px solid #e500a1;
}
.select-wrapper select {
  width: 280px;
  border: 1px solid #dddddd;
  min-height: 30px;
  padding: 0 10px;
  border-radius: 2.5px;
  background-color: #f7f7f7;
}
.select-wrapper .choices {
  width: 280px;
  border: 10.4px;
}
.select-wrapper .choices .choices__inner {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  color: #555659;
}

body,
html {
  height: 100%;
  color: #000000;
}

html body {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: 3px solid #e500a1;
}

.container {
  max-width: 1180px;
  margin: 0 auto;
  width: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.rich-text {
  color: #555659;
  font-size: 16px;
}
.rich-text span.lang-text {
  color: #555659;
}
.rich-text .quote-text span.lang-text {
  color: #cc008e;
}
.rich-text strong {
  font-weight: 500;
}
.rich-text em, .rich-text blockquote {
  font-style: italic;
}
.rich-text a {
  font-weight: 300;
  color: #cc008e;
}
.rich-text a span.lang-text {
  color: #cc008e;
}
.rich-text img {
  max-width: 100%;
}
.rich-text ol,
.rich-text ul {
  margin-bottom: 20px;
  padding-left: 15px;
  list-style-type: decimal;
}
.rich-text ol li,
.rich-text ul li {
  margin-bottom: 10px;
  line-height: 1.5em;
}
.rich-text ol ol,
.rich-text ul ol {
  margin-top: 15px;
  list-style-type: lower-alpha;
}
.rich-text ol ol ol,
.rich-text ul ol ol {
  list-style-type: lower-roman;
}
.rich-text ol ul,
.rich-text ul ul {
  margin-top: 15px;
}
.rich-text ul {
  list-style-image: url("/assets/img/elements/ico_bulletpoint.webp");
}
.rich-text p {
  margin-bottom: 15px;
  line-height: 1.5em;
}
.rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6 {
  color: #023670;
  margin-bottom: 12px;
  text-align: left;
  line-height: 1.2;
}
.rich-text h3 {
  font-size: 26px;
  margin-bottom: 18px;
  font-weight: 300;
}
.rich-text h4 {
  font-size: 24px;
}
.rich-text h5 {
  font-size: 18px;
  font-weight: 500;
}
.rich-text h6 {
  font-size: 16px;
}
.rich-text h2 {
  font-size: 28px;
  color: #023670;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  transform: translateX(-50%);
  left: 50%;
}
.rich-text h2 a {
  color: #023670;
  text-decoration: none;
}
.rich-text h2 a:hover, .rich-text h2 a:focus {
  text-decoration: underline;
}
.rich-text h2::after {
  content: "";
  width: 60%;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  background: #009DE0;
  bottom: -13px;
  position: absolute;
}
.rich-text h2.hide-underline:after {
  display: none;
}
@media (max-width: 767px) {
  .rich-text h2 {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .rich-text h2:after {
    content: none;
  }
}
.rich-text *:last-child {
  margin-bottom: 0;
}
.rich-text table td, .rich-text table th {
  padding-right: 8px;
}
.rich-text table td:last-child, .rich-text table th:last-child {
  padding-right: 0;
}
.rich-text table th {
  font-weight: 500;
}

.no-padding-left {
  padding-left: 0;
}
@media (max-width: 767px) {
  .no-padding-left {
    padding: 0;
  }
}

.no-padding-right {
  padding-right: 0;
}
@media (max-width: 767px) {
  .no-padding-right {
    padding: 0;
  }
}

#h-name-input {
  display: none;
}

@media (max-width: 767px) {
  .page-home #main .layout1 .compass-navigation-wrapper {
    display: flex;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
  }
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .collapsible .collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  .collapsible .collapsible-button {
    border: none;
    background: transparent;
    transform-origin: center 35%;
    transition: transform 0.6s;
  }
  .collapsible .collapsible-button.open {
    transform: rotate(180deg);
  }
  .show-one-mobile:not(:first-child) {
    display: none;
  }
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
    user-focus: none !important;
    user-select: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
    user-focus: none !important;
    user-select: none !important;
  }
}

.overlay {
  background: #494949;
  opacity: 0;
  z-index: -10;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  transition: all 400ms;
}
.overlay.show {
  z-index: 999;
  opacity: 0.6;
}

@media (max-width: 767px) {
  .stick-to-top {
    display: block !important;
    width: 100%;
  }
  .stick-to-top.show {
    top: 0;
    overflow: scroll;
    position: fixed !important;
    z-index: 1000;
  }
}

.underline-headline {
  font-size: 28px;
  color: #023670;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  transform: translateX(-50%);
  left: 50%;
}
.underline-headline a {
  color: #023670;
  text-decoration: none;
}
.underline-headline a:hover, .underline-headline a:focus {
  text-decoration: underline;
}
.underline-headline::after {
  content: "";
  width: 60%;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  background: #009DE0;
  bottom: -13px;
  position: absolute;
}
.underline-headline.hide-underline:after {
  display: none;
}
@media (max-width: 767px) {
  .underline-headline {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .underline-headline:after {
    content: none;
  }
}

.no-padding {
  padding: 0;
}

.grey-bg {
  background-color: #ececec;
}

.text-center {
  text-align: center;
}

.el-center {
  display: flex;
  margin: auto;
}

.margin-top-10 {
  margin-top: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.padding-right-20 {
  padding-right: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.margin-left-30 {
  margin-left: 30px;
}

.padding-left-30 {
  padding-left: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.padding-right-30 {
  padding-right: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.padding-top-40 {
  padding-top: 40px;
}

.margin-left-40 {
  margin-left: 40px;
}

.padding-left-40 {
  padding-left: 40px;
}

.margin-right-40 {
  margin-right: 40px;
}

.padding-right-40 {
  padding-right: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.padding-top-50 {
  padding-top: 50px;
}

.margin-left-50 {
  margin-left: 50px;
}

.padding-left-50 {
  padding-left: 50px;
}

.margin-right-50 {
  margin-right: 50px;
}

.padding-right-50 {
  padding-right: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.padding-top-60 {
  padding-top: 60px;
}

.margin-left-60 {
  margin-left: 60px;
}

.padding-left-60 {
  padding-left: 60px;
}

.margin-right-60 {
  margin-right: 60px;
}

.padding-right-60 {
  padding-right: 60px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.padding-top-70 {
  padding-top: 70px;
}

.margin-left-70 {
  margin-left: 70px;
}

.padding-left-70 {
  padding-left: 70px;
}

.margin-right-70 {
  margin-right: 70px;
}

.padding-right-70 {
  padding-right: 70px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.margin-top-80 {
  margin-top: 80px;
}

.padding-top-80 {
  padding-top: 80px;
}

.margin-left-80 {
  margin-left: 80px;
}

.padding-left-80 {
  padding-left: 80px;
}

.margin-right-80 {
  margin-right: 80px;
}

.padding-right-80 {
  padding-right: 80px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.margin-top-90 {
  margin-top: 90px;
}

.padding-top-90 {
  padding-top: 90px;
}

.margin-left-90 {
  margin-left: 90px;
}

.padding-left-90 {
  padding-left: 90px;
}

.margin-right-90 {
  margin-right: 90px;
}

.padding-right-90 {
  padding-right: 90px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.margin-top-100 {
  margin-top: 100px;
}

.padding-top-100 {
  padding-top: 100px;
}

.margin-left-100 {
  margin-left: 100px;
}

.padding-left-100 {
  padding-left: 100px;
}

.margin-right-100 {
  margin-right: 100px;
}

.padding-right-100 {
  padding-right: 100px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .mobile-margin-top-10 {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-10 {
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-10 {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-10 {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-10 {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-10 {
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-10 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-10 {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-20 {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-20 {
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-20 {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-20 {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-20 {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-20 {
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-20 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-20 {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-30 {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-30 {
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-30 {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-30 {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-30 {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-30 {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-30 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-30 {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-40 {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-40 {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-40 {
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-40 {
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-40 {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-40 {
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-40 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-40 {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-50 {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-50 {
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-50 {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-50 {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-50 {
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-50 {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-50 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-50 {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-60 {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-60 {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-60 {
    margin-left: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-60 {
    padding-left: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-60 {
    margin-right: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-60 {
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-60 {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-60 {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-70 {
    margin-top: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-70 {
    padding-top: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-70 {
    margin-left: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-70 {
    padding-left: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-70 {
    margin-right: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-70 {
    padding-right: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-70 {
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-70 {
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-80 {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-80 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-80 {
    margin-left: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-80 {
    padding-left: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-80 {
    margin-right: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-80 {
    padding-right: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-80 {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-80 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-90 {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-90 {
    padding-top: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-90 {
    margin-left: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-90 {
    padding-left: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-90 {
    margin-right: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-90 {
    padding-right: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-90 {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-90 {
    padding-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-top-100 {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-top-100 {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-left-100 {
    margin-left: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-left-100 {
    padding-left: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-right-100 {
    margin-right: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-right-100 {
    padding-right: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-margin-bottom-100 {
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .mobile-padding-bottom-100 {
    padding-bottom: 100px;
  }
}
.layout1,
.layout2 {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .col-sm-8.layout2 {
    padding-right: 1rem;
  }
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  border-top: #ececec 1px solid;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.custom-scripts {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 0;
  height: 0;
}

#main {
  width: 100%;
}

.stop-scroll {
  overflow: hidden;
}

#fh-skiplinks {
  left: 0;
  list-style-type: none;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 10000;
}
#fh-skiplinks .fh-skiplink {
  background: none repeat scroll 0 0 #000;
  color: #fff;
  font-weight: 700;
  left: 100px;
  opacity: 0.8;
  padding: 0.5em;
  position: absolute;
  text-align: center;
  text-decoration: underline;
  top: -4em;
  width: 11em;
}
#fh-skiplinks .fh-skiplink:focus, #fh-skiplinks .fh-skiplink:active {
  top: 1.5rem;
}

.pink-outline:focus {
  outline: 5px solid #e500a1;
}
.pink-outline > *:focus {
  outline: 5px solid #e500a1;
}

.text-black {
  color: #000000 !important;
}
.text-black a {
  color: #000000 !important;
}

.text-white {
  color: #ffffff !important;
}
.text-white a {
  color: #ffffff !important;
}

.bg-white {
  background: #ffffff !important;
}

.btn-bw {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.hidden {
  display: none;
}

.darker-bg {
  background: #007db3 !important;
}

.choices[data-type*=select-multiple] .choices__input.is-hidden,
.choices[data-type*=select-one] .choices__input.is-hidden,
.choices__input.is-hidden {
  display: none;
}

.choices__list--single {
  margin-top: 4px !important;
  font-family: Arial !important;
  color: #000 !important;
  font-size: 14px !important;
}

#executive-education .infobox .headLine {
  color: #ffffff;
}
#executive-education .contact-image-left-contact,
#executive-education .content-box .row .part2,
#executive-education .benefits,
#executive-education .infobox,
#executive-education .apply-now-banner {
  background: #7E3E93;
}
#executive-education .courses .course-contact,
#executive-education .contact-image-left-right-contact,
#executive-education .contact-image-left-contact,
#executive-education .contact-image-right-contact {
  background: url("../img/background/ee_bg_sub_ansprechpartner.svg");
  background-size: cover;
}
#executive-education .apply-now-banner {
  background: url("../img/background/ee_bg_sub_onlinetest.svg");
  background-size: cover;
}
#executive-education .header-image h1 {
  font-weight: 900;
}
#executive-education .header-image .triangle {
  background: linear-gradient(to top left, #84BD00 49.6%, rgba(0, 0, 0, 0) 50%);
}
#executive-education .header-image .box:after {
  background: #84BD00;
}
#executive-education .mobile-button {
  background: #84BD00;
}
#executive-education .btn-more {
  color: #7E3E93;
}
#executive-education .btn-more:after {
  content: url("../img/icons/ico_arrow_EECpurple.webp");
}
#executive-education .rich-text h2,
#executive-education .underline-headline {
  color: #4F7100;
  font-weight: 800;
}
#executive-education .rich-text h2::after,
#executive-education .underline-headline::after {
  background: #4F7100;
}
#executive-education .programs h4,
#executive-education .part1 .title,
#executive-education .headLine,
#executive-education .headline2,
#executive-education .headline3,
#executive-education .rich-text h3,
#executive-education .rich-text h4,
#executive-education .rich-text h5,
#executive-education .rich-text h6,
#executive-education .rich-text span.lang-text,
#executive-education .btn-white,
#executive-education .breadcrumbs li a,
#executive-education .menu-item .menu-item-link {
  color: #4F7100;
}
#executive-education .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within .menu-item-link {
  color: #ffffff;
}
#executive-education .btn-white:hover, #executive-education .btn-white:hover .menu-item-link,
#executive-education .menu-item:hover,
#executive-education .menu-item:hover .menu-item-link {
  color: #ffffff;
}
#executive-education .rich-text a {
  color: #7E3E93;
}
#executive-education .infobox .rich-text a {
  color: #f7cfe4;
}
#executive-education .search-login .btn {
  background-color: #4F7100;
}
#executive-education .a-url .iconDownload {
  background-image: url("../img/icons/ico_download_EECpurple.webp");
}
#executive-education .a-url .fileExtension {
  color: #7E3E93;
}
@media (max-width: 767px) {
  #executive-education .teaser-items-mobile-item,
#executive-education .home-buttom {
    background: #4F7100;
  }
  #executive-education .menu-search,
#executive-education .search input,
#executive-education .dropdown-menu,
#executive-education .search-login .btn {
    background: #84BD00;
  }
  #executive-education .menu-item {
    border-bottom: 1px solid #4F7100;
  }
  #executive-education .socialmedia-mobile {
    border-top: 1px solid #4F7100;
  }
  #executive-education .socialmedia-mobile a {
    border-right: 1px solid #4F7100;
  }
  #executive-education .teaser-items-mobile-item:nth-child(2n) {
    border-left: 1.5px solid #84BD00;
  }
  #executive-education .teaser-items-mobile-item:nth-child(odd) {
    border-right: 1.5px solid #84BD00;
  }
}

.video-template .uc-embedding-container {
  position: absolute !important;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 99;
  min-height: unset !important;
}
.video-template .uc-embedding-container .uc-embedding-wrapper {
  height: 100%;
  max-height: 95%;
}
.video-template .uc-embedding-container .uc-embedding-wrapper h3 {
  font-size: 17px;
  margin: 0 auto 12px !important;
}
.video-template .uc-embedding-container .uc-embedding-wrapper .description-text {
  display: none;
}

.btn {
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 18px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.1;
  cursor: pointer;
}
.btn-white {
  background: #ffffff;
  color: #023670;
  transition: background-color 0.4s, color 0.4s;
}
.btn-white:hover, .btn-white:focus {
  background: #023670;
  color: #ffffff;
}
.btn-pink {
  background: #cc008e;
  transition: background-color 0.4s;
  text-transform: none;
}
.btn-pink.btn {
  color: #ffffff;
  font-weight: 500;
}
.btn-pink:hover, .btn-pink:focus {
  background: #b3007c;
}
.btn-light-blue {
  background: #009DE0;
  color: #ffffff;
  transition: background-color 0.4s;
  border: none;
}
.btn-light-blue:hover, .btn-light-blue:focus {
  background: #0079ad;
}
.btn-empty {
  color: #023670;
  border: 1px solid #023670;
  background: #ffffff;
}
.btn-more {
  color: #cc008e;
  padding: 0;
  text-align: right;
  overflow: visible;
  margin-left: -50%;
  position: relative;
  padding: 5px;
  padding-right: 10px;
  padding-bottom: 0;
  margin-bottom: 5px;
}
.btn-more:after {
  content: url("../img/elements/ico_arrow_pink.webp");
  position: absolute;
  right: 0;
  bottom: 0;
}
.btn-block-left {
  text-align: left;
}
@media (max-width: 767px) {
  .btn-block-left {
    text-align: center;
  }
}
.btn-block-right {
  margin-top: 20px;
  text-align: right;
}
.btn-mobile {
  display: none;
  margin-top: 20px;
}
.btn-large {
  padding-left: 35px;
  padding-right: 35px;
}
.btn-disabled {
  border: 1px solid #9b9b9b;
  background-color: #ffffff;
  color: #9b9b9b;
  pointer-events: none;
  cursor: default;
}

@media (max-width: 767px) {
  .btn-mobile {
    display: flex;
  }
}
.blue-strip {
  background: #022a57;
}

.header-panel,
.header-panel_second {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-panel_second,
.header-panel_second_second {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.header-panel_second a,
.header-panel_second_second a {
  text-decoration: none;
  color: #ffffff;
  padding: 0 6px;
}
.header-panel_second a.select,
.header-panel_second_second a.select {
  padding: 1px 4px;
}
.header-panel_second a.select:focus,
.header-panel_second_second a.select:focus {
  outline-offset: -3px !important;
}
.header-panel_second a.newsletter,
.header-panel_second_second a.newsletter {
  display: flex;
  gap: 2px;
  align-items: center;
  font-weight: 500;
}
.header-panel_second .dropdown,
.header-panel_second_second .dropdown {
  border-right: none;
}

.socialmedia {
  display: flex;
  align-items: center;
}
.socialmedia li {
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
}
.socialmedia li:focus {
  outline: 5px solid #e500a1;
}
.socialmedia li > *:focus {
  outline: 5px solid #e500a1;
}
.socialmedia li:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.socialmedia li:focus {
  outline-offset: -5px;
  outline-width: 3px;
  outline-style: solid;
}
.socialmedia li img {
  width: 15px;
  height: 15px;
}
.socialmedia li .linkedin img {
  height: 15px;
}
.socialmedia .newsletter {
  color: #ffffff;
  text-decoration: none;
}
.socialmedia .newsletter:after {
  content: ">";
  padding-left: 8px;
  transform: scaleX(0.5);
}

.lang-color {
  display: flex;
}

.color-contrast {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  width: 26px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-contrast:focus {
  outline: 5px solid #e500a1;
}
.color-contrast > *:focus {
  outline: 5px solid #e500a1;
}
.color-contrast button {
  border: none;
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.color-contrast button:focus {
  outline-offset: 2px;
  outline-width: 3px;
  outline-style: solid;
}
.color-contrast button:after {
  content: "";
  position: absolute;
  background: #ffffff;
  height: 20px;
  width: 9px;
  left: -2px;
  top: -1px;
}
@media (max-width: 767px) {
  .color-contrast {
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-right: 10vw;
    height: 32px;
    width: 32px;
  }
}

.language-switch {
  position: relative;
  width: 100px;
}

.dropdown {
  display: inline-block;
  transition: all 0.5s ease;
  position: relative;
  font-size: 12px;
  color: #ffffff;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  text-align: left;
}
.dropdown:focus {
  outline: 5px solid #e500a1;
}
.dropdown > *:focus {
  outline: 5px solid #e500a1;
}
.dropdown .select {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 1px 2px;
}
.dropdown .select:after {
  content: "";
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #ffffff;
  margin-left: 3px;
}
.dropdown a {
  text-decoration: none;
  color: #ffffff;
}
.dropdown a.select:focus {
  outline-offset: -5px;
}
.dropdown a:focus {
  outline-offset: 2px;
  outline-width: 3px;
  outline-style: solid;
}

.dropdown-menu {
  position: absolute;
  background-color: #022a57;
  width: 100%;
  left: 0;
  margin-top: 1px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
}
.dropdown-menu button,
.dropdown-menu span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  border: none;
  background: transparent;
  color: white;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.dropdown-menu.show {
  display: flex;
  position: relative;
  background: transparent;
}
.dropdown-menu.show button,
.dropdown-menu.show span {
  list-style: none;
}
.dropdown-menu.show button:focus,
.dropdown-menu.show span:focus {
  outline: 3px solid #e500a1;
  outline-offset: -5px;
}
.dropdown-menu.show button:last-child,
.dropdown-menu.show span:last-child {
  border-left: 1px solid hsla(0deg, 0%, 100%, 0.4);
}
.dropdown-menu.show button.active,
.dropdown-menu.show span.active {
  text-decoration: underline;
}
.open .dropdown-menu {
  display: block;
}

.white-strip {
  background: #fff;
}
.white-strip .menu-wrapper {
  padding: 1rem 0.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.white-strip .menu-wrapper .logo {
  position: relative;
  align-self: flex-end;
  margin-bottom: -16px;
}
.white-strip .menu-wrapper .logo a {
  display: flex;
  align-items: flex-end;
}
.white-strip .menu-wrapper .logo img {
  z-index: 25;
  width: 170px;
}
.white-strip .menu-wrapper .logo img.en-logo {
  max-width: 220px;
  width: 220px;
}
.white-strip .menu-wrapper .logo .koso {
  z-index: 24;
  position: absolute;
  top: 20px;
  width: 200px;
  height: 66px;
  margin-left: -12px;
  background: #ffffff;
  transform: skewY(-10deg);
}
.white-strip .menu-wrapper .mobile-menu-focus {
  position: absolute;
  top: -5%;
}
.white-strip .menu-wrapper .menu-search {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.white-strip .menu-wrapper .menu-search .search-login {
  display: flex;
  justify-content: flex-end;
}
.white-strip .menu-wrapper .menu-search .search-login .lang-login {
  margin-top: 5px;
}
.white-strip .menu-wrapper .menu-search .search-login .search {
  display: flex;
  align-items: center;
}
.white-strip .menu-wrapper .menu-search .search-login .search form {
  display: flex;
}
.white-strip .menu-wrapper .menu-search .search-login .search input {
  padding: 8px 30px 8px 14px;
  border: none;
  background: #f7f7f7;
  width: 220px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 300;
}
.white-strip .menu-wrapper .menu-search .search-login .search .search-btn {
  margin-left: -30px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.white-strip .menu-wrapper .menu-search .search-login .btn {
  margin-left: 5px;
  border: none;
  padding: 7px 14px;
}
.white-strip .menu-wrapper .menu-search .search-login .btn img {
  margin-right: 5px;
}
.white-strip .menu-wrapper .menu-search .menu {
  margin-top: 20px;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list {
  display: flex;
  justify-content: flex-end;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item {
  font-size: 16px;
  font-weight: 500;
  padding: 1rem 6px;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item {
    padding: 1rem 18px;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link {
  text-decoration: none;
  font-weight: 500;
  color: #023670;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link:focus {
  outline: 5px solid #e500a1;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link > *:focus {
  outline: 5px solid #e500a1;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link:focus, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link:focus-within {
  outline-offset: 2px;
  outline-width: 3px;
  outline-style: solid;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item {
    margin: 10px 0;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item a {
    font-weight: 300;
    color: #022a57;
    text-decoration: none !important;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item a:focus {
    outline: 5px solid #e500a1;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item a > *:focus {
    outline: 5px solid #e500a1;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item.four-blocks a {
    display: block;
    margin: 10px 0;
    flex-basis: 50%;
    border: none;
    min-height: 90px;
    padding: 8px;
    max-height: 100px;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item.four-blocks a p,
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .sub-menu-item.four-blocks a span {
    font-weight: 300;
    color: #ffffff !important;
    text-decoration: none !important;
    font-size: 14px;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus {
  background: #023670;
  color: #ffffff;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus {
    background: #009DE0;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover .menu-item-link, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within .menu-item-link, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus .menu-item-link {
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover .menu-item-link svg, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within .menu-item-link svg, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus .menu-item-link svg {
  margin-left: 6px;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover .menu-item-link:after, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within .menu-item-link:after, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus .menu-item-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  left: 50%;
  top: 2rem;
  z-index: 50;
  transform: translateX(-50%);
  border-top: 20px solid #023670;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover .menu-item-link:after, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within .menu-item-link:after, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus .menu-item-link:after {
    content: none;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:hover > .sub-menu, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus-within > .sub-menu, .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:focus > .sub-menu,
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu {
  background: #f7f7f7;
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
  z-index: 20;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu {
    background: transparent;
    visibility: visible;
    opacity: 1;
    position: static;
    display: block;
  }
}
@media (min-width: 768px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu {
    display: block !important;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container {
  padding: 1.5rem 0 0.5rem 0;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a {
  display: flex;
  text-decoration: none;
  color: #ffffff;
  align-items: flex-end;
  padding: 12px;
  position: relative;
  min-height: 120px;
  margin-bottom: 10px;
  outline-offset: -2px;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a {
    padding: 12px;
    min-height: 110px;
    max-height: 120px;
    width: 50%;
  }
}
@media (max-width: 440px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a {
    padding: 8px;
    min-height: 90px;
    max-height: 100px;
    padding-right: 16px;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a:focus {
  outline-style: solid;
  outline-width: 3px;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a:nth-child(2n+1) {
  border-right: 5px solid #f7f7f7;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a:nth-child(2n+1) {
    border-right-color: #009DE0;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a:nth-child(2n) {
  border-left: 5px solid #f7f7f7;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a:nth-child(2n) {
    border-left-color: #009DE0;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a .menu-thumbnail-overlay {
  background: rgba(2, 54, 112, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a span {
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a span {
    font-size: 14px;
    font-weight: 400;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a .arrow {
  z-index: 1;
  position: absolute;
  right: 4px;
  bottom: 6px;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a p,
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container .four-blocks a span {
    color: #ffffff !important;
    text-decoration: none !important;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul {
    margin-right: 0;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item {
  padding: 0 20px 20px 0;
  position: relative;
  display: flex;
  align-items: center;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item .arrow {
  margin-left: 6px;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item a {
  text-decoration: none;
  color: #023670;
  font-weight: 400;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item a:focus {
  outline: 5px solid #e500a1;
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item a > *:focus {
  outline: 5px solid #e500a1;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item a {
    text-decoration: none !important;
  }
}
.white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item:not(:last-child):after {
  content: "";
  background-color: #ececec;
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 10px;
  left: 0px;
}
@media (max-width: 767px) {
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .sub-menu .container ul .sub-menu-item:not(:last-child):after {
    background-color: transparent;
  }
}

.menu-wrapper .logo img {
  max-width: 170px;
}

.mobile-button {
  float: right;
  height: 100%;
  width: 65px;
  background: #009DE0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 0;
}
.mobile-button:focus, .mobile-button:focus-visible {
  outline: 3px solid #e500a1 !important;
}
.mobile-button span {
  background: #ffffff;
  width: 50%;
  height: 3px;
}

.close-button {
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}
.close-button img {
  width: 12px;
  margin-left: 3px;
  margin-bottom: -1px;
}

.socialmedia-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #007db3;
  flex-shrink: 0;
}
.socialmedia-mobile li {
  border-right: 1px solid #007db3;
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline-offset: -5px;
}
.socialmedia-mobile li img {
  max-width: 20px;
  max-height: 20px;
}
.socialmedia-mobile li:last-child {
  border: none;
}

@media (max-width: 767px) {
  .mobile-button:focus:focus {
    outline: 5px solid #e500a1;
  }
  .mobile-button:focus > *:focus {
    outline: 5px solid #e500a1;
  }
  .menu-wrapper {
    padding-top: 0;
    padding-right: 0;
  }
  .menu-wrapper *:focus {
    outline: 5px solid #e500a1;
  }
  .menu-wrapper * > *:focus {
    outline: 5px solid #e500a1;
  }
  .menu-wrapper .mobile-button:focus {
    outline-offset: -6px;
    outline-style: solid;
    outline-width: 3px;
  }
  .menu-wrapper .logo:not(.logo-homepage) img {
    width: 75px;
  }
  .menu-wrapper .logo:not(.logo-homepage) .koso {
    width: 110px;
    height: 60px;
  }
  .white-strip {
    height: 50px;
  }
  .white-strip .menu-wrapper {
    padding: 0 0 0 10pt;
  }
  .white-strip .menu-wrapper .logo:not(.logo-homepage) {
    margin-bottom: -3px;
  }
  .white-strip .menu-wrapper .logo:not(.logo-homepage) img {
    width: 80px;
    height: auto;
  }
  .white-strip .menu-wrapper .logo:not(.logo-homepage) .koso {
    width: 100px;
    height: 32px;
    margin-left: -6px;
    top: 12px;
  }
  .white-strip .menu-wrapper .logo.logo-homepage {
    margin-bottom: -20px;
  }
  .white-strip .menu-wrapper .logo.logo-homepage img {
    width: 130px;
    margin-bottom: -10px;
  }
  .white-strip .menu-wrapper .logo.logo-homepage .koso {
    height: 38px;
    margin-left: -15px;
    top: 34px;
  }
  .white-strip .menu-wrapper .menu-search {
    top: -100%;
    left: 0;
    position: absolute;
    width: 100vw;
    background: #009DE0;
    justify-content: flex-start;
    display: none;
    z-index: 9999;
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
  }
  .white-strip .menu-wrapper .menu-search.show {
    position: fixed;
    display: flex;
    animation: slideIn 0.5s ease forwards;
  }
  .white-strip .menu-wrapper .menu-search.hide {
    animation: slideOut 0.5s ease forwards;
  }
  .white-strip .menu-wrapper .menu-search > div:first-child {
    flex: 1 0 auto;
    position: relative;
  }
  .white-strip .menu-wrapper .menu-search .teaser-items-mobile {
    margin: 10px 0 0 0;
  }
  .white-strip .menu-wrapper .menu-search .teaser-items-mobile .teaser-items-mobile-item {
    background: #0079ad;
    padding: 20px;
    text-align: center;
    margin-bottom: 3px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    outline-offset: -5px;
  }
  .white-strip .menu-wrapper .menu-search .teaser-items-mobile .teaser-items-mobile-item:nth-child(2n+1) {
    border-right: #009DE0 solid 1.5px;
  }
  .white-strip .menu-wrapper .menu-search .teaser-items-mobile .teaser-items-mobile-item:nth-child(2n) {
    border-left: #009DE0 solid 1.5px;
  }
  .white-strip .menu-wrapper .menu-search .teaser-items-mobile .home-buttom {
    background: #008bc7;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none;
    outline-offset: -5px;
  }
  .white-strip .menu-wrapper .menu-search .search-login {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
  .white-strip .menu-wrapper .menu-search .search-login .search input {
    color: #ffffff;
    background: #009DE0;
    border: 1px solid #ffffff;
  }
  .white-strip .menu-wrapper .menu-search .search-login .search ::placeholder {
    color: #ffffff;
    opacity: 1;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    order: -1;
    width: 100%;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .btn {
    background: transparent;
    padding: 0;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown {
    border: none;
    width: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown:focus {
    outline: 5px solid #e500a1;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown > *:focus {
    outline: 5px solid #e500a1;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    height: auto;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown a.select:focus {
    outline-offset: 0px;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown .dropdown-menu {
    background: #009DE0;
    border: none;
    padding: 2px;
    text-align: center;
  }
  .white-strip .menu-wrapper .menu-search .search-login .lang-login .dropdown .dropdown-menu button {
    font-weight: 500;
    font-size: 14px;
    padding: 1px;
  }
  .white-strip .menu-wrapper .menu-search .menu {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    margin-top: 0;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list {
    display: block;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item {
    border-bottom: 1px solid #007db3;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item-dropdown {
    display: flex;
    justify-content: space-between;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item-dropdown.active svg {
    transform: rotate(90deg);
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item-dropdown svg {
    margin-right: 20px;
    transition: all 300ms;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item .menu-item-link svg {
    margin-left: 6px;
  }
  .white-strip .menu-wrapper .menu-search .menu .menu-list .menu-item:last-child {
    border-bottom: none;
  }
}
@keyframes slideIn {
  from {
    top: -50%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -50%;
    opacity: 0;
  }
}
.ft-black-strip {
  height: 50px;
  background: #022a57;
}
.ft-black-strip:focus {
  outline: 5px solid #e500a1;
}
.ft-black-strip > *:focus {
  outline: 5px solid #e500a1;
}
.ft-black-strip nav {
  height: 100%;
}
.ft-black-strip nav ul {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ft-black-strip nav ul .ico {
  margin: 0 10px;
}

.ft-white-strip {
  background: #0b4383;
  overflow: hidden;
  padding: 10px 0;
}
.ft-white-strip:focus {
  outline: 5px solid #e500a1;
}
.ft-white-strip > *:focus {
  outline: 5px solid #e500a1;
}
.ft-white-strip .ft-box {
  position: relative;
  display: flex;
  align-items: center;
}
.ft-white-strip .ft-box .ft-triangle {
  width: 2650px;
  height: 470px;
  position: absolute;
  right: 90%;
  top: -18px;
  background: linear-gradient(to bottom right, #023670 49.8%, transparent 50%);
}
.ft-white-strip .ft-box span {
  margin-right: 10px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 6%;
}
@media (max-width: 767px) {
  .ft-white-strip .ft-box span {
    margin-left: 0;
  }
}
.ft-white-strip .ft-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ft-white-strip .ft-icons img {
  width: 15px;
  height: 15px;
}

.ft-blue-strip {
  background: #023670;
}
.ft-blue-strip:focus {
  outline: 5px solid #e500a1;
}
.ft-blue-strip > *:focus {
  outline: 5px solid #e500a1;
}
.ft-blue-strip .container {
  padding-bottom: 33px;
  padding-top: 37px;
}
.ft-blue-strip .container .ft-logo,
.ft-blue-strip .container .address {
  align-self: center;
}
.ft-blue-strip .container .ft-logo img,
.ft-blue-strip .container .address img {
  width: 100%;
  max-width: 200px;
  height: auto;
}
.ft-blue-strip .container .waca-logo {
  align-items: center;
  display: flex;
}
@media (max-width: 767px) {
  .ft-blue-strip .container .waca-logo {
    padding-top: 15px;
  }
}
.ft-blue-strip .container .waca-logo img {
  max-width: 440px;
  width: 100%;
}
.ft-blue-strip .container .privacy-settings-cont {
  margin-top: 10px;
}
.ft-blue-strip .container .privacy-settings-cont .privacy-settings {
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
}
.ft-blue-strip .container .address ul {
  margin-top: 15px;
  margin-right: 50px;
}
.ft-blue-strip .container .address ul li {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}
.ft-blue-strip .container .address ul li a {
  color: #ffffff;
  text-decoration: none;
}
.ft-blue-strip .container .address ul li .email {
  text-decoration: underline;
}
.ft-blue-strip .container .services-info {
  display: flex;
  justify-content: space-around;
}
.ft-blue-strip .container .services,
.ft-blue-strip .container .info,
.ft-blue-strip .container .newsletter {
  position: relative;
  color: #ffffff;
}
.ft-blue-strip .container .services h3,
.ft-blue-strip .container .info h3,
.ft-blue-strip .container .newsletter h3 {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 24px;
}
.ft-blue-strip .container .services li,
.ft-blue-strip .container .info li,
.ft-blue-strip .container .newsletter li {
  font-size: 14px;
  line-height: 24px;
}
.ft-blue-strip .container .services li a,
.ft-blue-strip .container .info li a,
.ft-blue-strip .container .newsletter li a {
  text-decoration: none;
  color: #ffffff;
}
.ft-blue-strip .container .services:before,
.ft-blue-strip .container .info:before,
.ft-blue-strip .container .newsletter:before {
  content: "";
  background-color: #024289;
  position: absolute;
  height: 120%;
  width: 1px;
  top: -10px;
  left: -20px;
}
.ft-blue-strip .container .newsletter {
  height: 100%;
}
.ft-blue-strip .container .newsletter .newsletter-input-box {
  position: relative;
  max-width: 240px;
}
.ft-blue-strip .container .newsletter .newsletter-input {
  border-radius: 8px;
  padding: 8px;
  border: #ffffff;
  width: 100%;
  border: none;
  height: 36px;
  font-size: 14px;
  padding-right: 38px;
}
.ft-blue-strip .container .newsletter button {
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.ft-blue-strip .container .newsletter button:focus {
  outline: none;
}
.ft-blue-strip .container .newsletter .checkbox {
  margin-top: 15px;
  position: relative;
}
.ft-blue-strip .container .newsletter .checkbox input {
  opacity: 0;
  position: absolute;
  width: 5px;
}
.ft-blue-strip .container .newsletter .checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  font-size: 12px;
  line-height: 1.5;
}
.ft-blue-strip .container .newsletter .checkbox label a {
  text-decoration: underline;
}
.ft-blue-strip .container .newsletter .checkbox label:after, .ft-blue-strip .container .newsletter .checkbox label:before {
  position: absolute;
  content: "";
  display: inline-block;
}
.ft-blue-strip .container .newsletter .checkbox label:before {
  height: 16px;
  width: 16px;
  left: 0px;
  background: #ffffff;
}
.ft-blue-strip .container .newsletter .checkbox label:after {
  height: 4px;
  width: 8px;
  border-left: 2px solid #009DE0;
  border-bottom: 2px solid #009DE0;
  transform: rotate(-55deg);
  left: 4px;
  top: 4px;
}
.ft-blue-strip .container .newsletter .checkbox input[type=checkbox] + label::after {
  content: none;
}
.ft-blue-strip .container .newsletter .checkbox input[type=checkbox]:checked + label::after {
  content: "";
}
.ft-blue-strip .container .newsletter .checkbox input[type=checkbox]:focus + label::before {
  outline: #e500a1 auto 5px;
}
.ft-blue-strip .container .services::before {
  width: 2px;
}

.fact-figures {
  background: url(/assets/img/background/bg_zahlen-fakten.webp) center no-repeat;
  background-size: cover;
  color: #ffffff;
  padding: 35px 0 40px 0;
}
.fact-figures h3 {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .fact-figures h3 {
    margin-bottom: 5px;
  }
}
.fact-figures .number-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fact-figures .number-text .ff-number {
  font-size: 3rem;
  margin: 20px 20px 6px 20px;
}
.fact-figures .number-text .ff-text {
  font-size: 14px;
  font-weight: 400;
}

.apply-now {
  padding: 40px 0;
}
.apply-now .container .content-box {
  max-width: 800px;
  padding: 5px;
  margin: auto;
}
.apply-now .container .content-box .row {
  opacity: 1;
  margin: auto;
}
.apply-now .container .content-box .row .part1 {
  height: 100%;
  padding: 15px;
}
.apply-now .container .content-box .row .part1 .title {
  color: #009DE0;
  padding: 20px 0;
  font-weight: 500;
}
.apply-now .container .content-box .row .part1 .content {
  height: 100%;
  width: 100%;
  color: #555659;
  font-family: Roboto;
  font-size: 16px;
}
@media (max-width: 767px) {
  .apply-now .container .content-box .row .part1 .content {
    font-size: 14px;
  }
}
.apply-now .container .content-box .row .part2 {
  background-color: #009DE0;
}
.apply-now .container .content-box .row .part2 .card {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0px;
  padding: 60px 23px 25px 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.apply-now .container .content-box .row .part2 .card h2 {
  text-align: left;
}
.apply-now .container .content-box .row .part2 .card .btnbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.apply-now .container .content-box .row .part2 .card .btnbox .applynowbutton:hover {
  background-color: #ececec;
}

@media (max-width: 767px) {
  .apply-now .container .content-box {
    width: 100%;
    padding: 10px;
  }
}
.three-column-text .container .row .Column .title {
  height: 39px;
  width: 380px;
  color: #023670;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 24px;
}
.three-column-text .container .row .Column .content {
  height: 100%;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
}

.three-column-image .container {
  height: 100%;
  width: 100%;
}
.three-column-image .container .row {
  grid-column-gap: 10px;
}
.three-column-image .container .row .Column {
  height: 100%;
  width: 100%;
}
.three-column-image .container .row .Column img {
  height: 200px;
  width: 100%;
  margin: 40px 0 10px 0;
}
.three-column-image .container .row .Column .textlink {
  height: 36px;
  width: 100%;
  line-height: 16px;
  text-align: right;
}
.three-column-image .container .row .Column .textlink a:link {
  color: #CC008E;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}
.three-column-image .container .row .Column .textlink a:hover {
  text-decoration: underline;
}

.course-navigator {
  background: url(/assets/img/background/bg_studieren.webp) no-repeat center;
  background-size: cover;
  padding: 40px 0;
  color: #ffffff;
}
.course-navigator h3 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  letter-spacing: 0.8px;
}
.course-navigator-text {
  margin-bottom: 25px;
}
.course-navigator-text p {
  color: #ffffff;
  font-size: 22px;
  line-height: 28px;
}
.course-navigator-text p span.lang-text {
  color: #ffffff;
  font-weight: 500;
}
.course-navigator .btn:focus {
  outline: 5px solid #e500a1;
}
.course-navigator .btn > *:focus {
  outline: 5px solid #e500a1;
}

.one-column-text .mainTitle {
  text-align: left;
  height: 39px;
  color: #023670;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 21px;
}
.one-column-text .mainText {
  color: #555659;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
.one-column-text .headline-styles .headline2, .one-column-text .headline-styles .headline3 {
  text-align: left;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .one-column-text .mainTitle {
    margin-bottom: 10px;
  }
}
.intro-text {
  padding: 30px 0;
}
.intro-text .intro {
  height: 100%;
  width: 778px;
  color: #555659;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
.intro-text .intro-text-image {
  background-size: cover;
  height: 280px;
  width: 100%;
}

@media (max-width: 767px) {
  .intro-text {
    padding-bottom: 0;
  }
  .intro-text .underline-headline {
    font-weight: 300;
    text-align: left;
    font-size: 24px;
    margin-bottom: 25px;
    left: unset;
    transform: none;
  }
  .intro-text .underline-headline:after {
    content: none;
  }
  .intro-text .intro-text-image {
    margin-top: 20px;
    width: calc(100% + 20px + 1rem);
    margin-left: calc(-10px - 0.5rem);
  }
}
.head-image-slider {
  margin-bottom: 270px;
  /* Position the "next button" to the right */
  /* On hover, add a black background color with a little bit see-through */
  /* The dots/bullets/indicators */
  /* On larger screens, higher the button */
  /* On smaller screens, decrease text size */
  /* On large screens,normal the button position */
}
.head-image-slider .container-fluid {
  padding: 0;
  margin: 0;
}
.head-image-slider .prev,
.head-image-slider .next {
  z-index: 10;
  display: none;
}
.head-image-slider .mySlides {
  background-position-x: center;
  margin-bottom: -20px;
  outline-offset: -5px;
}
.head-image-slider .mySlides:focus {
  outline: 5px solid #e500a1;
}
.head-image-slider .mySlides > *:focus {
  outline: 5px solid #e500a1;
}
.head-image-slider .prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 48px 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 48px;
  transition: 0.6s ease;
  border-radius: 0 10px 10px 0;
  user-select: none;
  opacity: 0.3;
}
.head-image-slider .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 48px 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 48px;
  transition: 0.6s ease;
  border-radius: 10px 0 0 10px;
  user-select: none;
  opacity: 0.3;
  right: 0;
}
.head-image-slider .prev:hover,
.head-image-slider .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.head-image-slider .dots {
  display: none;
  top: 20%;
  position: absolute;
  /*display: inline-block;*/
  text-align: center;
  margin: auto;
  right: 20%;
  background-color: #555659;
  width: 40px;
  padding: 10px;
  border-radius: 20px;
  opacity: 0.2;
}
.head-image-slider .dots .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 4px 0;
  background-color: #555659;
  border-radius: 50%;
  display: inline-block;
  border: solid 2px #ffffff;
  transition: background-color 0.6s ease;
}
.head-image-slider .dots .active,
.head-image-slider .dots .dot:hover {
  background-color: #ffffff;
}
.head-image-slider .fade {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 724px;
  min-height: 724px;
  background-size: cover;
}
.head-image-slider .facettes-box {
  shape-outside: polygon(100% 0, 100% 70.5%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 70.5%, 0 100%, 0 0);
  background-color: rgba(2, 36, 70, 0.2);
  position: absolute;
  z-index: 1;
  top: 0;
  height: 205px;
  width: 344px;
  left: 16%;
  padding-left: 14px;
}
@media only screen and (max-width: 1725px) {
  .head-image-slider .facettes-box {
    display: none;
  }
}
.head-image-slider .facettes-box .facettes {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  position: absolute;
  padding: 20px 20px 20px 10px;
  font-family: "Roboto", sans-serif;
  text-shadow: 1px 1px 1px rgba(2, 54, 112, 0.8);
}
.head-image-slider .facettes-box-outer {
  display: none;
  top: initial;
  bottom: 12vw;
}
@media only screen and (max-width: 1725px) {
  .head-image-slider .facettes-box-outer {
    display: block;
  }
}
@media only screen and (min-width: 1220px) {
  .head-image-slider .facettes-box-outer {
    bottom: 8vw;
  }
}
.head-image-slider .box {
  position: absolute;
  bottom: -233px;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.head-image-slider .box .inner-box {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.head-image-slider .box .inner-box:after {
  background-color: #023670;
  content: " ";
  width: 100%;
  position: absolute;
  top: 0;
  right: -88%;
  height: 300px;
  transform: translateX(-1px);
}
@media only screen and (min-width: 2135px) {
  .head-image-slider .box .inner-box:after {
    right: -77%;
  }
}
@media only screen and (min-width: 2445px) {
  .head-image-slider .box .inner-box:after {
    right: -67%;
  }
}
@media only screen and (min-width: 2815px) {
  .head-image-slider .box .inner-box:after {
    right: -59%;
  }
}
@media only screen and (min-width: 3195px) {
  .head-image-slider .box .inner-box:after {
    right: -52%;
  }
}
@media only screen and (min-width: 3625px) {
  .head-image-slider .box .inner-box:after {
    right: -46%;
  }
}
.head-image-slider .box .facette-text {
  position: absolute;
  left: 60%;
  width: 25vw;
  height: auto;
  top: 32px;
  max-width: 340px;
  min-width: 250px;
}
@media only screen and (min-width: 1000px) {
  .head-image-slider .box .facette-text {
    top: 40px;
  }
}
@media only screen and (min-width: 1300px) {
  .head-image-slider .box .facette-text {
    top: 50px;
  }
}
@media only screen and (min-width: 1450px) {
  .head-image-slider .box .facette-text {
    top: 65px;
  }
}
@media only screen and (min-width: 1870px) {
  .head-image-slider .box .facette-text {
    left: 1280px;
  }
}
.head-image-slider .box:before {
  background-color: #023670;
  content: " ";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 251px;
  z-index: 2;
}
.head-image-slider .box img {
  display: block;
  width: 100%;
  min-height: auto;
  max-height: none;
  max-width: 1885px;
  z-index: 1;
}
@media only screen and (max-width: 1870px) {
  .head-image-slider {
    margin-bottom: 240px;
  }
  .head-image-slider .box {
    bottom: -208px;
  }
  .head-image-slider .box:before {
    display: none;
  }
  .head-image-slider .box .inner-box:after {
    display: none;
  }
}
@media only screen and (max-width: 1755px) {
  .head-image-slider .box .facettes-box {
    top: 0;
  }
}
@media only screen and (max-width: 1714px) {
  .head-image-slider {
    margin-bottom: 200px;
  }
  .head-image-slider .box {
    bottom: -165px;
  }
}
@media only screen and (max-width: 1602px) {
  .head-image-slider .box .facettes-box {
    top: 0;
  }
}
@media only screen and (max-width: 1565px) {
  .head-image-slider {
    margin-bottom: 160px;
  }
  .head-image-slider .box {
    bottom: -120px;
  }
}
@media only screen and (max-width: 1220px) {
  .head-image-slider .box {
    bottom: -68px;
  }
}
@media only screen and (max-width: 920px) {
  .head-image-slider .box {
    bottom: -42px;
  }
}
@media only screen and (max-width: 720px) {
  .head-image-slider .box {
    bottom: -20px;
  }
  .head-image-slider .box .facette-text {
    top: 26px;
    right: 22px;
    left: unset;
  }
}
@media only screen and (max-width: 560px) {
  .head-image-slider .box {
    bottom: -14px;
  }
  .head-image-slider .box .facette-text {
    top: 20px;
    right: 18px;
    min-width: 240px;
  }
}
@media only screen and (max-width: 460px) {
  .head-image-slider .box {
    bottom: -8px;
  }
  .head-image-slider .box .facette-text {
    top: 14px;
    right: 16px;
    min-width: 230px;
  }
}
@media only screen and (max-width: 400px) {
  .head-image-slider .box {
    bottom: 0px;
  }
  .head-image-slider .box .facette-text {
    top: 12px;
    right: 11px;
    min-width: 220px;
  }
}
@media only screen and (max-width: 370px) {
  .head-image-slider .box .facette-text {
    top: 10px;
    right: 10px;
    min-width: 200px;
  }
}
.head-image-slider .slogan {
  color: #ffffff;
  position: absolute;
  bottom: 15%;
  right: 0;
  width: 15%;
  z-index: 9;
  font-size: 30px;
  font-weight: 500;
  margin-right: 15%;
  line-height: 35px;
}
.head-image-slider .slogan img {
  height: auto;
  width: 200px;
  max-height: initial;
  max-width: initial;
  min-height: initial;
}
.head-image-slider img {
  width: 100%;
  height: 100%;
  max-height: 724px;
  min-height: 724px;
  bottom: 0;
  display: block;
  overflow: hidden;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 970px) {
  .head-image-slider .prev,
.head-image-slider .next {
    top: 35%;
  }
}
@media only screen and (max-width: 750px) {
  .head-image-slider .prev,
.head-image-slider .next {
    top: 30%;
    font-size: 24px;
    padding: 24px 12px;
  }
}
@media only screen and (max-width: 320px) {
  .head-image-slider .prev,
.head-image-slider .next {
    top: 20%;
    font-size: 24px;
    padding: 24px 12px;
  }
}

@media (max-width: 767px) {
  .head-image-slider .fade {
    max-height: 420px;
    min-height: 420px;
  }
  .head-image-slider .facettes-box {
    left: 15%;
    width: 36%;
  }
  .head-image-slider .facettes-box .facettes {
    font-size: 24px;
    line-height: 30px;
  }
  .head-image-slider .slogan {
    bottom: 10%;
  }
}
@media only screen and (max-width: 560px) {
  .head-image-slider .facettes-box {
    width: 38%;
  }
  .head-image-slider .facettes-box .facettes {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 460px) {
  .head-image-slider .facettes-box {
    width: 42%;
  }
  .head-image-slider .facettes-box .facettes {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 380px) {
  .head-image-slider .facettes-box {
    width: 46%;
  }
}
.text-slider {
  overflow: hidden;
  padding: 30px 0 15px 0;
  background: url(/assets/img/background/bg_slider.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  outline-offset: -6px;
}
.text-slider .row {
  align-items: flex-end;
}
.text-slider .row .text-slider-box {
  position: relative;
}
.text-slider .row.handlers {
  margin-top: 30px;
}
.text-slider .row .slider-item {
  position: absolute;
  opacity: 0;
  top: 0;
  z-index: -1;
  transition: all 800ms ease-out;
  color: #023670;
  width: 100%;
}
.text-slider .row .slider-item.active {
  opacity: 1;
  right: unset;
  position: static;
  z-index: 2;
}
.text-slider .row .slider-item.active-left {
  animation: slide-in-left 800ms forwards;
}
.text-slider .row .slider-item.active-right {
  animation: slide-in-right 800ms forwards;
}
.text-slider .row .slider-item.left {
  animation: slide-out-left 800ms forwards;
}
.text-slider .row .slider-item.right {
  animation: slide-out-right 800ms forwards;
}
.text-slider .row .slider-item h3 {
  font-size: 28px;
  margin-bottom: 28px;
  font-weight: 400;
  line-height: 1.2;
}
.text-slider .row .slider-item h3 p {
  font-size: 28px;
}
.text-slider .row .slider-item p {
  font-size: 18px;
  line-height: 24px;
}
.text-slider .row .text-slider-items {
  font-size: 18px;
  font-weight: 400;
  color: #023670;
  padding-left: 15px;
}
@media (max-width: 767px) {
  .text-slider .row .text-slider-items {
    font-size: 14px;
    padding-left: 0.5rem;
  }
}
.text-slider .row .strip {
  background: #ffffff;
  height: 2px;
}
.text-slider .row .strip .pink-strip {
  background: #cc008e;
  height: 100%;
  width: 100%;
}
.text-slider .row .strip .pink-strip.animate {
  animation: color-me-in 5s linear forwards;
}
.text-slider .row .buttons {
  text-align: right;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .text-slider .row .buttons {
    padding-right: 0.5rem;
  }
}
.text-slider .row .buttons img {
  width: 10px;
  cursor: pointer;
  height: auto;
}
.text-slider .text-slider-progress {
  margin-top: 10px;
}

@keyframes color-me-in {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
.quote {
  padding: 60px 0;
  background: url("../img/background/bg_quote.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.quote:focus {
  outline: 5px solid #e500a1;
}
.quote > *:focus {
  outline: 5px solid #e500a1;
}
.quote .quote-image {
  width: 100%;
}
.quote .quote-text {
  background: #ffffff;
  padding: 24px 20px;
  margin-bottom: 20px;
  position: relative;
  color: #023670;
  line-height: 31px;
  font-size: 20px;
}
.quote .quote-text .lang-text {
  color: #cc008e;
}
.quote .quote-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  left: 5%;
  top: 100%;
  z-index: 2;
  border-top: 20px solid #ffffff;
}
.quote .quote-author {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.quote .quote-author .author-image {
  width: 130px;
  height: 130px;
  border: 6px solid #72cef7;
  border-radius: 100%;
  flex-shrink: 0;
}
.quote .quote-author .author-name {
  margin-left: 12px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
}
.quote .quote-author .author-name a {
  color: #cc008e;
}
.quote .quote-author .author-name span:first-child {
  font-weight: 500;
}

@media (max-width: 767px) {
  .quote {
    padding: 30px 0;
  }
  .quote .quote-image {
    width: 12vw;
    margin-bottom: 20pt;
  }
  .quote .quote-author {
    margin-left: 0;
  }
  .quote .quote-text {
    font-size: 18px;
  }
}
.benefits {
  background: url(/assets/img/background/bg_quote.webp);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 30px 0;
}
.benefits:focus {
  outline: 5px solid #e500a1;
}
.benefits > *:focus {
  outline: 5px solid #e500a1;
}
.benefits-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.benefits-text {
  color: #ffffff;
}
.benefits-text h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.benefits-text ul li {
  margin-bottom: 10px;
  font-size: 18px;
  position: relative;
  padding-left: 20px;
  line-height: 1.35;
}
.benefits-text ul li::before {
  content: url("/assets/img/elements/bulletpoint_white.webp");
  position: absolute;
  left: 0;
  top: -3px;
}

.career {
  background: #023670 url(/assets/img/background/bg_karriere.webp) center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 95px 0;
  color: #ffffff;
}
.career:focus {
  outline: 5px solid #e500a1;
}
.career > *:focus {
  outline: 5px solid #e500a1;
}
.career h4 {
  font-size: 18px;
  margin-bottom: 15px;
}
.career h3 {
  font-size: 28px;
  margin-bottom: 30px;
}

.header-image {
  min-height: 600px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
}
.header-image .main-img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 1450px) {
  .header-image .main-img {
    min-height: 100%;
    min-width: 100%;
    height: auto;
  }
}
.header-image .container {
  position: relative;
}
.header-image .container .header-image-overlay-img {
  left: 0;
  transform: translate(0);
  max-width: 500px;
  position: absolute;
  height: auto;
  bottom: 50%;
}
@media (max-width: 767px) {
  .header-image .container .header-image-overlay-img {
    top: 0;
    bottom: 18%;
    max-width: 75%;
    left: 8px;
    max-height: 100%;
  }
}
.header-image h1 {
  z-index: 10;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  max-width: 52%;
}
.header-image .box {
  position: relative;
  height: 220px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.header-image .box:after {
  content: "";
  position: absolute;
  width: 300%;
  height: 179.5px;
  background: #023670;
  right: -314.5%;
}
.header-image .triangle {
  width: 115%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 180px;
  background: linear-gradient(to top left, #023670 49.6%, transparent 50%);
  position: absolute;
  overflow: hidden;
}

@media (max-width: 767px) {
  .header-image {
    min-height: 240px;
  }
  .header-image .container {
    width: 100%;
  }
  .header-image .container h1 {
    font-size: 14px;
    max-width: 180px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  .header-image .triangle {
    height: 110px;
    width: 100%;
    left: 0;
    position: absolute;
    overflow: hidden;
    background-image: url("../img/elements/triangle.webp");
    background-position-x: left;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.featured-blog-post {
  padding: 20px 0;
}
.featured-blog-post:focus {
  outline: 5px solid #e500a1;
}
.featured-blog-post > *:focus {
  outline: 5px solid #e500a1;
}
.featured-blog-post .img {
  min-height: 280px;
  outline: 1px solid white;
  outline-offset: -10px;
}
.featured-blog-post .text-box {
  padding: 35px;
  border: 1px solid #ececec;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.featured-blog-post .text-box h3 {
  font-size: 26px;
  color: #023670;
  margin-bottom: 20px;
}
.featured-blog-post .text-box .post-text {
  margin-bottom: 20px;
  color: #555659;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .featured-blog-post .text-box {
    border: none;
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .hidden-desktop {
    display: none;
  }
}
.apply-now-banner {
  background-color: #009DE0;
  padding: 20px 0;
}
.apply-now-banner.layout2menu {
  background: url("../img/background/bg_sub_onlinetest.webp") no-repeat center;
  background-size: cover;
}
@media (min-width: 768px) {
  .apply-now-banner.layout2menu {
    margin-bottom: 20px;
  }
}
.apply-now-banner.layout1main .btn-text {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .apply-now-banner.layout1main .btn-text {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .apply-now-banner.layout1main span {
    margin-right: 10px;
  }
}
.apply-now-banner .btn-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .apply-now-banner .btn-block {
    margin-top: 10px;
    justify-content: center;
  }
}
.apply-now-banner .btn-block.layout2menu {
  margin-top: 10px;
  justify-content: center;
}
.apply-now-banner .btn-block .btn {
  color: #009DE0;
}
.apply-now-banner span {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply-now-banner span.layout2menu {
  font-size: 28px;
  font-weight: 500;
}
.apply-now-banner .sub-hedline {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 5px;
}

.events-outer-wrapper .event {
  background: #ffffff;
  min-width: 272px;
  max-width: 272px;
}
.events-outer-wrapper .event .event-text {
  background: #023670;
  clip-path: polygon(100% 0, 100% 86%, 0 100%, 0 0);
  shape-outside: polygon(100% 0, 100% 86%, 0 100%, 0 0);
  padding: 35px 20px 70px 20px;
  text-align: left;
  color: #ffffff;
  height: 340px;
  position: relative;
  margin-bottom: 10px;
}
.events-outer-wrapper .event .event-text img {
  display: inline-block;
}
.events-outer-wrapper .event .event-text .date-time {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 30px;
  font-weight: bold;
}
.events-outer-wrapper .event .event-text .date-time:before {
  content: url("../img/elements/ico_calendar.webp");
  position: absolute;
  left: 0;
  top: 0;
}
.events-outer-wrapper .event .event-text .event-title {
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 1.1;
}
.events-outer-wrapper .event .event-text .event-title p {
  font-size: 24px;
  line-height: 1.1;
}
.events-outer-wrapper .event .event-text h2 {
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 1.1;
}
.events-outer-wrapper .event .event-text h2 p {
  font-size: 24px;
  line-height: 1.1;
}
.events-outer-wrapper .event .event-text p {
  font-size: 14px;
}
.events-outer-wrapper .event .button-block {
  padding: 0 35px 35px 35px;
  text-align: right;
  background: #ffffff;
}
.events-outer-wrapper .event .button-block:focus {
  outline: 5px solid #e500a1;
}
.events-outer-wrapper .event .button-block > *:focus {
  outline: 5px solid #e500a1;
}
@media (max-width: 1160px) {
  .events-outer-wrapper .events-row {
    flex-direction: column;
    align-items: center;
  }
  .events-outer-wrapper .event-wrapper {
    margin-bottom: 30px;
    width: 100%;
    max-width: 272px;
    padding: 0;
  }
  .events-outer-wrapper .event-wrapper:last-child {
    margin-bottom: 0;
  }
}
.events-outer-wrapper .event-wrapper {
  padding: 5px;
  box-sizing: content-box;
  margin-bottom: 15px;
}
.events-outer-wrapper .events-container {
  background-color: #ececec;
  padding-top: 5px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .events-outer-wrapper .event-wrapper {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.upcoming-events {
  background: #ececec;
  padding: 30px 0;
  padding-bottom: 60px;
}
.upcoming-events .events-outer-wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 900px) {
  .upcoming-events .events-outer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
.upcoming-events .event-wrapper {
  margin-bottom: 0;
}

.event-wrapper {
  position: relative;
}
.event-wrapper .btn-more {
  margin-left: auto;
}
.event-wrapper .more-wrapper {
  display: flex;
  margin-top: 20px;
  position: absolute;
  right: 5px;
}

.career-opportunities {
  padding: 40px 0 15px 0;
}
.career-opportunities .career-image img {
  width: 100%;
}
.career-opportunities .btn-block-right {
  margin-top: 5px;
}
.career-opportunities .btn-mobile {
  margin-top: 0px;
  overflow: hidden;
}
.career-opportunities .career-opportunity {
  text-align: left;
  max-width: 376px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .career-opportunities .career-opportunity {
    padding-bottom: 10px;
  }
}
.career-opportunities .career-opportunity h3 {
  margin-top: 15px;
}
.career-opportunities .career-opportunity h3 * {
  font-size: 24px;
  line-height: 28px;
  color: #023670;
}
.career-opportunities .career-opportunity .career-opportunity-position {
  margin-bottom: 10px;
}
.career-opportunities .career-opportunity .career-opportunity-position p {
  line-height: 18px;
}
.career-opportunities .career-opportunity p {
  color: #555659;
  font-size: 14px;
  line-height: 18px;
}
.career-opportunities .career-opportunity .career-opportunity-image {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.career-opportunities .career-opportunity .collapse {
  margin: 10px 0;
}
.career-opportunities .career-opportunity .collapse .collapsible-btn img {
  width: 15px;
}
.career-opportunities .career-opportunity .collapse h4 {
  display: flex;
  justify-content: space-between;
}
.career-opportunities .career-opportunity .collapse h4 button {
  border: none;
  background: transparent;
  transition: all 300ms;
}
.career-opportunities .career-opportunity .collapse h4 button.active {
  transform: rotate(180deg);
}
.career-opportunities .career-opportunity .collapse .collapse-content {
  display: none;
  color: #555659;
  font-size: 14px;
  margin-top: 10px;
}

.representation-for-students {
  padding: 10px 0 20px 0;
}
.representation-for-students .representation-image {
  height: 280px;
  margin-top: 10px;
}
.representation-for-students h3 p,
.representation-for-students h3 span {
  font-size: 24px;
  line-height: 32px;
  color: #023670;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin-top: 10px;
}
.representation-for-students .representation-text {
  font-size: 14px;
  line-height: 16px;
  color: #555659;
  margin-bottom: 15px;
}
.representation-for-students p {
  color: #555659;
  font-size: 16px;
  line-height: 18px;
}
.representation-for-students p .representation-email {
  color: #cc008e;
}
.representation-for-students p.phone-number, .representation-for-students p.email {
  margin: 5px 0;
}

.contact-form {
  padding: 20px 0;
}
.contact-form .captcha {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .contact-form .captcha {
    justify-content: center;
  }
}
.contact-form #h-email, .contact-form #h-name, .contact-form #h-body {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: -1;
}
.contact-form input:not([type=submit]),
.contact-form textarea {
  width: 100%;
  padding: 8px 14px;
  font-size: 18px;
  border-radius: 8px;
  background: #ececec;
  border: none;
  color: #555659;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 767px) {
  .contact-form input:not([type=submit]),
.contact-form textarea {
    font-size: 14px;
  }
}
.contact-form input[type=submit] {
  line-height: 1;
  width: 100%;
  max-width: 220px;
}
.contact-form .contact-message {
  margin-bottom: 10px;
}
.contact-form .checkbox {
  position: relative;
}
.contact-form .checkbox input {
  opacity: 0;
  position: absolute;
  width: 5px;
}
.contact-form .checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  font-size: 12px;
  line-height: 1.5;
}
.contact-form .checkbox label a {
  text-decoration: underline;
}
.contact-form .checkbox label:after, .contact-form .checkbox label:before {
  position: absolute;
  content: "";
  display: inline-block;
}
.contact-form .checkbox label:before {
  height: 16px;
  width: 16px;
  left: 0px;
  background: #ffffff;
}
.contact-form .checkbox label:after {
  height: 4px;
  width: 8px;
  border-left: 2px solid #009DE0;
  border-bottom: 2px solid #009DE0;
  transform: rotate(-55deg);
  left: 4px;
  top: 4px;
}
.contact-form .checkbox input[type=checkbox] + label::after {
  content: none;
}
.contact-form .checkbox input[type=checkbox]:checked + label::after {
  content: "";
}
.contact-form .checkbox input[type=checkbox]:focus + label::before {
  outline: #e500a1 auto 5px;
}
.contact-form .checkbox label {
  text-align: left;
}
.contact-form .checkbox label p {
  line-height: 18px;
  font-size: 14px;
}
@media (max-width: 767px) {
  .contact-form .checkbox label p {
    font-size: 12px;
  }
}
.contact-form .checkbox label:before {
  background: #ececec;
}
.contact-form .checkbox-contact-input,
.contact-form .name-contact-input,
.contact-form .email-contact-input,
.contact-form .message-contact-input {
  margin-bottom: 18px;
  position: relative;
  text-align: left;
}
.contact-form .checkbox-contact-input input,
.contact-form .name-contact-input input,
.contact-form .email-contact-input input,
.contact-form .message-contact-input input {
  text-align: center;
}
.contact-form .checkbox-contact-input.error input,
.contact-form .name-contact-input.error input,
.contact-form .email-contact-input.error input,
.contact-form .message-contact-input.error input {
  border: 1px solid #a20606;
}
.contact-form .checkbox-contact-input.error .error-message,
.contact-form .name-contact-input.error .error-message,
.contact-form .email-contact-input.error .error-message,
.contact-form .message-contact-input.error .error-message {
  display: block;
}
.contact-form .checkbox-contact-input.error .checkbox label:before,
.contact-form .name-contact-input.error .checkbox label:before,
.contact-form .email-contact-input.error .checkbox label:before,
.contact-form .message-contact-input.error .checkbox label:before {
  border: 1px solid #a20606;
}
.contact-form .checkbox-contact-input .error-message,
.contact-form .name-contact-input .error-message,
.contact-form .email-contact-input .error-message,
.contact-form .message-contact-input .error-message {
  display: none;
  position: absolute;
  bottom: calc(100% + 20px);
  background: #a20606;
  padding: 12px 30px;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  border-radius: 8px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}
.contact-form .checkbox-contact-input .error-message:after,
.contact-form .name-contact-input .error-message:after,
.contact-form .email-contact-input .error-message:after,
.contact-form .message-contact-input .error-message:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  left: 50%;
  top: 100%;
  z-index: 2;
  transform: translateX(-50%);
  border-top: 20px solid #a20606;
}
.contact-form .flash-message {
  display: block;
  margin-top: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: #009DE0;
  color: #ffffff;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.contact-form .flash-message.flash-message-error {
  background-color: #a20606;
}

@keyframes errorOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes errorClose {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.headline-text-image {
  padding: 20px 0;
}
.headline-text-image .container .headline {
  height: 30px;
  color: #023670;
  font-size: 26px;
  letter-spacing: 0.74px;
  line-height: 30px;
  margin-bottom: 20px;
}
.headline-text-image .container img {
  height: 200px;
  width: 100%;
}
.headline-text-image .container .subtext {
  height: 200px;
  color: #555659;
  font-size: 16px;
  line-height: 22px;
  float: left;
}
.headline-text-image .container .maintext {
  color: #555659;
  font-size: 16px;
  line-height: 22px;
}

.headline-image-text {
  padding: 20px 0;
}
.headline-image-text .container .headline {
  height: 30px;
  color: #023670;
  font-size: 26px;
  letter-spacing: 0.74px;
  line-height: 30px;
  margin-bottom: 20px;
}
.headline-image-text .container img {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}
.headline-image-text .container .subtext {
  height: 200px;
  color: #555659;
  font-size: 16px;
  line-height: 22px;
  float: left;
}
.headline-image-text .container .maintext {
  color: #555659;
  font-size: 16px;
  line-height: 22px;
}

.teaser-desktop {
  padding: 10px;
  padding-top: 0;
  padding-bottom: 80px;
  position: relative;
  transform: translateY(-170px);
  z-index: 2;
  margin-bottom: -130px;
}
@media screen and (min-width: 1350px) {
  .teaser-desktop {
    transform: translateY(-190px);
  }
}
@media screen and (min-width: 1550px) {
  .teaser-desktop {
    transform: translateY(-210px);
  }
}
@media screen and (min-width: 1720px) {
  .teaser-desktop {
    transform: translateY(-230px);
  }
}
@media (max-width: 767px) {
  .teaser-desktop {
    transform: translateY(-164px);
  }
}
.teaser-desktop:focus {
  outline: 5px solid #e500a1;
}
.teaser-desktop > *:focus {
  outline: 5px solid #e500a1;
}
.teaser-desktop:after {
  background: #ececec;
  content: " ";
  position: absolute;
  width: 100%;
  height: 78%;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 1350px) {
  .teaser-desktop:after {
    height: 66%;
  }
}
@media screen and (min-width: 1550px) {
  .teaser-desktop:after {
    height: 58%;
  }
}
@media screen and (min-width: 1720px) {
  .teaser-desktop:after {
    height: 54%;
  }
}
.teaser-desktop .teaser-item {
  background-position: center;
  background-size: cover;
  height: 370px;
  text-decoration: none;
  text-align: right;
  z-index: 5;
  outline-offset: -3px;
}
@media screen and (max-width: 845px) {
  .teaser-desktop .teaser-item {
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media screen and (max-width: 330px) {
  .teaser-desktop .teaser-item {
    padding-left: 3px;
    padding-right: 3px;
  }
}
@media screen and (min-width: 520px) {
  .teaser-desktop .teaser-item {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 519px) {
  .teaser-desktop .teaser-item {
    background-position: top;
  }
}
@media (max-width: 767px) {
  .teaser-desktop .teaser-item {
    height: 260px;
  }
}
.teaser-desktop .teaser-item span {
  background: #023670;
  color: #ffffff;
  padding: 8px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.8px;
}
.teaser-desktop .teaser-item span p {
  display: inline;
}
@media screen and (max-width: 877px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: 0.5px;
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media screen and (max-width: 812px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: 0.3px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media screen and (min-width: 766px) and (max-width: 785px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 423px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 406px) {
  .teaser-desktop .teaser-item span {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 13px;
  }
}
@media screen and (max-width: 378px) {
  .teaser-desktop .teaser-item span {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 12.5px;
  }
}
@media screen and (max-width: 362px) {
  .teaser-desktop .teaser-item span {
    font-size: 12px;
  }
}
@media screen and (max-width: 351px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: -0.2px;
    font-size: 11.5px;
  }
}
@media screen and (max-width: 330px) {
  .teaser-desktop .teaser-item span {
    letter-spacing: -0.3px;
    font-size: 11px;
  }
}

.slider {
  padding: 20px 0;
}
.slider .slider-wrapper {
  position: relative;
  height: 500px;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
}
.slider .slider-wrapper.threeImage .slidersImage {
  width: 33.3333333333%;
}
.slider .slidersImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  outline-offset: -5px;
  z-index: -1;
}
.slider .slidersImage:focus {
  outline: 5px solid #e500a1;
}
.slider .slidersImage > *:focus {
  outline: 5px solid #e500a1;
}
.slider .slidersImage.active {
  z-index: 1;
}
.slider .row {
  position: relative;
}
.slider .row .lightbox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  display: none;
  transition: all 0.3s ease-in;
}
.slider .row .lightbox.show {
  transform: translate(-50%, -50%) scale(1);
  display: block;
  opacity: 1;
  max-width: 80%;
  max-height: 90vh;
  z-index: 10;
}
@media (max-width: 767px) {
  .slider .row .lightbox.show {
    max-width: 94%;
  }
}
.slider .row .lightbox .lightbox-focus {
  outline: none;
}
.slider .row .lightbox .lightbox-close {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 3;
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
}
.slider .row .lightbox .lightbox-close span {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.slider .row .lightbox .lightbox-close span::before, .slider .row .lightbox .lightbox-close span::after {
  background: #ffffff;
  content: "";
  top: 12px;
  display: block;
  height: 3px;
  position: absolute;
  width: 100%;
  transform: rotate(45deg);
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
}
.slider .row .lightbox .lightbox-close span::after {
  transform: rotate(-45deg);
}
.slider .row .lightbox img {
  width: 100%;
}
.slider .prv-col,
.slider .nxt-col {
  text-align: center;
  margin: auto;
}
.slider .nxt,
.slider .prv {
  max-width: 50px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s;
  transform: rotate(-90deg);
}
.slider .prv {
  transform: rotate(90deg);
}
.slider .prv:hover,
.slider .nxt:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .slider .slider-wrapper {
    height: 280px;
  }
  .slider .slider-wrapper.threeImage .slidersImage {
    width: 50%;
  }
  .slider .nxt,
.slider .prv {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .slider .slider-wrapper {
    height: 210px;
  }
}
.body-text {
  padding: 20px 0;
}

.search-results {
  padding: 20px 0;
}
.search-results ul .row {
  position: relative;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .search-results ul .row {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.search-results ul .row:last-child {
  margin-bottom: 15px;
}
.search-results ul .row:after {
  content: "";
  background: #ececec;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -10px;
}
.search-results .search-results-item {
  text-align: left;
}
.search-results .search-results-item .breadcrumbs {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .search-results .search-results-item .breadcrumbs.container {
    padding-left: 0;
    padding-right: 0;
  }
}
.search-results .search-results-item .entry-name {
  color: #cc008e;
  font-size: 18px;
  text-decoration: none;
  position: relative;
}
.search-results .search-results-item .entry-name:after {
  content: url("../img/elements/pfeil.webp");
  position: absolute;
  right: -15px;
  width: 6px;
}
.search-results .page-numbers a, .search-results .page-numbers span {
  text-decoration: none;
  color: #c0ddfe;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
}
.search-results .page-numbers a img, .search-results .page-numbers span img {
  width: 8px;
}
.search-results .page-numbers .current {
  color: #022a57;
}

@media (max-width: 767px) {
  .search-results .underline-headline {
    font-weight: 300;
    text-align: left;
    font-size: 24px;
    margin-bottom: 25px;
    left: unset;
    transform: none;
  }
  .search-results .underline-headline:after {
    content: none;
  }
}
.company-thumbnail-slider {
  padding: 10px;
  overflow: hidden;
}
.company-thumbnail-slider .row {
  position: relative;
}
@media (max-width: 1300px) {
  .company-thumbnail-slider .row {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .company-thumbnail-slider .row {
    width: 92%;
  }
}
.company-thumbnail-slider .layout2main {
  width: 92%;
  margin: 0 auto;
}
.company-thumbnail-slider .layout2menu {
  width: 86%;
  margin: 0 auto;
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper {
  overflow: hidden;
  padding: 2px;
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .img.prev {
  left: -30px;
}
@media (max-width: 1020px) {
  .company-thumbnail-slider .company-thumbnail-slider-wrapper .img.prev {
    left: -20px;
  }
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .img.next {
  right: -30px;
}
@media (max-width: 1020px) {
  .company-thumbnail-slider .company-thumbnail-slider-wrapper .img.next {
    right: -20px;
  }
}
@media (max-width: 1020px) {
  .company-thumbnail-slider .company-thumbnail-slider-wrapper {
    padding: 0 2px;
  }
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .company-thumbnail-slider-box {
  display: flex;
  transition: all 1s;
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .company-thumbnail-slider-box .company-thumbnail-slider-item {
  padding: 5px;
  display: inline-block;
  width: 130px;
  height: 130px;
  border: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: -5px;
}
@media (max-width: 767px) {
  .company-thumbnail-slider .company-thumbnail-slider-wrapper .company-thumbnail-slider-box .company-thumbnail-slider-item {
    width: 95px;
    height: 95px;
  }
}
.company-thumbnail-slider .company-thumbnail-slider-wrapper .company-thumbnail-slider-box .company-thumbnail-slider-item .company-logo {
  width: 100%;
  height: 100%;
}

.body-links {
  overflow: hidden;
}
.body-links .container .content-box .headLine {
  height: 32px;
  color: #023670;
  font-size: 26px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 15px;
}
.body-links .container .content-box .linkText {
  height: 41px;
  color: #00a0e5;
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
  background-image: url(/assets/img/elements/ico_nxt_gray.webp);
  background-position: center right;
  background-repeat: no-repeat;
  border-bottom: 1px solid #ececec;
}
.body-links .container .content-box .linkText .SubText {
  display: inline-block;
  color: #00a0e5;
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
}
.body-links .container .content-box .linkText .SubText:hover {
  text-decoration: underline;
}
.body-links .container .content-box .subOutside {
  padding: 5px;
  border: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.body-links .container .content-box .subOutside .subRow {
  width: 100%;
  border: 1px solid #ececec;
  background-color: #ececec;
  margin: 0;
}
.body-links .container .content-box .subOutside .subRow .plainText {
  color: #00a0e5;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: auto;
  padding: 25px;
}
.body-links .container .content-box .subOutside .subRow .subLineBox {
  padding: 25px;
  background: #009DE0 url(/assets/img/background/bg-sub.bewerben.webp) no-repeat center;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
}
.body-links .container .content-box .subOutside .subRow .subLineBox .subLine {
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin: 0;
}
.body-links .container .content-box .buttonBox {
  height: 162px;
  background-color: #009DE0;
  margin-bottom: 20px;
}
.body-links .container .content-box .buttonBox h2 {
  color: #ffffff;
  font-family: Roboto;
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
  padding-top: 30px;
}
.body-links .container .content-box .buttonBox h4 {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
}
.body-links .container .content-box .buttonBox .bt-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.body-links .container .content-box .buttonBox .bt-div .Linkbutton {
  cursor: pointer;
  border: 0.8px solid #ffffff;
  border-radius: 15px;
  background-color: #ffffff;
  text-align: center;
  position: center;
}
.body-links .container .content-box .buttonBox .bt-div .Linkbutton .buttonCaption {
  color: #00a0e5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: center;
}
.body-links .container .content-box .buttonBox .bt-div .Linkbutton:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .body-links .container .content-box {
    background-color: #009DE0;
  }
  .body-links .container .content-box .subOutside {
    display: none;
  }
  .body-links .container .content-box .buttonBox {
    display: none;
  }
  .body-links .container .content-box .headLine {
    color: #ffffff;
    margin: 0 20px;
  }
  .body-links .container .content-box .linkText {
    color: #ffffff;
    margin: 0 20px;
  }
  .body-links .container .content-box .linkText .SubText {
    color: #ffffff;
  }
}
.contact-image-right {
  padding: 20px 0;
}
@media (max-width: 767px) {
  .contact-image-right .container {
    padding: 0;
  }
}
.contact-image-right .contact-image-right-contact {
  background: #009DE0 url("../img/background/bg_sub_ansprechpartner.webp") center;
  background-size: cover;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #ffffff;
  text-align: center;
}
.contact-image-right .contact-image-right-contact p {
  line-height: 22px;
}
.contact-image-right .contact-image-right-contact h3 {
  font-size: 26px;
  line-height: 34px;
}
.contact-image-right .contact-image-right-contact ul {
  line-height: 22px;
  font-size: 16px;
  margin: 30px 0 20px 0;
}
.contact-image-right .contact-image-right-contact ul li:first-child {
  font-weight: 400;
}
.contact-image-right .contact-image-right-contact a {
  line-height: 22px;
  color: #ffffff;
}
.contact-image-right .contact-image-right-image {
  min-height: 286px;
  background-size: cover;
  border-left: 20px solid #ffffff;
}
@media (max-width: 767px) {
  .contact-image-right .contact-image-right-image {
    border: none;
  }
}

.contact-image-left {
  padding: 20px 0;
}
@media (max-width: 767px) {
  .contact-image-left .container {
    padding: 0;
  }
}
.contact-image-left .contact-image-left-contact {
  background: #009DE0 url("../img/background/bg_sub_ansprechpartner.webp") center;
  background-size: cover;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #ffffff;
  text-align: center;
}
.contact-image-left .contact-image-left-contact p {
  line-height: 22px;
}
.contact-image-left .contact-image-left-contact h3 {
  font-size: 26px;
  line-height: 34px;
}
.contact-image-left .contact-image-left-contact ul {
  line-height: 22px;
  font-size: 16px;
  margin: 30px 0 20px 0;
}
.contact-image-left .contact-image-left-contact ul li:first-child {
  font-weight: 400;
}
.contact-image-left .contact-image-left-contact a {
  line-height: 22px;
  color: #ffffff;
}
.contact-image-left .contact-image-left-image {
  min-height: 286px;
  background-size: cover;
  border-right: 20px solid #ffffff;
}
@media (max-width: 767px) {
  .contact-image-left .contact-image-left-image {
    border: none;
  }
}

.contact-image-left-right {
  padding: 20px 0;
}
@media (max-width: 767px) {
  .contact-image-left-right .container {
    padding: 0;
  }
}
.contact-image-left-right .contact-image-left-right-contact {
  background: #009DE0 url("../img/background/bg_sub_ansprechpartner.webp") center;
  background-size: cover;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #ffffff;
  text-align: center;
}
@media (min-width: 768px) {
  .contact-image-left-right .contact-image-left-right-contact {
    border-left: 10px solid #ffffff;
    border-right: 10px solid #ffffff;
  }
}
.contact-image-left-right .contact-image-left-right-contact p {
  line-height: 22px;
}
.contact-image-left-right .contact-image-left-right-contact h3 {
  font-size: 26px;
  line-height: 34px;
}
.contact-image-left-right .contact-image-left-right-contact ul {
  line-height: 22px;
  font-size: 16px;
  margin: 30px 0 20px 0;
}
.contact-image-left-right .contact-image-left-right-contact ul li:first-child {
  font-weight: 400;
}
.contact-image-left-right .contact-image-left-right-contact a {
  line-height: 22px;
  color: #ffffff;
}
.contact-image-left-right .contact-image-left-right-image1,
.contact-image-left-right .contact-image-left-right-image2 {
  min-height: 280px;
}
@media (max-width: 767px) {
  .contact-image-left-right .contact-image-left-right-image1,
.contact-image-left-right .contact-image-left-right-image2 {
    border: none;
  }
}

@media (min-width: 768px) {
  .contact-image-left-right-image1 {
    border-right: 10px solid #ffffff;
  }
  .contact-image-left-right-image2 {
    border-left: 10px solid #ffffff;
  }
}
.job-listings {
  background: url(/assets/img/background/bg_quote.webp);
  padding: 50px;
}
.job-listings h3 {
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  letter-spacing: 0.8px;
  font-weight: 400;
}
.job-listings input {
  border-radius: 8px;
  padding: 8px;
  border: #ffffff;
  width: 100%;
  border: none;
  height: 36px;
  font-size: 14px;
  margin: 25px 0 10px 0;
}
.job-listings a {
  color: #ffffff;
}
.job-listings-button {
  text-align: center;
  margin-top: 15px;
}
.job-listings-button a {
  color: #022a57;
  padding-left: 40px;
  padding-right: 40px;
}

.search-results {
  padding: 20px 0;
}
.search-results ul .row {
  position: relative;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .search-results ul .row {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.search-results ul .row:last-child {
  margin-bottom: 15px;
}
.search-results ul .row:after {
  content: "";
  background: #ececec;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -10px;
}
.search-results .search-results-item {
  text-align: left;
}
.search-results .search-results-item .breadcrumbs {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .search-results .search-results-item .breadcrumbs.container {
    padding-left: 0;
    padding-right: 0;
  }
}
.search-results .search-results-item .entry-name {
  color: #cc008e;
  font-size: 18px;
  text-decoration: none;
  position: relative;
}
.search-results .search-results-item .entry-name:after {
  content: url("../img/elements/pfeil.webp");
  position: absolute;
  right: -15px;
  width: 6px;
}
.search-results .page-numbers a, .search-results .page-numbers span {
  text-decoration: none;
  color: #c0ddfe;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
}
.search-results .page-numbers a img, .search-results .page-numbers span img {
  width: 8px;
}
.search-results .page-numbers .current {
  color: #022a57;
}

@media (max-width: 767px) {
  .search-results .underline-headline {
    font-weight: 300;
    text-align: left;
    font-size: 24px;
    margin-bottom: 25px;
    left: unset;
    transform: none;
  }
  .search-results .underline-headline:after {
    content: none;
  }
}
.infobox {
  background-color: #009DE0;
}
@media (min-width: 768px) {
  .infobox.layout2menu {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
  }
}
.infobox .container .headLine {
  width: 100%;
  color: #ffffff;
  font-size: 26px;
  line-height: 1.15;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infobox .container .info {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  padding-top: 10px;
}
.infobox .container .info:last-child {
  border: none;
}
.infobox .container .info h4 {
  font-size: 14px;
  font-weight: 500;
}
.infobox .container .info .rich-text {
  color: #ffffff;
}
.infobox .container .info .rich-text ul {
  list-style-image: url("/assets/img/elements/bulletpoint_white.webp");
}
.infobox .container .info .rich-text a {
  color: #ffffff;
}
@media (max-width: 767px) {
  .infobox.collapse .collapse-content {
    display: none;
  }
}
.infobox.collapse .collapsible-btn {
  border: none;
  background: transparent;
  transform-origin: center 35%;
  transition: transform 0.6s;
}
.infobox.collapse .collapsible-btn.active {
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  .infobox {
    display: none;
  }
}
.infobox-mobile {
  display: none;
}
@media (max-width: 767px) {
  .infobox-mobile {
    display: block;
    margin-top: 0;
  }
}

.body-download-links {
  padding: 10px 0;
}
.body-download-links .container .headLine {
  padding-bottom: 5px;
  padding-top: 5px;
  color: #023670;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body-download-links .container .headLine .collapsible-button img {
  width: 18px;
}
.body-download-links .container .name-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0.5rem 10px 1.5rem;
}
.body-download-links .container .name-link:after {
  content: "";
  width: 100%;
  background: #ececec;
  height: 1px;
  margin-top: 10px;
}
.body-download-links .container .name-link .fileName {
  color: #555659;
  font-size: 16px;
}
.body-download-links .container .name-link .a-url .iconDownload {
  width: 100%;
  background-image: url(/assets/img/elements/ico_download@3x.webp);
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 16.31px;
  box-sizing: border-box;
  height: 6.62px;
  width: 16.31px;
  background-size: 100%;
}
.body-download-links .container .name-link .a-url .fileExtension {
  color: #cc008e;
  font-size: 14px;
  display: inline-block;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: left;
  padding-left: 2px;
}
.body-download-links .container .collapsible-btn {
  border: none;
  background: transparent;
  transform-origin: center 35%;
  transition: transform 0.6s;
}
.body-download-links .container .collapsible-btn.active {
  transform: rotate(180deg);
}
.body-download-links .container .collapsed {
  display: none;
}

@media (max-width: 767px) {
  .body-download-links .container .fileName {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  .body-download-links .container .collapse-content {
    display: none;
  }
}
.text {
  padding: 30px 0;
}
.text h3 {
  text-align: left;
  color: #023670;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 400;
}
.text p {
  color: #555659;
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
}

.headline-underlined {
  padding-top: 40px;
}

.full-width-text {
  color: #555659;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding-bottom: 50px;
}

.content-image-big .container .mainImage {
  height: 507px;
  width: 780px;
}
.content-image-big .container .mainImage img {
  width: 100%;
  height: 100%;
}
.content-image-big .container .imageText {
  padding-left: 10px;
  height: 100%;
}
.content-image-big .container .imageText .headLine {
  height: 100%;
  width: 100%;
  color: #023670;
  font-family: Roboto;
  font-size: 26px;
  letter-spacing: 0.74px;
  line-height: 30px;
}
.content-image-big .container .imageText .richText {
  height: 100%;
  width: 100%;
  color: #555659;
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .content-image-big .container {
    padding: 0px;
  }
  .content-image-big .container .imageText .headLine {
    padding: 25px 25px 0px 25px;
  }
  .content-image-big .container .imageText .richText {
    padding: 25px;
  }
}
.content-image-small .container .mainImage {
  height: 252px;
  width: 380px;
}
.content-image-small .container .mainImage img {
  width: 100%;
  height: 100%;
}
.content-image-small .container .imageText {
  padding-left: 10px;
  height: 100%;
}
.content-image-small .container .imageText .headLine {
  height: 100%;
  width: 100%;
  color: #023670;
  font-family: Roboto;
  font-size: 26px;
  letter-spacing: 0.74px;
  line-height: 30px;
}
.content-image-small .container .imageText .richText {
  height: 100%;
  width: 100%;
  color: #555659;
  font-family: Roboto;
  font-size: 16px;
  line-height: 22px;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .content-image-small .container {
    padding: 0px;
  }
  .content-image-small .container .imageText .headLine {
    padding: 25px 25px 0px 25px;
  }
  .content-image-small .container .imageText .richText {
    padding: 25px;
  }
}
/**
For your own safety, please never touch this code. You WILL end up like Max.
**/
.uc-banner-content {
  background-color: #24b0fc !important;
}
.uc-banner-content.darker-bg {
  background: #007db3 !important;
}
.uc-banner-content.darker-bg #uc-btn-accept-banner {
  color: #007db3 !important;
}
.uc-banner-content.darker-bg #uc-btn-more-info-banner {
  background: #007db3 !important;
  border: 1px solid #ffffff;
}

.uc-optin-description {
  font-family: "Nova-Regular", sans-serif;
  font-size: 95%;
  line-height: 148%;
  margin: 0 auto;
}

.btn-list uc-col-lg-12 uc-col-md-12 uc-col-sm-12 uc-col-12 uc-overflow-hidden show-deny-btn show-more-btn {
  width: 62%;
}

.uc-powered-by-footer {
  font-size: 70%;
  width: 60.5%;
}

#uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny {
  font-family: "Nova-Regular", sans-serif;
  font-weight: bold;
  background-color: transparent;
  color: white;
  padding: 0.8%;
  text-align: center;
  text-decoration: none;
  border-radius: 20px !important;
  border: solid white 1px;
  width: 15% !important;
  box-shadow: none !important;
}

#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny,
#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-more-info-banner.uc-btn.uc-btn-default.more-info-button {
  width: 15% !important;
}

#uc-btn-accept-banner {
  font-family: "Nova-Regular", sans-serif;
  font-weight: bold;
  background-color: white;
  border: none;
  color: dodgerblue;
  padding: 0.8%;
  text-align: center;
  text-decoration: none;
  border-radius: 20px !important;
  width: 15% !important;
  box-shadow: none !important;
}

#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner.uc-btn.uc-btn-primary {
  width: 65% !important;
}

#uc-btn-more-info-banner {
  font-family: "Nova-Regular", sans-serif;
  font-weight: bold;
  background-color: #1f9ee2;
  border: none;
  color: white;
  padding: 0.8%;
  text-align: center;
  text-decoration: none;
  border-radius: 20px !important;
  width: 15% !important;
  box-shadow: none !important;
}

.uc-banner-content > div > .uc-powered-by-footer {
  width: auto !important;
  margin: 0 auto !important;
  text-align: right !important;
}

.uc-banner-content > div > .uc-powered-by-footer span {
  color: #ffffff !important;
}

#uc-main-banner .uc-banner-content > div {
  width: 70% !important;
  margin: 0 auto !important;
  float: none !important;
}

.uc-banner-content div.btn-list.uc-overflow-hidden {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

@media screen and (max-width: 640px) {
  #usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny,
#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-more-info-banner.uc-btn.uc-btn-default.more-info-button,
#usercentrics-button.layout-1 #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner.uc-btn.uc-btn-primary {
    width: 100% !important;
  }
  .uc-banner-content div.btn-list.uc-overflow-hidden {
    flex-direction: column !important;
  }
  #uc-btn-deny-banner.uc-btn.uc-btn-default.btn-deny {
    border: transparent !important;
  }
  #uc-main-banner .uc-banner-content > div {
    width: 95% !important;
  }
  .uc-optin-description {
    display: inline-block;
    height: 112px !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .uc-banner-content ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background-color: rgba(69, 158, 226, 0.9);
  }
}
.bullet-points {
  padding: 10px 0;
}
.bullet-points .iconText {
  background-image: url("../img/elements/ico_bulletpoint.webp");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 5px;
}
.bullet-points .bulletText {
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 5px;
}
.bullet-points .larger-blue {
  color: #023670;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 40px;
  text-decoration: none;
  padding: 5px 0;
}
.bullet-points .label-row2 {
  padding-bottom: 20px;
}
.bullet-points .small-grey {
  color: #555659;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  padding: 5px 0;
}
.bullet-points .bg-style {
  height: 100%;
  width: 100%;
  padding: 10px 20px;
}
.bullet-points .bg-style-grey {
  background-color: #ececec;
}
.bullet-points .bg-style-white-greyborder {
  border: 1px solid #ececec;
}
.bullet-points .bullet-points-list {
  text-align: left;
}
.bullet-points .bullet-points-list.bigger-text li {
  font-size: 24px;
  color: #023670;
}
.bullet-points .layout2menu .col-xs-12 {
  padding: 0;
}
.bullet-points .layout2menu .bigger-text li {
  font-size: 16px;
  color: #555659;
}

.alumni-benefits .container {
  height: 100%;
  width: 100%;
}
.alumni-benefits .container .row {
  grid-column-gap: 10px;
}
.alumni-benefits .container .row .headLine {
  height: 100%;
  width: 100%;
  color: #023670;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 28px;
  display: inline-block;
}
.alumni-benefits .container .row .richText {
  height: 64.39px;
  width: 100%;
  color: #555659;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 16px;
}
.alumni-benefits .container .row .Column {
  height: 100%;
  width: 100%;
}
.alumni-benefits .container .row .Column .imageBox {
  height: 180px;
  width: 100%;
  border: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alumni-benefits .container .row .Column .textlink {
  height: 36px;
  width: 100%;
  line-height: 16px;
  text-align: right;
}
.alumni-benefits .container .row .Column .textlink a:link {
  color: #cc008e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}
.alumni-benefits .container .row .Column .textlink a {
  color: #cc008e;
}
.alumni-benefits .container .row .Column .textlink a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .alumni-benefits .container .pagetitle, .alumni-benefits .container hr {
    display: none;
  }
  .alumni-benefits .container .row .Column2, .alumni-benefits .container .row .Column3 {
    display: none;
  }
  .alumni-benefits .container .row .Column1 .headLine {
    margin: 20px 0px;
  }
}
.events-overview {
  background-color: #ececec;
}

.past-events [class*=col-] {
  margin-bottom: 20px;
}
.past-events [class*=col-] .cardbox {
  border: 1px solid #ececec;
  height: 100%;
}
.past-events [class*=col-] .cardbox img {
  width: 100%;
  height: 236.25px;
}
.past-events [class*=col-] .cardbox .blogtext {
  color: #023670;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 10px 10px 10px;
}
.past-events [class*=col-] .cardbox .datetime {
  color: grey;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 10px 0px 10px;
}
.past-events [class*=col-] .cardbox .bottom {
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 30px;
}
.past-events [class*=col-] .cardbox .bottom .btn-white {
  border: 0.8px solid #023670;
}

.recent-blog-post {
  padding-top: 40px;
  padding-bottom: 40px;
}

.video-template {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  max-width: 376px;
  margin-left: auto;
  margin-right: auto;
}
.video-template .video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.video-template .video-image {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}
.video-template .video-play {
  position: absolute;
  z-index: 3;
  width: 100px;
  margin-left: -50px;
  margin-top: -50px;
  top: 50%;
  left: 50%;
}
.video-template .video-play:focus {
  outline: 5px solid #e500a1;
}
.video-template .video-play > *:focus {
  outline: 5px solid #e500a1;
}
.video-template[data-playing=true] .video-frame {
  z-index: 3;
}
.video-template[data-playing=true] .video-play {
  display: none;
}
.video-template[data-playing=false]:hover {
  cursor: pointer;
}
.video-template[data-playing=false]:hover .video-play {
  opacity: 0.8;
}

.compass-navigation-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .compass-navigation-wrapper {
    display: flex;
  }
}

section.compass-navigation {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 999;
  display: none;
  max-height: 900px;
  overflow: hidden;
}
@media (max-width: 767px) {
  section.compass-navigation {
    display: block;
  }
}
section.compass-navigation .mobile-button {
  position: absolute;
  height: 50px;
  right: 0;
  top: 70px;
  z-index: 9;
}
section.compass-navigation .top-strip {
  position: absolute;
  background-color: #023670;
  width: 114%;
  height: 128px;
  transform: rotateZ(-10deg) translate3d(-34px, -75px, 0);
}
@media (max-height: 460px) {
  section.compass-navigation .top-strip {
    height: 320px;
  }
}
@media (max-width: 460px) {
  section.compass-navigation .top-strip {
    width: 130%;
  }
}
section.compass-navigation .logo-strip {
  position: absolute;
  right: 45px;
  bottom: 63vw;
  z-index: 20;
}
@media (min-width: 319px) {
  section.compass-navigation .logo-strip {
    bottom: 70vw;
  }
}
@media (min-width: 400px) {
  section.compass-navigation .logo-strip {
    bottom: 58vw;
    right: 50px;
  }
}
@media (min-width: 500px) {
  section.compass-navigation .logo-strip {
    bottom: 52vw;
    right: 60px;
  }
}
@media (min-width: 640px) {
  section.compass-navigation .logo-strip {
    bottom: 47vw;
  }
}
section.compass-navigation .logo-strip img {
  width: 158px;
  height: auto;
}
@media (max-height: 460px) {
  section.compass-navigation .logo-strip img {
    display: none;
  }
  section.compass-navigation .logo-strip .white-logo {
    display: block;
    z-index: 10;
  }
}
section.compass-navigation .bottom-strip {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ffffff;
  text-align: right;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  padding-bottom: 138px;
}
@media (max-width: 321px) {
  section.compass-navigation .bottom-strip {
    padding-bottom: 32vh;
  }
}
section.compass-navigation .bottom-strip:after {
  position: absolute;
  width: 100%;
  height: 138px;
  background-color: #023670;
  content: " ";
  left: 0;
  bottom: 0;
}
@media (max-width: 321px) {
  section.compass-navigation .bottom-strip:after {
    height: 32vh;
  }
}
section.compass-navigation .bottom-strip img.bottom-strip-text {
  width: 180px;
  margin-top: 13px;
  position: absolute;
  top: 45px;
  right: 30px;
  z-index: 1;
}
@media (max-height: 460px) {
  section.compass-navigation .bottom-strip img.bottom-strip-text {
    right: 80px;
    top: 110px;
  }
}
section.compass-navigation .bottom-strip img.bottom-strip-facets {
  width: 100%;
  display: block;
  height: auto;
}
section.compass-navigation .compass-direction {
  position: absolute;
  z-index: 999;
  background-repeat: no-repeat;
  background-size: cover;
}
section.compass-navigation .compass-direction .compass-header > div {
  position: absolute;
  background-color: rgba(2, 42, 87, 0.35);
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 35px;
  line-height: 1.2;
}
section.compass-navigation .compass-direction .compass-header > div h2 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 24px;
}
section.compass-navigation .compass-direction .navigation-info {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
}
section.compass-navigation .compass-direction .navigation-info span {
  opacity: 0;
}
section.compass-navigation .compass-direction .navigation-info img {
  opacity: 0;
  padding-top: 10px;
  width: 20px;
  transform: rotate(180deg);
}
section.compass-navigation .compass-direction .navigation-info img.switch {
  transform: rotate(0);
}
section.compass-navigation .compass-direction.dir-bottom .navigation-info {
  top: 10px;
  bottom: auto;
  flex-direction: column-reverse;
}
section.compass-navigation .compass-direction.dir-bottom .navigation-info img {
  transform: rotate(0deg);
}
section.compass-navigation .compass-direction.dir-bottom .navigation-info img.switch {
  transform: rotate(180deg);
}
section.compass-navigation .compass-direction.dir-right .navigation-info {
  transform: rotate(-90deg);
  justify-content: center;
  width: 200px;
  top: 0;
  pointer-events: none;
  left: initial;
  right: 64px;
  flex-direction: column-reverse;
}
section.compass-navigation .compass-direction.dir-right .navigation-info img {
  transform: rotate(0deg);
  padding-bottom: 10px;
  padding-top: 0;
}
section.compass-navigation .compass-direction.dir-right .navigation-info img.switch {
  transform: rotate(180deg);
  padding-bottom: 0;
  padding-top: 10px;
}
section.compass-navigation .compass-direction.dir-left .navigation-info {
  transform: rotate(270deg);
  justify-content: center;
  width: 200px;
  top: 0;
  pointer-events: none;
  left: 64px;
  flex-direction: column;
}
section.compass-navigation .compass-direction.dir-left .navigation-info img {
  transform: rotate(180deg);
  padding-top: 0;
  padding-bottom: 10px;
}
section.compass-navigation .compass-direction.dir-left .navigation-info img.switch {
  transform: rotate(0deg);
  padding-top: 10px;
  padding-bottom: 0;
}
section.compass-navigation .compass-nav-helper {
  position: absolute;
  z-index: 10;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.3;
  min-height: 39px;
}
section.compass-navigation .compass-nav-helper a {
  color: #ffffff;
  text-decoration: none;
}
section.compass-navigation .compass-nav-helper a:focus {
  outline: 2px dashed #ffffff;
}
section.compass-navigation .compass-nav-helper-top {
  left: 110px;
  top: 35px;
  max-width: 240px;
  min-height: 0;
}
section.compass-navigation .compass-nav-helper-top:before {
  position: absolute;
  left: -31px;
  width: 1px;
  height: 49px;
  background-color: #ffffff;
  content: " ";
  top: -35px;
}
section.compass-navigation .compass-nav-helper-top img {
  position: absolute;
  top: 3px;
  left: -23px;
  width: 14px;
  height: auto;
}
section.compass-navigation .compass-nav-helper-right {
  right: 40px;
  bottom: 56px;
  max-width: 130px;
}
section.compass-navigation .compass-nav-helper-right:before {
  position: absolute;
  right: -40px;
  width: 28px;
  height: 1px;
  background-color: #ffffff;
  content: " ";
  top: -2px;
}
section.compass-navigation .compass-nav-helper-right img {
  position: absolute;
  top: 7px;
  right: -23px;
  width: 14px;
  height: auto;
  transform: rotate(90deg);
}
section.compass-navigation .compass-nav-helper-bottom {
  left: 110px;
  bottom: 10px;
  max-width: 240px;
  min-height: 0;
}
section.compass-navigation .compass-nav-helper-bottom:before {
  position: absolute;
  left: -31px;
  width: 1px;
  height: 28px;
  background-color: #ffffff;
  content: " ";
  top: 2px;
}
section.compass-navigation .compass-nav-helper-bottom img {
  position: absolute;
  top: 3px;
  left: -23px;
  width: 14px;
  height: auto;
  transform: rotate(180deg);
}
section.compass-navigation .compass-nav-helper-left {
  left: 40px;
  bottom: 56px;
  max-width: 102px;
}
section.compass-navigation .compass-nav-helper-left:before {
  position: absolute;
  left: -40px;
  width: 28px;
  height: 1px;
  background-color: #ffffff;
  content: " ";
  top: -2px;
}
section.compass-navigation .compass-nav-helper-left img {
  position: absolute;
  top: 7px;
  left: -23px;
  width: 14px;
  height: auto;
  transform: rotate(-90deg);
}
section.compass-navigation .dir-left,
section.compass-navigation .dir-right {
  height: 100%;
  width: 200px;
}
section.compass-navigation .dir-top,
section.compass-navigation .dir-bottom {
  height: 200px;
  width: 100%;
}
section.compass-navigation .dir-left {
  left: 0;
  top: 0;
  transform: translate3d(-200px, 0, 0);
}
section.compass-navigation .dir-left h2 {
  transform: rotate(270deg);
}
section.compass-navigation .dir-right {
  right: 0;
  top: 0;
  transform: translate3d(200px, 0, 0);
}
section.compass-navigation .dir-right h2 {
  transform: rotate(270deg);
}
section.compass-navigation .dir-top {
  top: 0;
  left: 0;
  transform: translate3d(0, -200px, 0);
}
section.compass-navigation .dir-bottom {
  bottom: 0;
  left: 0;
  transform: translate3d(0, 200px, 0);
}

.time-table {
  padding-top: 40px;
}
.time-table .container {
  padding: 0;
}
.time-table .row {
  text-align: left;
}
.time-table .row .events-times {
  padding-bottom: 20px;
  display: flex;
}
@media (max-width: 767px) {
  .time-table .row .events-times {
    flex-direction: column;
  }
}
.time-table .row .events-times .timeRange {
  color: #555659;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: justify;
  margin-right: 5vw;
}
.time-table .row .events-times .timeText {
  color: #555659;
  font-size: 16px;
  line-height: 24px;
}
.time-table .row .post-title {
  color: #023670;
  font-size: 26px;
  letter-spacing: 0.23px;
  line-height: 33px;
  padding-bottom: 5px;
}
.time-table .row .post-date {
  color: #023670;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 10px;
  font-weight: 500;
}
.time-table .row .blog-block-text {
  color: #555659;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
}

.news-recents {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.news-recents [class*=col-] {
  margin-bottom: 20px;
}
.news-recents .cardbox {
  border: 1px solid #ececec;
  height: 380px;
  width: 280px;
  justify-content: space-between;
  flex-direction: column;
  display: inline-flex;
  margin-right: 6px;
  margin-left: 6px;
  margin-bottom: 20px;
  text-align: left;
}
.news-recents .cardbox img {
  width: 100%;
  height: auto;
}
.news-recents .cardbox .blogtext {
  color: #023670;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 10px 10px 10px;
}
.news-recents .cardbox .datetime {
  color: grey;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 10px 0px 10px;
}
.news-recents .cardbox .bottom {
  display: inline-block;
  padding-left: 10px;
  padding-bottom: 30px;
}
.news-recents .cardbox .bottom .btn-white {
  border: 0.8px solid #023670;
}
@media (max-width: 767px) {
  .news-recents .cardbox {
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
  }
}
.news-recents .more-wrapper {
  display: flex;
  flex-basis: 100%;
}
.news-recents .more-wrapper a.btn-more {
  position: relative;
  margin-left: auto;
  padding-right: 10px;
  margin-right: 10px;
}

.news-details {
  max-width: 783px;
  margin: 0 auto;
}
.news-details ul.breadcrumb {
  padding: 10px 10%;
  list-style: none;
}
.news-details ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}
.news-details ul.breadcrumb li + li:before {
  padding: 8px;
  color: #023670;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
  content: ">> ";
}
.news-details ul.breadcrumb li a {
  color: #023670;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
  text-decoration: none;
}
.news-details ul.breadcrumb li a:hover {
  color: #023670;
  text-decoration: underline;
}
.news-details .newstitle {
  width: 100%;
  color: #023670;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.23px;
  line-height: 33px;
  padding-top: 30px;
}
.news-details .blog-block-text {
  width: 100%;
  color: #555659;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.news-details .blog-block-text img {
  max-width: 100%;
}
.news-details .interestpart {
  padding: 30px 0 0 0;
}
.news-details .recentsevents {
  padding: 30px 0 0 0;
}

.headline-styles {
  padding: 20px 0 10px 0;
}
.headline-styles .headline2, .headline-styles .headline3 {
  color: #023670;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0.8px;
}
@media (max-width: 440px) {
  .headline-styles .headline2, .headline-styles .headline3 {
    font-size: 20px;
  }
}
.headline-styles .headline3 {
  font-size: 18px;
  font-weight: 500;
}

.programs {
  padding: 20px 0 40px 0;
  overflow: hidden;
}
.programs .row {
  position: relative;
}
.programs .row:after {
  position: absolute;
  width: 100%;
  background: #ececec;
  content: "";
  bottom: -20px;
  width: calc(100% - 1rem);
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
}
.programs .programs-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .programs .programs-item:nth-child(n+4) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .programs .programs-item {
    margin-bottom: 10px;
  }
}
.programs .programs-item .programs-image {
  text-decoration: none;
}
.programs h3 {
  color: #023670;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 400;
  text-align: left;
}
@media (max-width: 767px) {
  .programs h3 {
    margin-bottom: 10px;
  }
}
.programs .programs-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left;
}
.programs .programs-text.no-margin {
  margin-bottom: 0;
}
.programs .programs-item-img {
  height: 200px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .programs .programs-item-img {
    width: calc(100% + 20pt + 1rem);
    margin-left: calc(-10pt - 0.5rem);
  }
}
.programs .programs-item-img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 100%;
  width: auto;
}
.programs .btn-block {
  text-align: right;
}
.programs .btn-block.hide {
  opacity: 0;
}
.programs .btn-block.hide-all {
  display: none;
}
@media (max-width: 767px) {
  .programs .btn-block.hide-mobile {
    display: none;
  }
}

.toggle {
  width: 100%;
  color: #555659;
  font-size: 24px;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding-left: 0;
  padding-right: 0;
}
.toggle:after {
  position: absolute;
  content: " ";
  right: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  background-image: url("../img/elements/arrow-grey.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}
@media (max-width: 767px) {
  .toggle:after {
    display: block;
  }
}
.toggle.toggled:after {
  transform: rotate(180deg);
}

.breadcrumbs {
  padding: 20px 0.5rem;
}
@media (max-width: 767px) {
  .breadcrumbs {
    padding: 20px calc(0.5rem + 10px);
  }
}
.breadcrumbs ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs ul li {
  padding-right: 15px;
  position: relative;
  color: #555659;
  font-size: 14px;
  line-height: 1.3rem;
}
.breadcrumbs ul li a {
  color: #023670;
  text-decoration: none;
}
.breadcrumbs ul li a:hover {
  text-decoration: underline;
}
.breadcrumbs ul li a:after {
  content: "»";
  position: absolute;
  right: 5px;
  color: #555659;
}

.quicklinks {
  padding: 20px 0;
  position: relative;
}
@media (max-width: 767px) {
  .quicklinks.collapse .collapse-content {
    display: none;
  }
}
.quicklinks.collapse .collapsible-btn {
  border: none;
  background: transparent;
  transform-origin: center 35%;
  transition: transform 0.6s;
}
.quicklinks.collapse .collapsible-btn.active {
  transform: rotate(180deg);
}
.quicklinks .quicklinks-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.quicklinks h4 {
  color: #023670;
  font-size: 26px;
  display: inline-block;
}
.quicklinks label {
  float: right;
  color: #ffffff;
  font-size: 26px;
  transition: all 0.6s ease;
}
@media (min-width: 768px) {
  .quicklinks label {
    display: none;
  }
}
.quicklinks input {
  position: absolute;
  opacity: 0;
  transform: scale(0.1);
  top: 20px;
  right: 10px;
}
.quicklinks .quicklinks-links {
  flex-basis: 100%;
}
.quicklinks .quicklinks-links li {
  padding: 8px 0;
  border-bottom: 1px solid #ececec;
}
.quicklinks .quicklinks-links li:first-child {
  margin-top: 10px;
  border-top: 1px solid #ececec;
}
.quicklinks .quicklinks-links li a {
  color: #009DE0;
  font-weight: 400;
  text-decoration: none;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .quicklinks .quicklinks-links li a {
    color: #ffffff;
    outline-offset: -3px;
  }
}

@media (max-width: 767px) {
  .quicklinks {
    background: #009DE0;
  }
  .quicklinks:focus {
    outline: 5px solid #e500a1;
  }
  .quicklinks > *:focus {
    outline: 5px solid #e500a1;
  }
  .quicklinks h4 {
    color: #ffffff;
    max-width: 80%;
  }
}
.single-image {
  padding: 20px 0;
}

.ects-overview {
  padding: 20px 0;
  position: relative;
}
.ects-overview abbr {
  text-decoration: none;
}
.ects-overview ul {
  width: 100%;
}
.ects-overview .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
  flex-direction: column;
}
.ects-overview .loader.active {
  z-index: 100;
  opacity: 1;
  display: flex;
}
.ects-overview .loader h3 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  color: #023670;
}
.ects-overview .loader .bullets {
  height: 25px;
}
.ects-overview .loader .bullets span {
  display: inline-block;
  background: #009DE0;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  border-radius: 50%;
  transform: translate3d(0);
  animation: bounce 0.8s infinite alternate;
}
.ects-overview .loader .bullets span:nth-child(2) {
  background: #555659;
  animation-delay: 0.2s;
}
.ects-overview .loader .bullets span:nth-child(3) {
  background: #023670;
  animation-delay: 0.4s;
}
.ects-overview .loader .bullets span:nth-child(4) {
  background: #555659;
  animation-delay: 0.6s;
}
@keyframes bounce {
  to {
    width: 16px;
    height: 16px;
    transform: translate3d(0, -2px, 0);
  }
}
.ects-overview .courses-limit-group {
  margin-bottom: 25px;
}
.ects-overview .courses-limit-group .title {
  font-size: 14px;
  color: #555659;
  padding: 0;
}
.ects-overview .courses-limit-group label {
  border: none;
  color: #cc008e;
  background-color: transparent;
  cursor: pointer;
  padding-right: 4px;
  font-size: 13px;
}
.ects-overview .courses-limit-group input {
  display: none;
}
.ects-overview .courses-limit-group span {
  font-size: 13px;
}
.ects-overview .courses-limit-group input:checked + label {
  font-weight: 800;
}
.ects-overview .courses-limit-group input:focus + label {
  outline: 2px solid #023670;
}
.ects-overview .courses-filter {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.ects-overview .courses-filter .button-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .ects-overview .courses-filter .button-wrapper {
    flex-basis: 100%;
  }
  .ects-overview .courses-filter .button-wrapper .btn {
    align-self: flex-end;
    width: 160px;
  }
}
.ects-overview .courses-filter .incoming-sprache {
  display: flex;
  flex-basis: 100%;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .ects-overview .courses-filter .incoming-sprache {
    margin-bottom: 0;
    flex-direction: column;
  }
}
.ects-overview .courses-filter .select-wrapper {
  margin-right: 8px;
  flex-basis: 16%;
  justify-content: space-between;
  max-width: 100%;
}
@media (max-width: 767px) {
  .ects-overview .courses-filter .select-wrapper {
    flex-basis: 100%;
    margin-right: 0;
  }
  .ects-overview .courses-filter .select-wrapper#semester {
    padding-right: 5px;
  }
}
.ects-overview .courses-filter .select-wrapper#incoming {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 158px;
}
.ects-overview .courses-filter .select-wrapper#incoming h4 {
  flex-basis: 100%;
}
.ects-overview .courses-filter .select-wrapper#incoming label {
  margin-right: 5px;
}
.ects-overview .courses-filter .select-wrapper#incoming div {
  margin: auto 0;
}
.ects-overview .courses-filter .select-wrapper#major {
  flex: 1;
}
.ects-overview .courses-filter .select-wrapper#semester, .ects-overview .courses-filter .select-wrapper#credit {
  flex-basis: 8%;
}
@media (max-width: 767px) {
  .ects-overview .courses-filter .select-wrapper#semester, .ects-overview .courses-filter .select-wrapper#credit {
    flex-basis: 50%;
    margin-right: 0;
  }
}
.ects-overview .courses-filter .select-wrapper .choices {
  width: auto;
}
.ects-overview .courses-filter .select-wrapper .choices:focus {
  outline: 3px auto #4D90FE;
}
.ects-overview .courses-filter .select-wrapper .choices__inner {
  border: none;
  border-radius: 10px;
  color: #9b9b9b;
  padding-left: 12px;
  padding-right: 32px;
}
.ects-overview .courses-filter .select-wrapper .choices__list {
  border: none;
  padding-left: 4px;
  padding-right: 4px;
}
.ects-overview .courses-filter .select-wrapper .choices__list--dropdown {
  border: 1px solid rgba(155, 155, 155, 0.4);
  border-top: none;
}
.ects-overview .courses-filter .select-wrapper .choices__list--dropdown .choices__item--selectable.is-highlighted:focus {
  outline: 5px solid #e500a1;
}
.ects-overview .courses-filter .select-wrapper .choices__list--dropdown .choices__item--selectable.is-highlighted > *:focus {
  outline: 5px solid #e500a1;
}
.ects-overview .courses-filter .select-wrapper .choices__list--dropdown .choices__list {
  display: flex;
  flex-direction: column;
}
.ects-overview .courses-filter .select-wrapper .choices__list--dropdown .choices__item:not(:last-child) {
  border-bottom: 1px solid rgba(155, 155, 155, 0.2);
}
.ects-overview .courses-filter .select-wrapper .choices__list--single {
  white-space: nowrap;
  overflow: hidden;
}
.ects-overview .courses-filter .select-wrapper .choices__item {
  line-height: 1.4;
}
.ects-overview .courses-filter .select-wrapper .choices__item.is-highlighted {
  outline: 2px solid #e500a1;
}
.ects-overview .courses-filter .select-wrapper .choices:after {
  content: url("/assets/img/elements/ico_arrow_open.webp");
  border: none;
  right: 22px;
  top: 48%;
  transform: scale(0.6);
}
.ects-overview .courses-filter .select-wrapper .choices.is-open:after {
  transform: scale(0.6) rotate(180deg);
  transform-origin: 8px 7px;
}
.ects-overview .reset-button {
  font-size: 14px;
  text-decoration: underline;
  padding-right: 0;
  border: none;
  background: transparent;
  margin-bottom: 20px;
  cursor: pointer;
  margin-left: 0;
}
.ects-overview .reset-button:after {
  content: "";
}
.ects-overview .course-wrapper {
  border-bottom: 1px solid rgba(155, 155, 155, 0.2);
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
}
.ects-overview .headers {
  font-size: 14px;
  color: #555659;
  font-weight: 500;
  margin-bottom: 5px;
}
.ects-overview .course {
  color: #555659;
  font-size: 20px;
}
.ects-overview .course .course-title a {
  color: #cc008e;
  text-decoration: none;
}
.ects-overview .course .course-title a img {
  height: 12px;
}
.ects-overview .headers,
.ects-overview .course {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ects-overview .headers div,
.ects-overview .course div {
  margin-left: 4px;
  flex: 2;
  display: flex;
  flex-direction: column;
}
.ects-overview .headers div.course-title,
.ects-overview .course div.course-title {
  flex: 7;
  margin-left: 0;
}
.ects-overview .headers div.open-btn,
.ects-overview .course div.open-btn {
  flex: 0.5;
  align-self: flex-end;
  text-align: right;
  display: block;
}
.ects-overview .headers div.open-btn img,
.ects-overview .course div.open-btn img {
  width: 22px;
}
.ects-overview .headers div.course-incoming img,
.ects-overview .course div.course-incoming img {
  width: 10px;
}
@media (max-width: 767px) {
  .ects-overview .headers div.course-title, .ects-overview .headers div.open-btn,
.ects-overview .course div.course-title,
.ects-overview .course div.open-btn {
    flex: 100%;
  }
  .ects-overview .headers div.open-btn,
.ects-overview .course div.open-btn {
    text-align: center;
  }
  .ects-overview .headers div.course-lang, .ects-overview .headers div.course-ects, .ects-overview .headers div.course-semester, .ects-overview .headers div.course-plan,
.ects-overview .course div.course-lang,
.ects-overview .course div.course-ects,
.ects-overview .course div.course-semester,
.ects-overview .course div.course-plan {
    flex-basis: 50%;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 0;
  }
  .ects-overview .headers div.course-ects,
.ects-overview .course div.course-ects {
    margin-left: 0;
  }
}
.ects-overview .headers .collapsible-btn,
.ects-overview .course .collapsible-btn {
  transition: all 300ms;
  border: none;
  padding: 0;
  background: transparent;
}
.ects-overview .headers .collapsible-btn.active,
.ects-overview .course .collapsible-btn.active {
  transform: rotate(180deg);
}
.ects-overview .collapse-content {
  color: #555659;
  padding-bottom: 15px;
  padding-top: 15px;
  max-width: 800px;
}
.ects-overview .collapse-content .rich-text {
  line-height: 1.5em;
  margin-bottom: 20px;
}
.ects-overview .collapse-content .rich-text ul {
  margin: 10px 0;
}
.ects-overview .collapse-content .rich-text p {
  margin: 10px 0 20px 0;
}
.ects-overview .legend {
  display: flex;
  align-items: flex-end;
  padding: 0;
}
@media (max-width: 767px) {
  .ects-overview .legend {
    justify-content: center;
    margin-top: 5px;
  }
}
.ects-overview .legend P {
  font-size: 14px;
  color: #555659;
}
.ects-overview .pagination {
  margin-top: 10px;
}
.ects-overview .pagination a,
.ects-overview .pagination span {
  text-decoration: none;
  color: #8fc2fc;
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
}
.ects-overview .pagination a img,
.ects-overview .pagination span img {
  width: 8px;
}
.ects-overview .pagination .current {
  color: #022a57;
}

.people {
  padding: 20px 0;
}
.people .dept {
  padding: 4px 0;
  color: #023670;
  font-size: 20px;
  border-bottom: 1px solid #9b9b9b;
}
.people .dept .dept-title {
  flex: 1;
}
.people .dept .dept-row {
  padding: 10px 0;
}
.people .dept .dept-collapse-content {
  margin-top: 10px;
}
.people .dept .person {
  padding: 15px 0;
  color: #555659;
  font-size: 16px;
  border-top: 1px solid #f7f7f7;
}
.people .dept .person-title {
  cursor: pointer;
}
.people .dept .person h4 {
  color: #009DE0;
  font-weight: 400;
  margin-bottom: 5px;
}
.people .dept .person .close-btn {
  display: none;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #009DE0;
}
.people .dept .person .title-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.people .person-card {
  display: flex;
  flex-wrap: wrap;
  background: #f7f7f7;
  padding: 20px;
  margin: 25px 0;
}
.people .person-card .picture {
  flex: 1;
  height: 160px;
  overflow: hidden;
  position: relative;
  min-width: 100px;
  max-width: 120px;
}
.people .person-card .picture img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.people .person-card .name-prof {
  flex: 1;
}
.people .person-card .contact-info {
  flex: 4;
  line-height: 1.2;
  padding-top: 5px;
  padding-left: 20px;
}
.people .person-card .contact-info h5 {
  font-weight: 400;
  margin-bottom: 0;
}
.people .person-card .contact-info p {
  margin-bottom: 20px;
}
.people .person-card .contact-info .tel, .people .person-card .contact-info .email {
  display: flex;
}
.people .person-card .contact-info .tel span, .people .person-card .contact-info .tel a, .people .person-card .contact-info .email span, .people .person-card .contact-info .email a {
  flex: 6;
  font-size: 16px;
}
.people .person-card .contact-info .tel span:first-child, .people .person-card .contact-info .email span:first-child {
  flex: 1;
  margin-right: 10px;
}
.people .person-card .contact-info .email span:last-child {
  color: #cc008e;
}
.people button.collapsible-btn {
  width: auto;
  border: none;
  background: transparent;
  align-self: center;
  margin: 0 4px;
  transition: all 300ms;
  padding: 10px 0;
  transform: rotate(180deg);
}
.people button.collapsible-btn.active {
  transform: rotate(0);
}
.people button.collapsible-btn.person-btn img {
  width: 15px;
}
.people .collapse-content {
  display: none;
}
.people .collapse-content.open {
  display: block;
}

.program-finder {
  padding: 20px 0;
}
.program-finder .interest-block > div {
  height: 100px;
  background: #ececec;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: relative;
}
.program-finder .interest-block > div .close {
  opacity: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: opacity 200ms;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  color: #ffffff;
}
.program-finder .interest-block > div .close:focus {
  opacity: 1;
}
.program-finder .interest-block > div.filled {
  background: #cc008e;
}
.program-finder .interest-block > div.filled:hover .close {
  opacity: 1;
}
.program-finder .interest-block .interest {
  text-align: center;
}
.program-finder .interest-block .interest::first-letter {
  text-transform: uppercase;
}
.program-finder .modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.program-finder .modal .modal-focus {
  position: absolute;
  top: -5%;
}
.program-finder .modal-content {
  margin: 10% auto;
  width: 90%;
  max-width: 800px;
  position: relative;
  background: #f7f7f7;
  border-radius: 8px;
  animation-name: modalopen 1s;
  padding: 10px;
}
.program-finder .modal-content .counter {
  flex-basis: 100%;
  text-align: right;
  padding: 0 0.5rem;
  color: #023670;
}
.program-finder .modal-content h2 {
  flex-basis: 100%;
  text-align: center;
  font-size: 26px;
  margin: 10px auto;
  color: #023670;
}
.program-finder .modal-content p {
  color: #023670;
}
.program-finder .modal-content .modal-close-box {
  text-align: right;
  width: 100%;
}
.program-finder .modal-content .modal-close-box .modal-close {
  text-transform: uppercase;
  font-size: 14px;
  color: #555659;
  cursor: pointer;
}
.program-finder .modal-content .modal-close-box .modal-close span {
  font-size: 20px;
}
.program-finder .modal-content .modal-interest {
  cursor: pointer;
}
.program-finder .modal-content .modal-interest.filled > div {
  background: #cc008e;
}
.program-finder .modal-content .modal-interest.filled > div p {
  color: #ffffff;
}
.program-finder .modal-content .modal-interest.filled > div .close {
  display: block;
}
.program-finder .modal-content .modal-interest > div {
  position: relative;
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 10px;
  text-align: center;
}
.program-finder .modal-content .close {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  color: #ffffff;
  cursor: pointer;
}
.program-finder .modal-content .add-button {
  width: 100%;
  text-align: center;
  margin: 15px auto;
}
.program-finder .modal-content .add-button .btn {
  display: inline-block;
}
.program-finder .modal-content .add-button .circle {
  width: 60px;
  height: 60px;
  background: #009DE0;
  margin: 5px auto;
  border-radius: 100%;
  position: relative;
}
.program-finder .modal-content .add-button .circle span {
  width: 30px;
  height: 6px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.program-finder .modal-content .add-button .circle span:last-child {
  width: 6px;
  height: 30px;
}
.program-finder .modal-content .add-button p {
  color: #009DE0;
  text-transform: none;
}
@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.program-finder #favouriteinterest, .program-finder #cycleInput {
  display: none;
}
.program-finder .cycle {
  margin-bottom: 40px;
  font-size: 28px;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .program-finder .cycle {
    margin-bottom: 25px;
  }
  .program-finder .cycle:after {
    content: "";
  }
}
.program-finder .cycle input {
  color: #023670;
  background: none;
  font-size: 28px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  transform: translateX(-50%);
  left: 50%;
  border: none;
  cursor: pointer;
}
.program-finder .cycle input:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .program-finder .cycle input {
    font-size: 14px;
    letter-spacing: normal;
  }
}
.program-finder .interest-search-box {
  position: relative;
}
.program-finder .interest-search-box.active #interest-search {
  border: 1px solid #ececec;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.program-finder .interest-search-box.active .suggestions {
  display: block;
}
.program-finder .interest-search-box #interest-search {
  background: #f7f7f7;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #555659;
}
.program-finder .interest-search-box #interest-search::placeholder {
  color: #9b9b9b;
}
.program-finder .interest-search-box .suggestions {
  position: absolute;
  width: calc(100% - 1rem);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: none;
}
.program-finder .interest-search-box .suggestions li {
  padding: 10px;
  color: #555659;
  border: 1px solid #ececec;
  border-top: none;
  background: #f7f7f7;
  cursor: pointer;
}
.program-finder .interest-search-box .suggestions li:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.program-finder .interest-search-box .suggestions li::first-letter {
  text-transform: uppercase;
}
.program-finder .interest-search-box .suggestions li:hover {
  background: #f6f6f6;
}
.program-finder .interest-search-box .suggestions li .hl {
  background: #ffc600;
}
.program-finder .favourite-column h4 {
  color: #023670;
  font-size: 20px;
}
.program-finder .favourite-column .favorite-block {
  background: #ececec;
  color: #023670;
  height: 60px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.program-finder .favourite-column .favorite-block.selected {
  color: #ffffff;
  background: #cc008e;
}
.program-finder .favourite-selected-block > div {
  height: 100px;
  background: #ececec;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: relative;
}
.program-finder .favourite-selected-block > div .close {
  opacity: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: opacity 200ms;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  color: #ffffff;
}
.program-finder .favourite-selected-block > div .close:focus {
  opacity: 1;
}
.program-finder .favourite-selected-block > div.filled {
  background: #009DE0;
}
.program-finder .favourite-selected-block > div.filled:hover .close {
  opacity: 1;
}
.program-finder .program-wrapper {
  border-bottom: 1px solid rgba(155, 155, 155, 0.2);
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;
}
.program-finder .program-wrapper:first-of-type {
  padding-top: 0;
}
.program-finder .program-wrapper .collapsible-btn {
  transition: all 300ms;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
}
.program-finder .program-wrapper .collapsible-btn.open-btn.active {
  transform: rotate(180deg);
}
.program-finder .program-wrapper .collapsible-btn .less {
  display: none;
}
.program-finder .program-wrapper .collapsible-btn.mobile {
  margin: auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.program-finder .program-wrapper .collapsible-btn.mobile .less,
.program-finder .program-wrapper .collapsible-btn.mobile .more {
  text-transform: uppercase;
  color: #cc008e;
  font-size: 14px;
  font-weight: 500;
}
.program-finder .program-wrapper .collapsible-btn.mobile img {
  width: 10px;
  margin-left: 2px;
  transition: all 600ms;
}
.program-finder .program-wrapper .collapsible-btn.active .less {
  display: block;
}
.program-finder .program-wrapper .collapsible-btn.active .more {
  display: none;
}
.program-finder .program-wrapper .collapsible-btn.active img {
  transform: rotate3d(1, 0, 0, 180deg);
}
.program-finder .headers {
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 500;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .program-finder .headers {
    margin-bottom: 6px;
    margin-top: 12px;
  }
}
.program-finder .program {
  color: #555659;
  font-size: 20px;
}
.program-finder .program .program-title a {
  color: #cc008e;
  text-decoration: none;
}
.program-finder .program .program-title a img {
  height: 12px;
}
.program-finder .headers,
.program-finder .program {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.program-finder .headers div,
.program-finder .program div {
  margin-left: 4px;
  flex: 2;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .program-finder .headers div,
.program-finder .program div {
    flex-basis: 50%;
    margin-left: 0;
  }
}
.program-finder .headers div.program-title,
.program-finder .program div.program-title {
  flex: 7;
  margin-left: 0;
}
@media (max-width: 767px) {
  .program-finder .headers div.program-title,
.program-finder .program div.program-title {
    flex-basis: 100%;
  }
}
.program-finder .headers div.open-btn,
.program-finder .program div.open-btn {
  flex: 0.5;
  align-self: flex-end;
  text-align: right;
  display: block;
}
.program-finder .headers div.open-btn img,
.program-finder .program div.open-btn img {
  width: 22px;
}
@media (max-width: 767px) {
  .program-finder .headers div.program-title, .program-finder .headers div.open-btn,
.program-finder .program div.program-title,
.program-finder .program div.open-btn {
    flex: 100%;
  }
  .program-finder .headers div.open-btn,
.program-finder .program div.open-btn {
    text-align: center;
  }
  .program-finder .headers div.program-lang, .program-finder .headers div.program-ects, .program-finder .headers div.program-semester,
.program-finder .program div.program-lang,
.program-finder .program div.program-ects,
.program-finder .program div.program-semester {
    flex-basis: 32%;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .program-finder .headers div.program-ects,
.program-finder .program div.program-ects {
    margin-left: 0;
  }
}
.program-finder .collapse-content {
  color: #555659;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 15px 0;
}
.program-finder .collapse-content .program-matches {
  flex: 3;
}
@media (max-width: 767px) {
  .program-finder .collapse-content .program-matches {
    flex-basis: 100%;
  }
}
.program-finder .collapse-content > div {
  flex: 1;
}
@media (max-width: 767px) {
  .program-finder .collapse-content > div {
    flex-basis: 50%;
    margin-top: 5px;
  }
}
.program-finder .collapse-content .buttons {
  flex-basis: 100%;
}
@media (max-width: 767px) {
  .program-finder .collapse-content .buttons {
    margin-top: 25px;
  }
}
.program-finder .collapse-content p {
  margin: 10px 0 20px 0;
}
.program-finder .collapse-content .headers {
  text-transform: uppercase;
}
.program-finder .pagination {
  margin-top: 10px;
}
.program-finder .pagination a,
.program-finder .pagination span {
  text-decoration: none;
  color: #8fc2fc;
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
}
.program-finder .pagination a img,
.program-finder .pagination span img {
  width: 8px;
}
.program-finder .pagination .current {
  color: #022a57;
}
.program-finder .stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 22px;
  font-family: Times;
  line-height: 1;
}
.program-finder .stars::before {
  content: "★★★★★";
  letter-spacing: 3px;
  background: linear-gradient(90deg, #00B2FF var(--percent), rgba(0, 179, 255, 0.2) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.empty-spacing.mittel {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .empty-spacing.mittel {
    padding-top: 20px;
  }
}
.empty-spacing.gross {
  padding-top: 60px;
}
@media (max-width: 767px) {
  .empty-spacing.gross {
    padding-top: 40px;
  }
}

.hr-application-form {
  padding: 20px 0;
}
.hr-application-form h3 {
  margin-top: 10px;
  font-size: 18px;
  color: #023670;
  font-weight: 500;
}
.hr-application-form .mandatory-note span {
  color: #555659;
}
.hr-application-form .hr-gdpr {
  display: flex;
  margin-bottom: 10px;
}
.hr-application-form .hr-gdpr input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.hr-application-form .hr-gdpr input[type=checkbox] + label::after {
  content: none;
}
.hr-application-form .hr-gdpr input[type=checkbox]:checked + label::after {
  content: "";
}
.hr-application-form .hr-gdpr input[type=checkbox]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
.hr-application-form .hr-gdpr label {
  line-height: 24px;
  position: relative;
  padding-left: 25px;
}
.hr-application-form .hr-gdpr label.other-check::before {
  top: -12px;
}
.hr-application-form .hr-gdpr label.other-check::after {
  top: -8px;
}
.hr-application-form .hr-gdpr label::before, .hr-application-form .hr-gdpr label::after {
  position: absolute;
  content: "";
  display: inline-block;
}
.hr-application-form .hr-gdpr label::before {
  height: 16px;
  width: 16px;
  top: 1px;
  left: 0;
  background: #ececec;
}
.hr-application-form .hr-gdpr label::after {
  height: 4px;
  width: 7px;
  left: 3px;
  top: 5px;
  border-left: 2px solid #009DE0;
  border-bottom: 2px solid #009DE0;
  transform: rotate(-55deg);
}
.hr-application-form .hr-field {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  position: relative;
}
.hr-application-form .hr-field label {
  color: #555659;
  margin-bottom: 5px;
}
.hr-application-form .hr-field input, .hr-application-form .hr-field textarea, .hr-application-form .hr-field select {
  border: 1px solid transparent;
  padding: 7.5px;
  font-size: 14px;
  min-height: 44px;
  font-family: Arial;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f7f7f7;
}
.hr-application-form .hr-field:not(.req) {
  margin-bottom: 18px;
}
.hr-application-form .hr-field .file-input {
  position: relative;
}
.hr-application-form .hr-field .file-input input {
  width: 100%;
  height: 160px;
  background: transparent;
  border: 1px dashed #555659;
  color: transparent;
}
.hr-application-form .hr-field .file-input input::-webkit-file-upload-button {
  visibility: hidden;
}
@media (max-width: 767px) {
  .hr-application-form .hr-field .file-input input {
    height: 90px;
  }
}
.hr-application-form .hr-field .file-input .btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  min-width: 200px;
  text-align: center;
}
@media (max-width: 767px) {
  .hr-application-form .hr-field .file-input .btn {
    bottom: 15px;
  }
}
.hr-application-form .hr-field .file-input .file-name {
  position: absolute;
  left: 0;
  padding: 4px;
  z-index: -1;
  top: 8px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.hr-application-form .hr-field .file-input .file-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hr-application-form .hr-field .file-input .file-value h4 {
  color: #555659;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}
.hr-application-form .hr-field .file-input .file-value span {
  color: #9b9b9b;
  font-size: 12px;
}
.hr-application-form .hr-field .file-input .file-value span.underline {
  color: #00a0e6;
  text-decoration: underline;
}
.hr-application-form .hr-field.error-active .error {
  opacity: 1;
}
.hr-application-form .hr-field .error {
  margin-top: 2px;
  color: #a20606;
  opacity: 0;
}
.hr-application-form .choices {
  border: 1px solid transparent;
  margin-bottom: 0;
}
.hr-application-form .choices.is-open .choices__inner {
  border: 1px solid #00B2FF;
  background: #009DE0;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.hr-application-form .choices__inner {
  border: none;
  border-radius: 4px;
  background-color: #f7f7f7;
}
.hr-application-form .choices__list--dropdown {
  border: 1px solid #00B2FF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.hr-application-form .choices[data-type*=select-one]:after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  border-width: 2px 0 0 2px;
  border-style: solid;
  border-color: #555659;
  margin-top: -6px;
  transform: rotate(225deg);
}
.hr-application-form .choices[data-type*=select-one].is-open:after {
  transform: rotate(45deg);
  margin-top: 0;
}

.job-listing {
  padding: 20px 0;
}
.job-listing ul li {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #ececec;
}
.job-listing ul li:first-child {
  border-bottom: none;
}
.job-listing ul li a {
  color: #023670;
  text-decoration: none;
  font-weight: 300;
}
.job-listing ul li span {
  color: #555659;
}
.job-listing ul li h4 {
  color: #555659;
  font-weight: 500;
  font-size: 16px;
}

.modal-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
}
.modal-container .modal {
  line-height: 30px;
  z-index: 101;
  width: 450px;
  position: fixed;
  max-width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 15px 30px 30px 30px;
}
.modal-container .modal .times {
  float: right;
  cursor: pointer;
  font-size: 30px;
}
.modal-container .modal .content {
  padding: 8px 0;
  margin-top: 29px;
  text-align: center;
}
.modal-container .modal .content h2 {
  font-size: 22px;
  font-weight: 500;
}
.modal-container .modal .footer {
  padding-top: 8px;
}
.modal-container .modal.bg-blue {
  background-color: #009de0;
  color: #ffffff;
}

.registration-form {
  padding: 20px 0;
}
.registration-form h3 {
  margin-top: 10px;
  font-size: 18px;
  color: #023670;
  font-weight: 500;
}
.registration-form .mandatory-note span {
  color: #555659;
}
.registration-form .flash-message {
  display: block;
  margin-top: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: #009DE0;
  color: #ffffff;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.registration-form .flash-message.flash-message-error {
  background-color: #a20606;
}
.registration-form .reg-field {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  position: relative;
}
.registration-form .reg-field label {
  color: #555659;
  margin-bottom: 5px;
  line-height: 1.3;
  display: inline-block;
}
.registration-form .reg-field input, .registration-form .reg-field textarea {
  border: 1px solid transparent;
  padding: 7.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f7f7f7;
}
.registration-form .reg-field .checkbox {
  margin-bottom: 10px;
}
.registration-form .reg-field .checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.registration-form .reg-field .checkbox input[type=checkbox] + label::after {
  content: none;
}
.registration-form .reg-field .checkbox input[type=checkbox]:checked + label::after {
  content: "";
}
.registration-form .reg-field .checkbox input[type=checkbox]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
.registration-form .reg-field .checkbox label {
  position: relative;
  padding-left: 25px;
}
.registration-form .reg-field .checkbox label.other-check::before {
  top: -12px;
}
.registration-form .reg-field .checkbox label.other-check::after {
  top: -8px;
}
.registration-form .reg-field .checkbox label::before, .registration-form .reg-field .checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
}
.registration-form .reg-field .checkbox label::before {
  height: 16px;
  width: 16px;
  top: 1px;
  left: 0;
  background: #ececec;
}
.registration-form .reg-field .checkbox label::after {
  height: 4px;
  width: 7px;
  left: 3px;
  top: 5px;
  border-left: 2px solid #009DE0;
  border-bottom: 2px solid #009DE0;
  transform: rotate(-55deg);
}
.registration-form .reg-field:not(.req) {
  margin-bottom: 18px;
}
.registration-form .reg-field .file-input {
  position: relative;
}
.registration-form .reg-field .file-input input {
  width: 100%;
  height: 160px;
  background: transparent;
  border: 1px dashed #555659;
  color: transparent;
}
.registration-form .reg-field .file-input input::-webkit-file-upload-button {
  visibility: hidden;
}
@media (max-width: 767px) {
  .registration-form .reg-field .file-input input {
    height: 90px;
  }
}
.registration-form .reg-field .file-input .btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  min-width: 200px;
  text-align: center;
}
@media (max-width: 767px) {
  .registration-form .reg-field .file-input .btn {
    bottom: 15px;
  }
}
.registration-form .reg-field .file-input .file-name {
  position: absolute;
  left: 0;
  padding: 4px;
  z-index: -1;
  top: 8px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.registration-form .reg-field .file-input .file-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registration-form .reg-field .file-input .file-value h4 {
  color: #555659;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}
.registration-form .reg-field .file-input .file-value span {
  color: #9b9b9b;
  font-size: 12px;
}
.registration-form .reg-field .file-input .file-value span.underline {
  color: #00a0e6;
  text-decoration: underline;
}
.registration-form .reg-field.error-active .error {
  opacity: 1;
}
.registration-form .reg-field .error {
  margin-top: 2px;
  color: #a20606;
  opacity: 0;
}
.registration-form .choices {
  border: 1px solid transparent;
  margin-bottom: 0;
}
.registration-form .choices.is-open .choices__inner {
  border: 1px solid #00B2FF;
  background: #009DE0;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.registration-form .choices__inner {
  border: none;
  border-radius: 4px;
  background-color: #f7f7f7;
}
.registration-form .choices__list--dropdown {
  border: 1px solid #00B2FF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.registration-form .choices[data-type*=select-one]:after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  border-width: 2px 0 0 2px;
  border-style: solid;
  border-color: #555659;
  margin-top: -6px;
  transform: rotate(225deg);
}
.registration-form .choices[data-type*=select-one].is-open:after {
  transform: rotate(45deg);
  margin-top: 0;
}

.add-to-calendar {
  padding: 20px 0;
}
.add-to-calendar .btn {
  color: #ffffff;
  text-transform: none;
  font-weight: 500;
  position: relative;
  padding-left: 45px;
  display: inline-block;
}
.add-to-calendar .btn img {
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  left: 15px;
}
.add-to-calendar .rich-text {
  text-align: left;
}
.add-to-calendar .rich-text p {
  margin-bottom: 20px;
}
.add-to-calendar.layout2menu {
  background: #009DE0;
}
.add-to-calendar.layout2menu p, .add-to-calendar.layout2menu h3, .add-to-calendar.layout2menu h4, .add-to-calendar.layout2menu h4 * {
  color: #ffffff;
}
.add-to-calendar.layout2menu h4, .add-to-calendar.layout2menu p {
  font-weight: 500;
  font-size: 14px;
}
.add-to-calendar.layout2menu .time {
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
}
.add-to-calendar.layout2menu h4, .add-to-calendar.layout2menu h4 p {
  margin-bottom: 10px;
}
.add-to-calendar.layout2menu .btn {
  background: #ffffff;
  color: #023670;
}
.add-to-calendar.layout2menu .date {
  border-bottom: 1px solid #68D1FF;
  padding-bottom: 25px;
}
.add-to-calendar.layout2menu .date:last-of-type {
  border: none;
}

.pdf-preview {
  margin: 10px 0;
}
.pdf-preview a {
  color: #cc008e;
  font-weight: 300;
}
.pdf-preview .pdf-cont {
  display: flex;
}
.pdf-preview .pdf-cont .left {
  margin-right: 20px;
}
.pdf-preview .pdf-cont .left img {
  width: 70px;
}
.pdf-preview .pdf-cont .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pdf-preview .pdf-cont .right p {
  margin-bottom: 10px;
  color: #555659;
}
.pdf-preview .pdf-container {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  height: 600px;
}
@media (max-width: 1020px) {
  .pdf-preview .pdf-container {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .pdf-preview .pdf-container {
    height: 410px;
  }
}
@media (max-width: 550px) {
  .pdf-preview .pdf-container {
    height: 340px;
  }
}
@media (max-width: 450px) {
  .pdf-preview .pdf-container {
    height: 270px;
  }
}

.page-news-entry .row.events-row {
  align-items: center;
  justify-content: center;
  display: flex;
}

.page-newsroom .pagination {
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: right;
}
.page-newsroom .pagination a {
  font-weight: 300;
  color: #cc008e;
  padding-left: 10px;
}
.page-newsroom .news-filter {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.page-newsroom .select-wrapper {
  margin-right: 20px;
}
.page-newsroom .select-wrapper .choices:focus {
  outline: 5px auto #e500a1;
}
.page-newsroom .button-wrapper {
  align-items: flex-end;
  display: flex;
}
@media (max-width: 767px) {
  .page-newsroom .news-recents {
    margin: 0 auto;
  }
  .page-newsroom form {
    margin: 0 auto;
    width: 100%;
    display: none;
    padding: 0;
  }
  .page-newsroom form .select-wrapper {
    margin-right: 0;
  }
  .page-newsroom form .choices {
    width: 100%;
  }
  .page-newsroom form.visible {
    display: block;
  }
  .page-newsroom form .button-wrapper {
    justify-content: flex-end;
  }
  .page-newsroom .news-filter {
    flex-direction: column;
  }
  .page-newsroom .news-filter .select-wrapper {
    margin-bottom: 15px;
  }
  .page-newsroom h2.underline-headline {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .courses {
    padding-top: 20px;
  }
}
.courses .back-btn-container {
  text-align: center;
}
@media (min-width: 768px) {
  .courses .back-btn-container {
    text-align: left;
  }
}
.courses .back-btn-container .back-btn {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .courses .main-content {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .courses .content-objetcs .container {
    padding: 0;
  }
}
.courses .content-objetcs .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}
.courses .right-section {
  padding: 0;
  margin-bottom: 20px;
}
.courses .course-contact {
  background: url("../img/background/bg_sub_ansprechpartner.webp");
  background-size: 100% auto;
  background-repeat: repeat-y;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.courses .infobox {
  background-color: #009DE0;
}
@media (min-width: 768px) {
  .courses .infobox.layout2menu {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
  }
}
.courses .infobox .container .headLine {
  width: 100%;
  color: #ffffff;
  font-size: 26px;
  line-height: 1.15;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courses .infobox .container .info {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  padding-top: 10px;
}
.courses .infobox .container .info:last-child {
  border: none;
}
.courses .infobox .container .info h4 {
  font-size: 14px;
  font-weight: 500;
}
.courses .infobox .container .info .rich-text {
  color: #ffffff;
}
.courses .infobox .container .info .rich-text ul {
  list-style-image: url("/assets/img/elements/bulletpoint_white.webp");
}
.courses .infobox .container .info .rich-text a {
  color: #ffffff;
}

.course-info,
.course-contact {
  background: #009DE0;
  padding: 15px;
  color: #ffffff;
  margin-bottom: 20px;
}
.course-info abbr,
.course-contact abbr {
  text-decoration: none;
}
.course-info:focus,
.course-contact:focus {
  outline: 5px solid #e500a1;
}
.course-info > *:focus,
.course-contact > *:focus {
  outline: 5px solid #e500a1;
}
@media (max-width: 767px) {
  .course-info,
.course-contact {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 0;
  }
}
.course-info .collapse-content,
.course-contact .collapse-content {
  flex-basis: 100%;
}
@media (max-width: 767px) {
  .course-info .collapse-content,
.course-contact .collapse-content {
    display: none;
  }
}
.course-info h3,
.course-contact h3 {
  font-size: 26px;
  display: inline-block;
  flex: 50;
}
@media (min-width: 768px) {
  .course-info h3,
.course-contact h3 {
    margin-bottom: 10px;
  }
}
.course-info .collapsible-btn,
.course-contact .collapsible-btn {
  float: right;
  transition: all 300ms;
  padding-top: 5px;
  background: none;
  border: none;
  flex: 1;
}
.course-info .collapsible-btn.active,
.course-contact .collapsible-btn.active {
  transform: rotate(180deg);
}
.course-info .info,
.course-contact .info {
  border-bottom: 1px solid #ffffff;
  padding: 10px 0;
}
.course-info .info h4,
.course-contact .info h4 {
  font-size: 14px;
  padding-bottom: 8px;
  font-weight: 500;
}
.course-info .info p,
.course-contact .info p {
  line-height: 1.2;
}
.course-info li,
.course-contact li {
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}
.course-info li a,
.course-contact li a {
  color: #cc008e;
}
.course-info li:last-child,
.course-contact li:last-child {
  border: none;
}
.course-info li p,
.course-contact li p {
  color: #ffffff;
}
.course-info li .person-card,
.course-contact li .person-card {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.course-info li .person-card .picture,
.course-contact li .person-card .picture {
  flex: 1;
  min-height: 120px;
  min-width: 80px;
}
.course-info li .person-card .contact-info,
.course-contact li .person-card .contact-info {
  color: #ffffff;
  flex: 4;
  line-height: 1.2;
}
.course-info li .person-card .contact-info h5,
.course-contact li .person-card .contact-info h5 {
  color: #ffffff;
}
.course-info .rich-text,
.course-contact .rich-text {
  line-height: 1.4;
}

.course-info ul {
  list-style-image: url("/assets/img/elements/bulletpoint_white.webp");
  padding-left: 15px;
}
.course-info ul li {
  border-bottom: none;
  padding: 8px 0;
}
@media (max-width: 767px) {
  .course-info {
    margin: 0;
  }
}

.job-detail {
  padding: 20px 0;
}
.job-detail h3 {
  text-align: left;
}
.job-detail .btn {
  display: inline-block;
}