.registration-form{
  padding: 20px 0;

  h3{
    margin-top: 10px;
    font-size: 18px;
    color: $blue70;
    font-weight: 500;
  }

  .mandatory-note span {
    color: $grey;
  }

  .flash-message{
    @include flash-messages;
  }

  .reg-field{
    display: flex;
    flex-direction: column;
    margin-top:25px;
    position: relative;
    label{
      color: $grey;
      margin-bottom: 5px;
      line-height: 1.3;
      display: inline-block;
    }
    input,textarea{
      border: 1px solid transparent;
      padding: 7.5px;
      font-size: 14px;
      min-height: 44px;
      overflow: hidden;
      border-radius: 4px;
      background-color: $very-light-grey;
    }
    
    .checkbox{
      margin-bottom: 10px;
      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        + label::after {
          content: none;
        }
        &:checked + label::after {
          content: "";
        }
        &:focus + label::before {
          outline: rgb(59, 153, 252) auto 5px;
      }      
      }
      label{
        position: relative;
        padding-left: 25px;
        &.other-check::before,
        {
          top:-12px;
        }
        &.other-check::after{
          top:-8px;
        }
        &::before,&::after{
            position: absolute;
            content: "";
            display: inline-block;
        }
        &::before{
          height: 16px;
          width: 16px;
          top: 1px;
          left: 0;
          background: $light-grey;  
        }
        &::after {
          height: 4px;
          width: 7px;
          left: 3px;
          top: 5px;
          border-left: 2px solid $light-blue;
          border-bottom: 2px solid $light-blue;
          transform: rotate(-55deg);
        }
      }
    }

    &:not(.req){
      margin-bottom: 18px;
    }

    .file-input{
      position: relative;

      input{
        width: 100%;
        height: 160px;
        background: transparent;
        border: 1px dashed $grey;
        color: transparent;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        @include brk-mobile{
          height: 90px;
        }
      }

      .btn{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        min-width: 200px;
        text-align: center;
        @include brk-mobile{
          bottom: 15px;
        }
      }

      .file-name{
        position: absolute;
        left: 0;
        padding:4px;
        z-index: -1;
        top: 8px;
        width: 100%;
        text-align: center;
        overflow: hidden;
      }
      
      .file-value{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4{
          color: $grey;
          font-size: 20px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 5px;
        }
        span{
          color: $darker-light-grey;
          font-size:12px;
          &.underline{
            color: $border-blue;
            text-decoration: underline;
          }
        }
      }
    }

    &.error-active{
      .error{
        opacity: 1;
      }
    }
    .error{
      margin-top:2px;
      color:$red;
      opacity: 0;
    }

  }

  .choices{
    border: 1px solid transparent;
    margin-bottom: 0;
    &.is-open{
      .choices__inner{
        border: 1px solid #00B2FF;
        background:$light-blue;
        color: $white;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &__inner{
      border:none;
      border-radius: 4px;
      background-color: $very-light-grey;
    }
    &__list--dropdown{
      border: 1px solid #00B2FF;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &[data-type*=select-one]{
      &:after{
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        border-width: 2px 0 0 2px;
        border-style: solid;
        border-color: #555659;
        margin-top: -6px;
        transform: rotate(225deg);
      }
      &.is-open:after{
        transform: rotate(45deg);
        margin-top:0;
      }
    }
  }
}