.teaser-desktop {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 80px;
    position: relative;
    transform: translateY(-170px);
    z-index: 2;
    margin-bottom: -130px;
    @media screen and (min-width: 1350px) {
        transform: translateY(-190px);
    }
    @media screen and (min-width: 1550px) {
        transform: translateY(-210px);
    }
    @media screen and (min-width: 1720px) {
        transform: translateY(-230px);
    }

    @include brk-mobile {
        transform: translateY(-164px);
    }

    @include focus-pink-outline;

    &:after {
        background: $light-grey;
        content: " ";
        position: absolute;
        width: 100%;
        height: 78%;
        bottom: 0;
        left: 0;
        @media screen and (min-width: 1350px) {
            height: 66%;
        }
        @media screen and (min-width: 1550px) {
            height: 58%;
        }
        @media screen and (min-width: 1720px) {
            height: 54%;
        }
    }

    .teaser-item {
        background-position: center;
        background-size: cover;
        height: 370px;
        text-decoration: none;
        text-align: right;
        z-index: 5;
        outline-offset: -3px;

        @media screen and (max-width: 845px) {
            padding-left: 4px;
            padding-right: 4px;
        }
        @media screen and (max-width: 330px) {
            padding-left: 3px;
            padding-right: 3px;
        }

        @media screen and (min-width: 520px) {
            flex-basis: 50%;
        }

        @media screen and (max-width: 519px) {
            background-position: top;
        }

        @include brk-mobile {
            height: 260px;
        }

        span {
            background: $blue70;
            color: $white;
            padding: 8px;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            letter-spacing: 0.8px;
            p {
                display: inline;
            }

            @media screen and (max-width: 877px) {
                letter-spacing: 0.5px;
                padding-left: 7px;
                padding-right: 7px;
            }
            @media screen and (max-width: 812px) {
                letter-spacing: 0.3px;
                padding-left: 6px;
                padding-right: 6px;
            }
            @media screen and (min-width: 766px) and (max-width: 785px) {
                letter-spacing: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @media screen and (max-width: 423px) {
                letter-spacing: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @media screen and (max-width: 406px) {
                padding-left: 4px;
                padding-right: 4px;
                font-size: 13px;
            }
            @media screen and (max-width: 378px) {
                padding-left: 3px;
                padding-right: 3px;
                font-size: 12.5px;
            }
            @media screen and (max-width: 362px) {
                font-size: 12px;
            }
            @media screen and (max-width: 351px) {
                letter-spacing: -0.2px;
                font-size: 11.5px;
            }
            @media screen and (max-width: 330px) {
                letter-spacing: -0.3px;
                font-size: 11px;
            }
        }
    }
}
