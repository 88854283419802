.time-table {
    padding-top: 40px;
    .container{
        padding: 0;
    }
    .row {
        text-align: left;
        .events-times{
            padding-bottom: 20px;
            display: flex;
            @include brk-mobile{
                flex-direction: column;
            }
            .timeRange {
                color: $grey;
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                text-align: justify;
                margin-right: 5vw;
            }
            .timeText {
                color: $grey;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .post-title {
            color: $blue70;
            font-size: 26px;
            letter-spacing: 0.23px;
            line-height: 33px;
            padding-bottom: 5px;
        }
        .post-date {
            color: $blue70;
            font-size: 14px;
            line-height: 22px;
            padding-bottom: 10px;
            font-weight: 500;
        }
        
        .blog-block-text {
            color: $grey;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 20px;
        }
    }
    
}
