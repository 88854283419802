$white: #ffffff;
$black: #000000;
$blue57: #022a57;
$blue70: #023670;
$light-blue: #009DE0;
$border-blue: #00a0e6;
$grey: #555659;
$very-light-grey: #f7f7f7;
$darker-light-grey: #9b9b9b;
$light-grey: #ececec;
$pink: #cc008e;
$bright-pink: #e500a1;
$red: #a20606;
$teal: #069ea2;
$very-light-blue: #72cef7;
$outline: #4D90FE;
$EECgreen: #84BD00;
$EECpurple: #7E3E93;
$EECgray: #3f3f3f;
$EECdarkerGreen: #4F7100;


$brk-small: 1020px;
$brk-mobile: 767px;
$brk-s-mobile: 440px;

$container-padding: 10px;

$img-path: '../img';

$max-width: 1180px;
$max-outer-width: 2266px;

$flexboxgrid-gutter-width: 20px;
// $breakpoints: sm 50.625em 49.625rem, md 62em 61rem, lg 75em 71rem;
$desktop-default-side-padding: 150px;
