.text {
    padding: 30px 0;
    h3 {
        text-align: left;
        color: $blue70;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 400;
    }
    p {
        color: $grey;
        text-align: justify;
        font-size: 16px;
        line-height: 24px;
    }
}
