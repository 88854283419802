.bullet-points {
    padding: 10px 0;

    .iconText {
        background-image: url($img-path + '/elements/ico_bulletpoint.webp');
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 5px;
    }

    .bulletText {
        display: inline-block;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    .larger-blue {
        color: $blue70;
        @include font-roboto;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 40px;
        text-decoration: none;
        padding: 5px 0;
    }

    .label-row2 {
        padding-bottom: 20px;
    }

    .small-grey {
        color: $grey;
        @include font-roboto;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        padding: 5px 0;
    }

    .bg-style {
        height: 100%;
        width: 100%;
        padding: 10px 20px;
    }

    .bg-style-grey {
        background-color: $light-grey;
    }

    .bg-style-white-greyborder {
        border: 1px solid $light-grey;
    }

    .bullet-points-list {
        text-align: left;

        &.bigger-text li {
            font-size: 24px;
            color: $blue70;
        }
    }

    .layout2menu {
        .col-xs-12 {
            padding: 0;
        }

        .bigger-text li {
            font-size: 16px;
            color: $grey;
        }
    }

}
