.representation-for-students {
    padding: 10px 0 20px 0;
    .representation-image {
        height: 280px;
        margin-top: 10px;
    }
    h3 p,
    h3 span{
        font-size: 24px;
        line-height: 32px;
        color: $blue70;
        font-weight: 400;
        letter-spacing: 0.8px;
        margin-top: 10px;
    }
    .representation-text {
        font-size: 14px;
        line-height: 16px;
        color: $grey;
        margin-bottom: 15px;
    }
    p {
        color: $grey;
        font-size: 16px;
        line-height: 18px;
        .representation-email {
            color: $pink;
        }
        &.phone-number, &.email{
            margin: 5px 0;
        }
    }
}
