.programs {
    padding: 20px 0 40px 0;
    overflow: hidden;
    .row {
        position: relative;
        &:after {
            position: absolute;
            width: 100%;
            background: $light-grey;
            content: '';
            bottom: -20px;
            width: calc(100% - 1rem);
            height: 1px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .programs-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include brk-desktop {
            &:nth-child(n + 4) {
                margin-top: 20px;
            }
        }
        @include brk-mobile {
            margin-bottom: 10px;
        }
        .programs-image{
            text-decoration: none;
        }
    }
    h3 {
        color: $blue70;
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: 400;
        text-align: left;
        @include brk-mobile{
            margin-bottom: 10px;
        }
    }
    .programs-text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: left;
        &.no-margin {
            margin-bottom: 0;
        }
    }
    .programs-item-img {
        height: 200px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        @include brk-mobile{
            width: calc(100% + 20pt + 1rem);
            margin-left: calc(-10pt - 0.5rem);
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;
            width: auto;
        }
    }
    .btn-block {
        text-align: right;
        &.hide {
            opacity: 0;
        }
        &.hide-all {
            display: none;
        }
        @include brk-mobile {
            &.hide-mobile {
                display: none;
            }
        }
    }
}
