.courses {
    @include brk-desktop{
        padding-top:20px;
    }

    .back-btn-container{
        text-align: center;
        @include brk-desktop{
            text-align: left;
        }
        .back-btn{
            margin-bottom: 16px;
        }
    }
   

    .main-content {
        @include brk-mobile {
            flex-direction: column-reverse;
        }
    }

    .content-objetcs {
        .container {
            @include brk-mobile {
                padding: 0;
            }
        }
        .col-xs-12 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .right-section {
        padding: 0;
        margin-bottom: 20px;
    }

    .course-contact{
        background:url($img-path + "/background/bg_sub_ansprechpartner.webp");
        background-size: 100% auto;
        background-repeat: repeat-y;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .infobox{
        @include infobox;
    }
}

.course-info,
.course-contact {
    abbr{
        text-decoration: none;
    }
    @include focus-pink-outline;
    background: $light-blue;
    padding: 15px;
    color: $white;
    margin-bottom: 20px;
    @include brk-mobile {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 0;
    }
    .collapse-content {
        flex-basis: 100%;
        @include brk-mobile {
            display: none;
        }
    }
    h3 {
        font-size: 26px;
        display: inline-block;
        flex: 50;
        @include brk-desktop{
            margin-bottom: 10px;
        }
    }
    .collapsible-btn {
        float: right;
        transition: all 300ms;
        padding-top: 5px;
        background: none;
        border: none;
        flex:1;
        &.active {
            transform: rotate(180deg);
        }
    }
    .info {
        border-bottom: 1px solid $white;
        padding: 10px 0;
        h4 {
            font-size: 14px;
            padding-bottom: 8px;
            font-weight: 500;
        }
        p{
            line-height: 1.2;
        }
    }
    li {
        padding: 10px 0;
        border-bottom: 1px solid $white;
        color: $white;
        a {
            color: $pink;
        }
        &:last-child {
            border: none;
        }
        p{
            color: $white;
        }
        .person-card{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .picture{
                flex:1;
                min-height: 120px;
                min-width: 80px;
            }
            .contact-info{
                color: $white;
                flex: 4;
                line-height: 1.2;
                // padding-top: 5px;
                // padding-left: 10px;
                h5{
                    color: $white;
                }
            }
        }
    }
    .rich-text{
        line-height: 1.4;
    }
}

.course-info{
    ul {
        list-style-image: url('/assets/img/elements/bulletpoint_white.webp');
        padding-left: 15px;
        li{
            border-bottom: none;
            padding: 8px 0;
        }
    }
    @include brk-mobile{
        margin: 0;
    }
}
