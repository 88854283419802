.program-finder{
  padding: 20px 0;
  
  .interest-block{
    &>div{
      height: 100px;
      background: $light-grey;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      position: relative;
      
      .close{
        opacity: 0;
        position: absolute;
        top: 5px;
        right: 5px;
        transition: opacity 200ms;
        font-size: 20px;
        cursor: pointer;
        padding: 5px;
        color: $white;
        &:focus{
          opacity: 1;
        }
      }
      &.filled{
        background: $pink;
        &:hover .close{
          opacity: 1;
        }
      }
    }
    .interest{
      text-align: center;
      &::first-letter{
        text-transform: uppercase;
      }
    }
  } 

  .modal{
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    .modal-focus {
      position: absolute;
      top: -5%;
    }
  }

  .modal-content {
    margin: 10% auto;
    width: 90%;
    max-width: 800px;
    position: relative;
    background: $very-light-grey;
    border-radius: 8px;
    animation-name: modalopen 1s;
    padding:10px;
    .counter{
      flex-basis: 100%;
      text-align: right;
      padding: 0 0.5rem;
      color: $blue70;
    }
    h2{
      flex-basis: 100%;
      text-align: center;
      font-size: 26px;
      margin: 10px auto;
      color: $blue70;
    }
    p{
      color: $blue70;
    }
    .modal-close-box{
      text-align: right;
      width: 100%;
      .modal-close{
        text-transform: uppercase;
        font-size: 14px;
        color: $grey;
        cursor:pointer;
        span{
          font-size: 20px;
        }
      }
    }
    .modal-interest{
      cursor:pointer;
      &.filled{
        &>div{
          background: $pink;
          p{
            color: $white;
          }
          .close{
            display: block;
          }
        }
      }
      
      &>div{
        position: relative;
        background: $light-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin-top: 10px;
        text-align: center;
      }
    }
    .close{
      position: absolute;
      right: 5px;
      top: 5px;
      display: none;
      color: $white;
      cursor:pointer;
    }

    .add-button{
      width: 100%;
      text-align: center;
      margin: 15px auto;
      .btn{
        display: inline-block;
      }
      .circle{
        width: 60px;
        height: 60px;
        background: $light-blue;
        margin: 5px auto;
        border-radius: 100%;
        position: relative;
        span{
          width: 30px;
          height: 6px;
          background:$white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          &:last-child{
            width: 6px;
            height: 30px;
          }
        }
      }
      p{
        color: $light-blue;
        text-transform: none;
      }
    }
  }

  @keyframes modalopen {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #favouriteinterest, #cycleInput{
    display: none;
  }

  .cycle {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 1.2;
    
    @include brk-mobile{
      margin-bottom: 25px;
      &:after{
        content:'';
      }
    }
    input{
      color:$blue70;
      background: none;
      font-size: 28px;
      font-weight: 500;
      position: relative;
      display: inline-block;
      text-align: center;
      line-height: 1.2;
      transform: translateX(-50%);
      left: 50%;
      border: none;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
      @include brk-mobile{
        font-size: 14px;
        letter-spacing: normal;
      }
    }
  }
  
  

  .interest-search-box{
    position: relative;
    &.active{
      #interest-search{
        border: 1px solid $light-grey;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .suggestions{
        display: block;
      }
    }

    #interest-search{
      background: $very-light-grey;
      border: none;
      padding: 12px 20px;
      border-radius: 8px;
      width:100%;
      text-align: center;
      font-size: 20px;
      color: $grey;
      &::placeholder{
        color: $darker-light-grey;
      }
    }
    .suggestions{
      position: absolute;
      width: calc(100% - 1rem);
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      display: none;
      li{
        padding: 10px;
        color: $grey;
        border: 1px solid $light-grey;
        border-top: none;
        background: $very-light-grey;
        &:last-of-type{
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &::first-letter{
          text-transform: uppercase;
        }
        cursor: pointer;
        &:hover{
          background: lighten($light-grey , 4%);
        }
        .hl{
          background: #ffc600;
        }
      }
    }
  }

  .favourite-column{
    h4{
      color: $blue70;
      font-size: 20px;
    }
    .favorite-block{
      background: $light-grey;
      color: $blue70;
      height: 60px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      &.selected{
        color: $white;
        background: $pink;
      }
    }
  }

  .favourite-selected-block > div{
    height: 100px;
    background: $light-grey;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    position: relative;
    .close{
      opacity: 0;
      position: absolute;
      top: 5px;
      right: 5px;
      transition: opacity 200ms;
      font-size: 20px;
      cursor: pointer;
      padding: 5px;
      color: $white;
      &:focus{
        opacity: 1;
      }
    }
    &.filled{
      background: $light-blue;
      &:hover .close{
        opacity: 1;
      }
    }
  }

  .program-wrapper {
    border-bottom: 1px solid rgba(155, 155, 155, 0.2);
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    &:first-of-type{
      padding-top:0;
    }
    .collapsible-btn {
      transition: all 300ms;
      text-align: center;
      border:none;
      background: transparent;
      padding: 0;
      &.open-btn.active {
          transform: rotate(180deg);
      }
      .less{
          display: none;
      } 
      &.mobile{
        margin: auto;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .less,
        .more{
          text-transform: uppercase;
          color: $pink;
          font-size: 14px;
          font-weight: 500;
          
        }
        img{
          width:10px;
          margin-left: 2px;
          transition: all 600ms;
        }
      }
      
      &.active{
          .less{
              display:block;
          }
          .more{
              display: none;
          }
          img{
            transform: rotate3d(1, 0, 0, 180deg);
          }
      }
  }
  }

  .headers {
    font-size: 14px;
    color: $darker-light-grey;
    font-weight: 500;
    margin-bottom: 12px;
    @include brk-mobile{
      margin-bottom: 6px;
      margin-top: 12px;
    }
  }
  .program {
      color: $grey;
      font-size: 20px;
      .program-title a {
          color: $pink;
          text-decoration: none;
          img {
              height: 12px;
          }
      }
  }

  .headers,
  .program {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      div {
          margin-left: 4px;
          flex: 2;
          display: flex;
          flex-direction: column;
          @include brk-mobile{
            flex-basis: 50%;
            margin-left: 0;
          }
          &.program-title {
              flex: 7;
              margin-left: 0;
              @include brk-mobile{
                flex-basis: 100%;
              }
          }
          &.open-btn {
              flex: 0.5;
              align-self: flex-end;
              text-align: right;
              display: block;
              img {
                  width: 22px;
              }
          }
          @include brk-mobile {
              &.program-title,
              &.open-btn {
                  flex: 100%;
              }
              &.open-btn {
                  text-align: center;
              }
              &.program-lang,
              &.program-ects,
              &.program-semester {
                  flex-basis: 32%;
                  margin-bottom: 15px;
                  margin-top: 15px;
              }
              &.program-ects {
                  margin-left: 0;
              }
          }
      }
      
  }
  .collapse-content {
      color: $grey;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 15px 0;
      .program-matches{
        flex:3;
        @include brk-mobile{
          flex-basis: 100%;
        }
      }
      &>div{
        flex:1;
        @include brk-mobile{
          flex-basis: 50%;
          margin-top: 5px;
        }
      }
      .buttons{
        flex-basis: 100%;
        @include brk-mobile{
          margin-top: 25px;
        }
      }
      p {
          margin: 10px 0 20px 0;
      }
      .headers{
        text-transform: uppercase;
      }
  }

  .pagination {
    margin-top: 10px;
    a,
    span {
        text-decoration: none;
        color: lighten($blue57, 60%);
        font-size: 14px;
        font-weight: 400;
        margin-left: 3px;
        img {
            width: 8px;
        }
    }
    .current {
        color: $blue57;
    }
  }
  
  .stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: 22px;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    
    &::before {
      content: '★★★★★';
      letter-spacing: 3px;
      background: linear-gradient(90deg, #00B2FF var(--percent), rgba(0, 179, 255, 0.2) var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
}