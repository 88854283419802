.three-column-image{
  .container{height:100%;width:100%;
     .row{ grid-column-gap: 10px;
             .Column{
                         height:100%;
                      width:100%;
                      img{
                        height:200px;
                        width:100%;
                        margin:40px 0 10px 0;
                      }
                          .textlink {
                              height:36px;
                               width: 100%;
                              line-height: 16px;
                               text-align: right;
                               a:link{
                                      color: #CC008E;
                                      font-family: Roboto;
                                      font-size: 14px;
                                      font-weight: bold;
                                      letter-spacing: 0.8px;
                                      text-decoration: none;
                                      text-transform: uppercase;
                                }
                                a:hover{
                                   text-decoration: underline;
                                }
                          }
                }
       }
   }
}
