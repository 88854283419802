.btn {
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px 18px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 1.1;
    cursor: pointer;

    &-white {
        background: $white;
        color: $blue70;
        transition: background-color .4s, color .4s;

        &:hover, &:focus {
            background: $blue70;
            color: $white;
        }
    }

    &-pink{
        background: $pink;
        transition: background-color .4s;
        text-transform: none;

        &.btn{
            color: $white;
            font-weight: 500;
        }

        &:hover, &:focus {
            background: darken($pink, 5%);
        }
    }

    &-light-blue {
        background: $light-blue;
        color: $white;
        transition: background-color .4s;
        border: none;

        &:hover, &:focus {
            background: darken($light-blue, 10%);
        }
    }

    &-empty {
        color: $blue70;
        border: 1px solid $blue70;
        background: $white;
    }

    &-more {
        color: $pink;
        padding: 0;
        text-align: right;
        overflow: visible;
        margin-left: -50%;
        position: relative;
        padding: 5px;
        padding-right: 10px;
        padding-bottom: 0;
        margin-bottom: 5px;

        &:after {
            content: url($img-path + "/elements/ico_arrow_pink.webp");
            position: absolute;
            right: 0;
            bottom:0;
        }
    }

    &-block-left {
        text-align: left;
        @include brk-mobile {
            text-align: center;
        }
    }

    &-block-right {
        margin-top: 20px;
        text-align: right;
    }

    &-mobile {
        display: none;
        margin-top: 20px;
    }

    &-large {
        padding-left: 35px;
        padding-right: 35px;
    }

    &-disabled {
        border: 1px solid $darker-light-grey;
        background-color: $white;
        color: $darker-light-grey;
        pointer-events: none;
        cursor: default;
    }
}

@include brk-mobile {
    .btn {
        &-mobile {
            display: flex;
        }
    }
}
