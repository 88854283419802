body,
html {
    height: 100%;
    color: $black;
}

html body {
    display: block;
    @include font-roboto;
    font-weight: 300;
}

* {
    box-sizing: border-box;
    &:focus {
        outline: 3px solid $bright-pink;
    }
}

.container {
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.rich-text {
    @include rich-text-styles;
}

.no-padding-left {
    padding-left: 0;
    @include brk-mobile {
        padding: 0;
    }
}

.no-padding-right {
    padding-right: 0;
    @include brk-mobile {
        padding: 0;
    }
}

#h-name-input {
    display: none;
}

@include brk-mobile {
    .page-home {
        // height: 100vh;
        // width: 100vw;
        // overflow: hidden;

        header .logo,
        header #mobile-btn {
            // display: none;
        }

        #main {
            // position: absolute;
            // top: 0;
            // left: 0;
            // height: 100vh;
            // width: 100vw;

            .layout1 {
                > div {
                    // display: none;
                }

                .compass-navigation-wrapper {
                    display: flex;
                    // height: 100%;
                    width: 100vw;
                    height: calc(var(--vh, 1vh) * 100);
                }
            }
        }

        footer,
        #fh-skiplinks {
            // display: none;
        }
    }

    .container {
        padding-right: $container-padding;
        padding-left: $container-padding;
    }

    .collapsible {
        .collapsible-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out;
        }
        .collapsible-button {
            border: none;
            background: transparent;
            transform-origin: center 35%;
            transition: transform 0.6s;
            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .show-one-mobile:not(:first-child) {
        display: none;
    }
}

.hidden-mobile {
    @include brk-mobile {
        display: none !important;
        user-focus: none !important;
        user-select: none !important;
    }
}

.hidden-desktop {
    @include brk-desktop {
        display: none !important;
        user-focus: none !important;
        user-select: none !important;
    }
}

.overlay {
    background: #494949;
    opacity: 0;
    z-index: -10;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    transition: all 400ms;
    &.show {
        z-index: 999;
        opacity: 0.6;
    }
}

.stick-to-top {
    @include brk-mobile {
        display: block !important;
        width: 100%;
        &.show {
            top: 0;
            overflow: scroll;
            position: fixed !important;
            z-index: 1000;
        }
    }
}

.underline-headline {
    @include headline-underlined;
}

.no-padding {
    padding: 0;
}

.grey-bg {
    background-color: $light-grey;
}

.text-center {
    text-align: center;
}

.el-center {
    display: flex;
    margin: auto;
}

$directions: top, left, right, bottom;
$props: margin, padding;

@for $i from 1 through 10 {
    @each $direction in $directions {
        @each $prop in $props {
            .#{$prop}-#{$direction}-#{$i * 10} {
                #{$prop}-#{$direction}: $i * 10px;
            }
        }
    }
}

@for $i from 1 through 10 {
    @each $direction in $directions {
        @each $prop in $props {
            @include brk-mobile {
                .mobile-#{$prop}-#{$direction}-#{$i * 10} {
                    #{$prop}-#{$direction}: $i * 10px;
                }
            }
        }
    }
}

.layout1,
.layout2 {
    padding-left: 0;
    padding-right: 0;
}

.col-sm-8.layout2 {
    @include brk-desktop {
        padding-right: 1rem;
    }
}

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-top: $light-grey 1px solid;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.custom-scripts {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 0;
    height: 0;
}

#main {
    width: 100%;
}

.stop-scroll {
    overflow: hidden;
}

#fh-skiplinks {
    left: 0;
    list-style-type: none;
    margin: 0;
    position: absolute;
    top: 0;
    z-index: 10000;
    .fh-skiplink {
        background: none repeat scroll 0 0 #000;
        color: #fff;
        font-weight: 700;
        left: 100px;
        opacity: 0.8;
        padding: 0.5em;
        position: absolute;
        text-align: center;
        text-decoration: underline;
        top: -4em;
        width: 11em;
        &:focus,
        &:active {
            top: 1.5rem;
        }
    }
}

.pink-outline {
    @include focus-pink-outline;
}

.text-black {
    color: $black !important;
    a {
        color: $black !important;
    }
}

.text-white {
    color: $white !important;
    a {
        color: $white !important;
    }
}

.bg-white {
    background: $white !important;
}

.btn-bw {
    background: $white !important;
    border: 1px solid $black !important;
    color: $black !important;
}

.hidden {
    display: none;
}

.darker-bg {
    background: #007db3 !important;
}

.choices[data-type*="select-multiple"] .choices__input.is-hidden,
.choices[data-type*="select-one"] .choices__input.is-hidden,
.choices__input.is-hidden {
    display: none;
}

.choices__list--single {
    margin-top: 4px !important;
    font-family: Arial !important;
    color: #000 !important;
    font-size: 14px !important;
}

#executive-education {
    .infobox .headLine {
        color: $white;
    }

    .contact-image-left-contact,
    .content-box .row .part2,
    .benefits,
    .infobox,
    .apply-now-banner {
        background: $EECpurple;
    }

    .courses .course-contact,
    .contact-image-left-right-contact,
    .contact-image-left-contact,
    .contact-image-right-contact {
        background: url($img-path + "/background/ee_bg_sub_ansprechpartner.svg");
        background-size: cover;
    }

    .apply-now-banner {
        background: url($img-path + "/background/ee_bg_sub_onlinetest.svg");
        background-size: cover;
    }

    .header-image {
        h1 {
            font-weight: 900;
        }
        .triangle {
            background: linear-gradient(
                to top left,
                $EECgreen 49.6%,
                rgba(0, 0, 0, 0) 50%
            );
        }
        .box:after {
            background: $EECgreen;
        }
    }

    .mobile-button {
        background: $EECgreen;
    }

    .btn-more {
        color: $EECpurple;
        &:after {
            content: url($img-path + "/icons/ico_arrow_EECpurple.webp");
        }
    }

    .rich-text h2,
    .underline-headline {
        color: $EECdarkerGreen;
        font-weight: 800;
        &::after {
            background: $EECdarkerGreen;
        }
    }

    .programs h4,
    .part1 .title,
    .headLine,
    .headline2,
    .headline3,
    .rich-text h3,
    .rich-text h4,
    .rich-text h5,
    .rich-text h6,
    .rich-text span.lang-text,
    .btn-white,
    .breadcrumbs li a,
    .menu-item .menu-item-link {
        color: $EECdarkerGreen;
    }

    .white-strip
        .menu-wrapper
        .menu-search
        .menu
        .menu-list
        .menu-item:focus-within {
        .menu-item-link {
            color: $white;
        }
    }

    .btn-white,
    .menu-item {
        &:hover,
        &:hover .menu-item-link {
            color: $white;
        }
    }

    .rich-text a {
        color: $EECpurple;
    }

    .infobox .rich-text a {
        color: #f7cfe4;
    }

    .search-login .btn {
        background-color: $EECdarkerGreen;
    }

    .a-url {
        .iconDownload {
            background-image: url($img-path + "/icons/ico_download_EECpurple.webp");
        }
        .fileExtension {
            color: $EECpurple;
        }
    }

    .ft-white-strip,
    .services::before,
    .info::before {
        // background: $black;
    }

    .ft-triangle {
        // background: linear-gradient(to bottom right,$EECgray 49.8%,rgba(0,0,0,0) 50%);
    }

    @include brk-mobile {
        .teaser-items-mobile-item,
        .home-buttom {
            background: $EECdarkerGreen;
        }
        .menu-search,
        .search input,
        .dropdown-menu,
        .search-login .btn {
            background: $EECgreen;
        }
        .menu-item {
            border-bottom: 1px solid $EECdarkerGreen;
        }
        .socialmedia-mobile {
            border-top: 1px solid $EECdarkerGreen;
            a {
                border-right: 1px solid $EECdarkerGreen;
            }
        }
        .teaser-items-mobile-item:nth-child(2n) {
            border-left: 1.5px solid $EECgreen;
        }
        .teaser-items-mobile-item:nth-child(odd) {
            border-right: 1.5px solid $EECgreen;
        }
        .ft-black-strip {
            // background: $black;
        }
        .ft-white-strip {
            // background: $EECgray;
        }
    }
}

.video-template .uc-embedding-container {
    position: absolute !important;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 99;
    min-height: unset !important;

    .uc-embedding-wrapper {
        height: 100%;
        max-height: 95%;

        h3 {
            font-size: 17px;
            margin: 0 auto 12px !important;
        }

        .description-text {
            display: none;
        }
    }
}
