.ects-overview {
    padding: 20px 0;
    position: relative;

    abbr{
        text-decoration: none;
    }

    ul{
        width: 100%;
    }

    .loader{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: -1;
        opacity: 0;
        background: rgba(255,255,255,.7);
        flex-direction: column;
        &.active{
            z-index: 100;
            opacity: 1;
            display: flex;
        }

        h3{
            margin-bottom: 30px;
            font-size: 28px;
            font-weight: 500;
            color: $blue70;
        }
        .bullets {
            height: 25px;
            span{
                display:inline-block;
                background:$light-blue;
                width:10px;
                height:10px;
                margin:0 8px;
                border-radius: 50%;
                transform: translate3d(0);
                animation: bounce .8s infinite alternate;
                &:nth-child(2){
                    background: $grey;
                    animation-delay: .2s;
                }
                &:nth-child(3){
                    background: $blue70;
                    animation-delay: .4s;
                }
                &:nth-child(4){
                    background: $grey;
                    animation-delay: .6s;
                }
            }
        }

        @keyframes bounce{
            to{
             width: 16px;
             height: 16px;
             transform: translate3d(0, -2px, 0);
            }
        }
    }

    .courses-limit-group{
        margin-bottom: 25px;

        .title{
            font-size: 14px;
            color: $grey;
            padding: 0;
        }

        label{
            border: none;
            color: $pink;
            background-color: transparent;
            cursor: pointer;
            padding-right:4px;
            font-size: 13px;
        }

        input{
            display: none;
        }

        span{
            font-size: 13px;
        }

        input:checked + label{
            font-weight: 800;
        }

        input:focus + label {
            outline: 2px solid $blue70;
        }
    }

    .courses-filter {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .button-wrapper {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include brk-mobile {
                flex-basis: 100%;
                .btn {
                    align-self: flex-end;
                    width: 160px;
                }
            }
        }

        .incoming-sprache{
            display: flex;
            flex-basis: 100%;
            margin-bottom: 15px;

            @include brk-mobile{
                margin-bottom: 0;
                flex-direction: column;
            }
        }

        .select-wrapper {
            margin-right: 8px;
            flex-basis: 16%;
            justify-content: space-between;
            max-width: 100%;
            @include brk-mobile {
                flex-basis: 100%;
                margin-right: 0;

                &#semester{
                    padding-right: 5px;
                }
            }

            &#incoming{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                min-width: 158px;

                h4{
                    flex-basis: 100%;
                }

                label{
                    margin-right: 5px;
                }

                div{
                    margin: auto 0;
                }
            }

            &#major{
                flex:1;
            }

            &#semester,
            &#credit{
                flex-basis: 8%;
                @include brk-mobile {
                    flex-basis: 50%;
                    margin-right: 0;
                }
            }

            .choices {
                width: auto;
                &:focus{
                    outline: 3px auto $outline;
                }
                &__inner {
                    border: none;
                    border-radius: 10px;
                    color: $darker-light-grey;
                    padding-left: 12px;
                    padding-right: 32px;
                }
                &__list {
                    border: none;
                    padding-left: 4px;
                    padding-right: 4px;
                    &--dropdown{
                        border: 1px solid rgba(155, 155, 155, 0.4);
                        border-top:none;
                        .choices__item--selectable.is-highlighted {
                            @include focus-pink-outline;
                        }
                        .choices__list{
                            display:flex;
                            flex-direction: column;
                        }
                        .choices__item:not(:last-child){
                            border-bottom: 1px solid rgba(155, 155, 155, 0.2);
                        }
                    }

                    &--single{
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                &__item{
                    line-height: 1.4;
                    &.is-highlighted {
                        outline: 2px solid $bright-pink;
                    }
                }
                &:after {
                    content: url('/assets/img/elements/ico_arrow_open.webp');
                    border: none;
                    right: 22px;
                    top: 48%;
                    transform: scale(0.6);
                }
                &.is-open:after {
                    transform: scale(0.6) rotate(180deg);
                    transform-origin: 8px 7px;
                }
            }
        }
    }

    .reset-button{
        font-size: 14px;
        text-decoration: underline;
        padding-right: 0;
        border:none;
        background: transparent;
        margin-bottom: 20px;
        cursor: pointer;
        margin-left: 0;
        &:after{
            content:'';
        }
    }

    .course-wrapper {
        border-bottom: 1px solid rgba(155, 155, 155, 0.2);
        padding-bottom: 15px;
        padding-top: 15px;
        width: 100%;
    }
    .headers {
        font-size: 14px;
        color: $grey;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .course {
        color: $grey;
        font-size: 20px;
        .course-title a {
            color: $pink;
            text-decoration: none;
            img {
                height: 12px;
            }
        }
    }
    .headers,
    .course {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        div {
            margin-left: 4px;
            flex: 2;
            display: flex;
            flex-direction: column;
            &.course-title {
                flex: 7;
                margin-left: 0;
            }
            &.open-btn {
                flex: 0.5;
                align-self: flex-end;
                text-align: right;
                display: block;
                img {
                    width: 22px;
                }
            }
            &.course-incoming img {
                width: 10px;
            }
            @include brk-mobile {
                &.course-title,
                &.open-btn {
                    flex: 100%;
                }
                &.open-btn {
                    text-align: center;
                }
                &.course-lang,
                &.course-ects,
                &.course-semester,
                &.course-plan {
                    flex-basis: 50%;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    margin-left: 0;
                }
                &.course-ects {
                    margin-left: 0;
                }
            }
        }

        .collapsible-btn {
            transition: all 300ms;
            border: none;
            padding: 0;
            background: transparent;
            &.active {
                transform: rotate(180deg);
            }
        }
    }

    .collapse-content {
        color: $grey;
        padding-bottom: 15px;
        padding-top: 15px;
        max-width: 800px;

        .rich-text{
            line-height: 1.5em;
            margin-bottom: 20px;
            ul{
                margin: 10px 0;
            }
            p {
                margin: 10px 0 20px 0;
            }
        }
    }


    .legend{
        display: flex;
        align-items: flex-end;
        padding: 0;

        @include brk-mobile{
            justify-content: center;
            margin-top: 5px;
        }

        P{
            font-size: 14px;
            color: $grey;
        }
    }

    .pagination {
        margin-top: 10px;
        a,
        span {
            text-decoration: none;
            color: lighten($blue57, 60%);
            font-size: 14px;
            font-weight: 400;
            margin-left: 3px;
            img {
                width: 8px;
            }
        }
        .current {
            color: $blue57;
        }
    }
}
