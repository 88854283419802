.text-slider {
    overflow: hidden;
    padding: 30px 0 15px 0;
    background: url(/assets/img/background/bg_slider.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    outline-offset: -6px;
    .row {
        align-items: flex-end;
        .text-slider-box {
            position: relative;
        }
        &.handlers {
            margin-top: 30px;
        }
        .slider-item {
            position: absolute;
            opacity: 0;
            top: 0;
            z-index: -1;
            transition: all 800ms ease-out;
            color: $blue70;
            width: 100%;
            &.active {
                opacity: 1;
                right: unset;
                position: static;
                z-index: 2;
                &-left {
                    animation: slide-in-left 800ms forwards;
                }
                &-right {
                    animation: slide-in-right 800ms forwards;
                }
            }
            &.left {
                animation: slide-out-left 800ms forwards;
            }
            &.right {
                animation: slide-out-right 800ms forwards;
            }
            h3 {
                font-size: 28px;
                margin-bottom: 28px;
                font-weight: 400;
                line-height: 1.2;
                p {
                    font-size: 28px;
                }
            }
            p {
                font-size: 18px;
                line-height: 24px;
            }
        }
        .text-slider-items {
            font-size: 18px;
            font-weight: 400;
            color: $blue70;
            padding-left: 15px;
            @include brk-mobile {
                font-size: 14px;
                padding-left: 0.5rem;
            }
        }
        .strip {
            background: $white;
            height: 2px;
            .pink-strip {
                background: $pink;
                height: 100%;
                width: 100%;
                &.animate {
                    animation: color-me-in 5s linear forwards;
                }
            }
        }
        .buttons {
            text-align: right;
            padding-right: 15px;
            @include brk-mobile {
                padding-right: 0.5rem;
            }
            img {
                width: 10px;
                cursor: pointer;
                height: auto
            }
        }
    }
    .text-slider-progress {
        margin-top: 10px;
    }
}

@keyframes color-me-in {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slide-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}
