.course-navigator {
    background: url(/assets/img/background/bg_studieren.webp) no-repeat center;
    background-size: cover;
    padding: 40px 0;
    color: $white;
    h3 {
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 400;
        letter-spacing: 0.8px;
    }
    &-text {
        margin-bottom: 25px;
        p {
            color: $white;
            font-size: 22px;
            line-height: 28px;

            span.lang-text {
                color: $white;
                font-weight: 500;
            }
        }
    }
    .btn{
        @include focus-pink-outline;
    }
}
