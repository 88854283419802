.breadcrumbs {
    padding: 20px 0.5rem;
    @include brk-mobile{
        padding: 20px calc(0.5rem + 10px);
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            padding-right: 15px;
            position: relative;
            color: $grey;
            font-size: 14px;
            line-height: 1.3rem;

            a {
                color: $blue70;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }

            a:after {
                content: "»";
                position: absolute;
                right: 5px;
                color: $grey;
            }
        }
    }
}
