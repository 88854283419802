.quote {
    @include focus-pink-outline;
    padding: 60px 0;
    background: url($img-path + '/background/bg_quote.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .quote-image {
        width: 100%;
    }

    .quote-text {
        background: $white;
        padding: 24px 20px;
        margin-bottom: 20px;
        position: relative;
        color: $blue70;
        line-height: 31px;
        font-size: 20px;

        .lang-text {
            color: $pink;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            left: 5%;
            top: 100%;
            z-index: 2;
            border-top: 20px solid $white;
        }
    }

    .quote-author {
        display: flex;
        align-items: center;
        margin-left: 50px;

        .author-image {
            width: 130px;
            height: 130px;
            border: 6px solid $very-light-blue;
            border-radius: 100%;
            flex-shrink: 0;
        }

        .author-name {
            margin-left: 12px;
            color: $white;
            font-size: 14px;
            line-height: 18px;

            a{
                color: $pink;
            }

            span:first-child {
                font-weight: 500;
            }
        }
    }
}

@include brk-mobile {
    .quote {
        padding: 30px 0;

        .quote-image {
            width: 12vw;
            margin-bottom: 20pt;
        }

        .quote-author {
            margin-left: 0;
        }

        .quote-text {
            font-size: 18px;
        }
    }
}
