.headline-styles {
    padding: 20px 0 10px 0;

    .headline2,.headline3 {
        color: $blue70;
        font-size: 26px;
        font-weight: 300;
        letter-spacing: 0.8px;
        @include brk-s-mobile{
            font-size: 20px;
        }
    }

    .headline3{
        font-size: 18px;
        font-weight: 500;
    }
}
