.blue-strip {
    background: $blue57;
}

.header-panel,
.header-panel_second {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_second {
        border-top: 1px solid rgba($white, 0.4);
        a {
            text-decoration: none;
            color: $white;
            padding: 0 6px;
            &.select {
                padding: 1px 4px;
                &:focus {
                    outline-offset: -3px !important;
                }
            }
            &.newsletter {
                display: flex;
                gap: 2px;
                align-items: center;
                font-weight: 500;
            }
        }
        .dropdown {
            border-right: none;
        }
    }
}

.socialmedia {
    display: flex;
    align-items: center;

    li {
        padding-left: 8px;
        padding-right: 8px;
        height: 30px;
        border-left: 1px solid rgba($white, 0.4);
        display: flex;
        align-items: center;
        @include focus-pink-outline;
        &:last-child {
            border-right: 1px solid rgba($white, 0.4);
        }
        &:focus {
            outline-offset: -5px;
            outline-width: 3px;
            outline-style: solid;
        }

        img {
            width: 15px;
            height: 15px;
        }

        .linkedin img {
            height: 15px;
        }
    }

    .newsletter {
        color: $white;
        text-decoration: none;

        &:after {
            content: "\003E";
            padding-left: 8px;
            transform: scaleX(0.5);
        }
    }
}

.lang-color {
    display: flex;
}

.color-contrast {
    border-left: 1px solid rgba($white, 0.4);
    width: 26px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include focus-pink-outline;
    button {
        border: none;
        background: transparent;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $white;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:focus {
            outline-offset: 2px;
            outline-width: 3px;
            outline-style: solid;
        }
        &:after {
            content: "";
            position: absolute;
            background: $white;
            height: 20px;
            width: 9px;
            left: -2px;
            top: -1px;
        }
    }
    @include brk-mobile {
        border: 1px solid $white;
        border-radius: 4px;
        margin-right: 10vw;
        height: 32px;
        width: 32px;
    }
}

.language-switch {
    position: relative;
    width: 100px;
}

.dropdown {
    // width: 40px;
    display: inline-block;
    transition: all 0.5s ease;
    position: relative;
    font-size: 12px;
    color: $white;
    border-left: 1px solid rgba($white, 0.4);
    border-right: 1px solid rgba($white, 0.4);
    text-align: left;
    @include focus-pink-outline;

    .select {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 30px;
        // padding-left: 10px;
        padding: 1px 2px;

        &:after {
            content: "";
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 3px solid $white;
            margin-left: 3px;
        }
    }

    a {
        text-decoration: none;
        color: $white;
        &.select:focus {
            outline-offset: -5px;
        }
        &:focus {
            outline-offset: 2px;
            outline-width: 3px;
            outline-style: solid;
        }
    }
}

.dropdown-menu {
    position: absolute;
    background-color: $blue57;
    width: 100%;
    left: 0;
    margin-top: 1px;
    overflow: hidden;
    display: none;
    max-height: 144px;
    overflow-y: auto;
    z-index: 9;

    button,
    span {
        @include font-roboto;
        font-size: 12px;
        border: none;
        background: transparent;
        color: white;
        padding: 8px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    &.show {
        display: flex;
        position: relative;
        background: transparent;
        button,
        span {
            list-style: none;
            &:focus {
                outline: 3px solid $bright-pink;
                outline-offset: -5px;
            }
            &:last-child {
                border-left: 1px solid hsla(0, 0%, 100%, 0.4);
            }
            &.active {
                text-decoration: underline;
            }
        }
    }

    .open & {
        display: block;
    }
}

.white-strip {
    background: #fff;

    .menu-wrapper {
        padding: 1rem 0.5rem 0 0.5rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            position: relative;
            align-self: flex-end;
            margin-bottom: -16px;

            a {
                display: flex;
                align-items: flex-end;
            }

            img {
                z-index: 25;
                // position: absolute;
                // margin-left: 10px;
                width: 170px;
                // height: auto;
                // top: -20px;
                &.en-logo {
                    max-width: 220px;
                    width: 220px;
                }
            }

            .koso {
                z-index: 24;
                position: absolute;
                top: 20px;
                width: 200px;
                height: 66px;
                margin-left: -12px;
                background: $white;
                transform: skewY(-10deg);
            }
        }

        .mobile-menu-focus {
            position: absolute;
            top: -5%;
        }

        .menu-search {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;

            .search-login {
                display: flex;
                justify-content: flex-end;
                .lang-login {
                    margin-top: 5px;
                }
                .search {
                    display: flex;
                    align-items: center;
                    form {
                        display: flex;
                    }
                    input {
                        padding: 8px 30px 8px 14px;
                        border: none;
                        background: $very-light-grey;
                        // height: 30px;
                        width: 220px;
                        border-radius: 10px;
                        font-size: 14px;
                        font-weight: 300;
                    }

                    .search-btn {
                        margin-left: -30px;
                        border: none;
                        background: transparent;
                        cursor: pointer;
                    }
                }

                .btn {
                    margin-left: 5px;
                    border: none;
                    padding: 7px 14px;

                    img {
                        margin-right: 5px;
                    }
                }
            }

            .menu {
                margin-top: 20px;
                .menu-list {
                    display: flex;
                    justify-content: flex-end;

                    .menu-item {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 1rem 6px;
                        @include brk-mobile {
                            padding: 1rem 18px;
                        }

                        .menu-item-link {
                            text-decoration: none;
                            font-weight: 500;
                            color: $blue70;
                            @include focus-pink-outline;
                            &:focus,
                            &:focus-within {
                                outline-offset: 2px;
                                outline-width: 3px;
                                outline-style: solid;
                            }
                        }

                        @include brk-mobile {
                            .sub-menu {
                                .row {
                                    // display: block;
                                    display: flex;
                                    flex-direction: column-reverse;
                                }

                                .sub-menu-item {
                                    margin: 10px 0;
                                    a {
                                        font-weight: 300;
                                        color: $blue57;
                                        text-decoration: none !important;

                                        @include focus-pink-outline;
                                    }

                                    &.four-blocks {
                                        a {
                                            display: block;
                                            margin: 10px 0;
                                            flex-basis: 50%;
                                            border: none;
                                            min-height: 90px;
                                            padding: 8px;
                                            max-height: 100px;

                                            // background: none !important;

                                            .menu-thumbnail-overlay {
                                                // display: none;
                                            }

                                            p,
                                            span {
                                                font-weight: 300;
                                                color: $white !important;
                                                text-decoration: none !important;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // @media (min-width: $brk-mobile+1px) {
                        &:hover,
                        &:focus-within,
                        &:focus {
                            background: $blue70;
                            color: $white;
                            @include brk-mobile {
                                background: $light-blue;
                            }

                            .menu-item-link {
                                color: $white;
                                position: relative;
                                display: flex;
                                align-items: center;

                                svg {
                                    margin-left: 6px;
                                }

                                &:after {
                                    content: "";
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border-left: 20px solid transparent;
                                    border-right: 20px solid transparent;
                                    left: 50%;
                                    top: 2rem;
                                    z-index: 50;
                                    transform: translateX(-50%);
                                    border-top: 20px solid $blue70;
                                    @include brk-mobile {
                                        content: none;
                                    }
                                }
                            }
                        }
                        &:hover > .sub-menu,
                        &:focus-within > .sub-menu,
                        &:focus > .sub-menu,
                        .sub-menu:hover {
                            visibility: visible;
                            opacity: 1;
                            display: block;
                        }
                        .sub-menu {
                            background: $very-light-grey;
                            visibility: hidden;
                            opacity: 0;
                            min-width: 100%;
                            position: absolute;
                            transition: all 0.5s ease;
                            margin-top: 1rem;
                            left: 0;
                            display: none;
                            z-index: 20;

                            @include brk-mobile {
                                background: transparent;
                                visibility: visible;
                                opacity: 1;
                                position: static;
                                display: block;
                            }

                            @include brk-desktop {
                                display: block !important;
                            }

                            .container {
                                padding: 1.5rem 0 0.5rem 0;

                                .four-blocks {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: stretch;
                                    flex-wrap: wrap;

                                    a {
                                        display: flex;
                                        text-decoration: none;
                                        color: $white;
                                        align-items: flex-end;
                                        padding: 12px;
                                        position: relative;
                                        min-height: 120px;
                                        margin-bottom: 10px;
                                        outline-offset: -2px;

                                        @include brk-mobile {
                                            padding: 12px;
                                            min-height: 110px;
                                            max-height: 120px;
                                            width: 50%;
                                        }

                                        @include brk-s-mobile {
                                            padding: 8px;
                                            min-height: 90px;
                                            max-height: 100px;
                                            padding-right: 16px;
                                        }

                                        &:focus {
                                            outline-style: solid;
                                            outline-width: 3px;
                                        }

                                        &:nth-child(2n + 1) {
                                            border-right: 5px solid
                                                $very-light-grey;
                                            @include brk-mobile {
                                                border-right-color: $light-blue;
                                            }
                                        }

                                        &:nth-child(2n) {
                                            border-left: 5px solid
                                                $very-light-grey;
                                            @include brk-mobile {
                                                border-left-color: $light-blue;
                                            }
                                        }

                                        .menu-thumbnail-overlay {
                                            background: rgba(2, 54, 112, 0.6);
                                            position: absolute;
                                            width: 100%;
                                            height: 100%;
                                            top: 0;
                                            left: 0;
                                        }

                                        span {
                                            z-index: 1;
                                            font-size: 16px;
                                            font-weight: 500;
                                            @include brk-mobile {
                                                font-size: 14px;
                                                font-weight: 400;
                                            }
                                        }

                                        .arrow {
                                            z-index: 1;
                                            position: absolute;
                                            right: 4px;
                                            bottom: 6px;
                                        }

                                        @include brk-mobile {
                                            // background-image: none !important;

                                            .menu-thumbnail-overlay {
                                                // display: none;
                                            }

                                            p,
                                            span {
                                                color: $white !important;
                                                text-decoration: none !important;
                                            }
                                        }
                                    }
                                }

                                ul {
                                    margin-right: 20px;
                                    @include brk-mobile {
                                        margin-right: 0;
                                    }

                                    .sub-menu-item {
                                        padding: 0 20px 20px 0;
                                        position: relative;
                                        display: flex;
                                        align-items: center;

                                        .arrow {
                                            margin-left: 6px;
                                        }

                                        a {
                                            text-decoration: none;
                                            color: $blue70;
                                            font-weight: 400;

                                            @include focus-pink-outline;

                                            @include brk-mobile {
                                                text-decoration: none !important;
                                            }
                                        }

                                        &:not(:last-child):after {
                                            content: "";
                                            background-color: $light-grey;
                                            position: absolute;
                                            height: 1px;
                                            width: 100%;
                                            bottom: 10px;
                                            left: 0px;
                                            @include brk-mobile {
                                                background-color: transparent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // }
                    }
                }
            }
        }
    }
}

.menu-wrapper .logo img {
    max-width: 170px;
}

.mobile-button {
    float: right;
    height: 100%;
    width: 65px;
    background: $light-blue;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 4px 0;

    &:focus,
    &:focus-visible {
        outline: 3px solid $bright-pink !important;
    }

    span {
        background: $white;
        width: 50%;
        height: 3px;
    }
}

.close-button {
    color: $white;
    font-size: 12px;
    cursor: pointer;
    img {
        width: 12px;
        margin-left: 3px;
        margin-bottom: -1px;
    }
}

.socialmedia-mobile {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid darken($border-blue, 10%);
    flex-shrink: 0;
    li {
        border-right: 1px solid darken($border-blue, 10%);
        flex: 1;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline-offset: -5px;

        img {
            max-width: 20px;
            max-height: 20px;
        }

        &:last-child {
            border: none;
        }
    }
}

@include brk-mobile {
    .blue-strip {
        // display: none;
    }

    .mobile-button:focus {
        @include focus-pink-outline;
    }

    .menu-wrapper {
        padding-top: 0;
        padding-right: 0;
        * {
            @include focus-pink-outline;
        }
        .mobile-button:focus {
            outline-offset: -6px;
            outline-style: solid;
            outline-width: 3px;
        }
        .logo {
            &:not(.logo-homepage) {
                img {
                    width: 75px;
                }

                .koso {
                    width: 110px;
                    height: 60px;
                }
            }
        }
    }

    .white-strip {
        height: 50px;

        .menu-wrapper {
            padding: 0 0 0 10pt;

            .logo {
                &:not(.logo-homepage) {
                    margin-bottom: -3px;

                    img {
                        width: 80px;
                        height: auto;
                    }

                    .koso {
                        width: 100px;
                        height: 32px;
                        margin-left: -6px;
                        top: 12px;
                    }
                }
                &.logo-homepage {
                    margin-bottom: -20px;
                    img {
                        width: 130px;
                        margin-bottom: -10px;
                        // orange logo
                        // margin-bottom: -5px;
                        // height: auto;
                    }
                    .koso {
                        height: 38px;
                        margin-left: -15px;
                        top: 34px;
                        // orange logo
                        // top: 12px;
                    }
                }
            }

            .menu-search {
                top: -100%;
                left: 0;
                position: absolute;
                width: 100vw;
                background: $light-blue;
                justify-content: flex-start;
                display: none;
                z-index: 9999;
                height: calc(var(--vh, 1vh) * 100);
                overflow: scroll;
                &.show {
                    position: fixed;
                    display: flex;
                    animation: slideIn 0.5s ease forwards;
                }
                &.hide {
                    animation: slideOut 0.5s ease forwards;
                }
                & > div:first-child {
                    flex: 1 0 auto;
                    position: relative;
                }
                .teaser-items-mobile {
                    margin: 10px 0 0 0;
                    .teaser-items-mobile-item {
                        background: darken($light-blue, 10%);
                        padding: 20px;
                        text-align: center;
                        margin-bottom: 3px;
                        font-size: 16px;
                        color: $white;
                        font-weight: 400;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        outline-offset: -5px;
                        &:nth-child(2n + 1) {
                            border-right: $light-blue solid 1.5px;
                        }
                        &:nth-child(2n) {
                            border-left: $light-blue solid 1.5px;
                        }
                    }
                    .home-buttom {
                        background: darken($light-blue, 5%);
                        padding: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: $white;
                        font-weight: 400;
                        text-decoration: none;
                        outline-offset: -5px;
                    }
                }
                .search-login {
                    flex-direction: column;
                    align-items: center;
                    padding: 0 20px;
                    .search {
                        input {
                            color: $white;
                            background: $light-blue;
                            border: 1px solid $white;
                        }
                        ::placeholder {
                            color: $white;
                            opacity: 1;
                        }
                    }
                    .lang-login {
                        margin: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        order: -1;
                        width: 100%;
                        .btn {
                            background: transparent;
                            padding: 0;
                        }
                        .dropdown {
                            border: none;
                            width: auto;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            @include focus-pink-outline;
                            a {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 16px;
                                height: auto;
                                &.select:focus {
                                    outline-offset: 0px;
                                }
                            }
                            .dropdown-menu {
                                background: $light-blue;
                                border: none;
                                padding: 2px;
                                text-align: center;
                                button {
                                    font-weight: 500;
                                    font-size: 14px;
                                    padding: 1px;
                                }
                            }
                        }
                    }
                }
                .menu {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: center;
                    margin-top: 0;
                    .menu-list {
                        display: block;
                        .menu-item {
                            border-bottom: 1px solid darken($border-blue, 10%);

                            &-dropdown {
                                display: flex;
                                justify-content: space-between;

                                &.active svg {
                                    transform: rotate(90deg);
                                }

                                svg {
                                    margin-right: 20px;
                                    transition: all 300ms;
                                }
                            }
                            .menu-item-link {
                                color: $white;
                                display: flex;
                                align-items: center;
                                font-size: 20px;

                                svg {
                                    margin-left: 6px;
                                }
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                    .sub-menu {
                        // display: none;
                    }
                }
            }
        }
    }
}

@keyframes slideIn {
    from {
        top: -50%;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        top: 0;
        opacity: 1;
    }
    100% {
        top: -50%;
        opacity: 0;
    }
}
