.video-template {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    max-width: 376px;
    margin-left: auto;
    margin-right: auto;

    .video-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .video-image {
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;
    }

    .video-play {
        $size: 100px;
        position: absolute;
        z-index: 3;
        width: $size;
        margin-left: -($size / 2);
        margin-top: -($size / 2);
        top: 50%;
        left: 50%;
        @include focus-pink-outline;
    }

    &[data-playing="true"] {
        .video-frame {
            z-index: 3;
        }

        .video-play {
            display: none;
        }
    }

    &[data-playing="false"]:hover {
        cursor: pointer;

        .video-play {
            opacity: .8;
        }
    }
}
