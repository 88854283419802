$height-brk: 460px;

.compass-navigation-wrapper {
    display: none;
    @include brk-mobile {
        display: flex;
    }
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $white;
}

section.compass-navigation {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 999;
    display: none;
    // max-width: 500px;
    max-height: 900px;
    overflow: hidden;

    @include brk-mobile {
        display: block;
    }

    .mobile-button {
        position: absolute;
        height: 50px;
        right: 0;
        top: 70px;
        z-index: 9;
    }

    .top-strip {
        position: absolute;
        background-color: $blue70;
        width: 114%;
        height: 128px;
        transform: rotateZ(-10deg) translate3d(-34px, -75px, 0);
        @media (max-height: $height-brk) {
            height: 320px;
        }
        @media (max-width: $height-brk) {
            width: 130%;
        }
    }

    .logo-strip {
        position: absolute;
        right: 45px;
        bottom: 63vw;
        z-index: 20;

        @media (min-width: 319px) {
            bottom: 70vw;
        }

        @media (min-width: 400px) {
            bottom: 58vw;
            right: 50px;
        }
        @media (min-width: 500px) {
            bottom: 52vw;
            right: 60px;
        }
        @media (min-width: 640px) {
            bottom: 47vw;
        }

        img {
            width: 158px;
            height: auto;
        }
        @media (max-height: $height-brk) {
            img{
                display: none;
            }
            .white-logo{
                display: block;
                z-index: 10;
            }
        }
    }

    .bottom-strip {
        position: absolute;
        bottom: 0;
        left: 0;
        color: $white;
        text-align: right;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        padding-bottom: 138px;

        @media (max-width: 321px) {
            padding-bottom: 32vh;
        }

        &:after {
            position: absolute;
            width: 100%;
            height: 138px;
            background-color: $blue70;
            content: " ";
            left: 0;
            bottom: 0;

            @media (max-width: 321px) {
                height: 32vh;
            }

        }

        img.bottom-strip-text {
            width: 180px;
            margin-top: 13px;
            position: absolute;
            top: 45px;
            right: 30px;
            z-index: 1;
            @media (max-height: $height-brk) {
                right: 80px;
                top: 110px;
            }
        }

        img.bottom-strip-facets {
            width: 100%;
            display: block;
            height: auto;
        }
    }

    .compass-direction {
        position: absolute;
        z-index: 999;
        background-repeat: no-repeat;
        background-size: cover;

        .compass-header > div {
            position: absolute;
            background-color: rgba($blue57, 0.35);
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding: 35px;
            line-height: 1.2;

            h2 {
                color: $white;
                @include font-roboto;
                font-weight: bold;
                font-size: 24px;
            }
        }

        .navigation-info {
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $white;
            font-weight: bold;
            font-size: 13px;

            span {
                opacity: 0;
            }

            img {
                opacity: 0;
                padding-top: 10px;
                width: 20px;
                transform: rotate(180deg);

                &.switch {
                    transform: rotate(0);
                }
            }
        }

        &.dir-bottom .navigation-info {
            top: 10px;
            bottom: auto;
            flex-direction: column-reverse;

            img {
                transform: rotate(0deg);

                &.switch {
                    transform: rotate(180deg);
                }
            }
        }

        &.dir-right .navigation-info {
            transform: rotate(-90deg);
            justify-content: center;
            width: 200px;
            top: 0;
            pointer-events: none;
            left: initial;
            right: 64px;
            flex-direction: column-reverse;

            img {
                transform: rotate(0deg);
                padding-bottom: 10px;
                padding-top: 0;

                &.switch {
                    transform: rotate(180deg);
                    padding-bottom: 0;
                    padding-top: 10px;
                }
            }
        }

        &.dir-left .navigation-info {
            transform: rotate(270deg);
            justify-content: center;
            width: 200px;
            top: 0;
            pointer-events: none;
            left: 64px;
            flex-direction: column;

            img {
                transform: rotate(180deg);
                padding-top: 0;
                padding-bottom: 10px;

                &.switch {
                    transform: rotate(0deg);
                    padding-top: 10px;
                    padding-bottom: 0;
                }
            }
        }
    }

    .compass-nav-helper {
        position: absolute;
        z-index: 10;
        color: $white;
        font-weight: bold;
        line-height: 1.3;
        min-height: 39px;

        a {
            color: $white;
            text-decoration: none;

            &:focus {
                outline: 2px dashed $white;
            }
        }

        &-top {
            left: 110px;
            top: 35px;
            max-width: 240px;
            min-height: 0;

            &:before {
                position: absolute;
                left: -31px;
                width: 1px;
                height: 49px;
                background-color: $white;
                content: " ";
                top: -35px;
            }

            img {
                position: absolute;
                top: 3px;
                left: -23px;
                width: 14px;
                height: auto;
            }
        }

        &-right {
            right: 40px;
            bottom: 56px;
            max-width: 130px;

            &:before {
                position: absolute;
                right: -40px;
                width: 28px;
                height: 1px;
                background-color: $white;
                content: " ";
                top: -2px;
            }

            img {
                position: absolute;
                top: 7px;
                right: -23px;
                width: 14px;
                height: auto;
                transform: rotate(90deg);
            }
        }

        &-bottom {
            left: 110px;
            bottom: 10px;
            max-width: 240px;
            min-height: 0;

            &:before {
                position: absolute;
                left: -31px;
                width: 1px;
                height: 28px;
                background-color: $white;
                content: " ";
                top: 2px;
            }

            img {
                position: absolute;
                top: 3px;
                left: -23px;
                width: 14px;
                height: auto;
                transform: rotate(180deg);
            }
        }

        &-left {
            left: 40px;
            bottom: 56px;
            max-width: 102px;

            &:before {
                position: absolute;
                left: -40px;
                width: 28px;
                height: 1px;
                background-color: $white;
                content: " ";
                top: -2px;
            }

            img {
                position: absolute;
                top: 7px;
                left: -23px;
                width: 14px;
                height: auto;
                transform: rotate(-90deg);
            }
        }
    }

    .dir-left,
    .dir-right {
        height: 100%;
        width: 200px;
    }

    .dir-top,
    .dir-bottom {
        height: 200px;
        width: 100%;
    }

    .dir-left {
        left: 0;
        top: 0;
        transform: translate3d(-200px, 0, 0);

        h2 {
            transform: rotate(270deg);
        }
    }

    .dir-right {
        right: 0;
        top: 0;
        transform: translate3d(200px, 0, 0);

        h2 {
            transform: rotate(270deg);
        }
    }

    .dir-top {
        top: 0;
        left: 0;
        transform: translate3d(0, -200px, 0);
    }

    .dir-bottom {
        bottom: 0;
        left: 0;
        transform: translate3d(0, 200px, 0);
    }
}
