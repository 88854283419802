.company-thumbnail-slider {
    padding: 10px;
    overflow: hidden;
    
    .row {
        position: relative;
        @media (max-width: 1300px) {
            width: 90%;
            margin: 0 auto;
        }
        @include brk-mobile {
            width: 92%;
        }
    }
    .layout2main {
        width: 92%;
        margin: 0 auto;
    }
    .layout2menu {
        width: 86%;
        margin: 0 auto;
    }
    .company-thumbnail-slider-wrapper {
        overflow: hidden;
        padding: 2px;
        .img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            &.prev {
                left: -30px;
                @include brk-small {
                    left: -20px;
                }
            }
            &.next {
                right: -30px;
                @include brk-small {
                    right: -20px;
                }
            }
        }
        @include brk-small {
            padding: 0 2px;
        }
        .company-thumbnail-slider-box {
            display: flex;
            transition: all 1s;
            .company-thumbnail-slider-item {
                padding: 5px;
                display: inline-block;
                width: 130px;
                height: 130px;
                border: 1px solid $light-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                outline-offset: -5px;
                @include brk-mobile {
                    width: 95px;
                    height: 95px;
                }
                .company-logo {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
