.career {
    @include focus-pink-outline;
    background: $blue70 url(/assets/img/background/bg_karriere.webp) center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 95px 0;
    color: $white;
    h4 {
        font-size: 18px;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 28px;
        margin-bottom: 30px;
    }
}
