.header-image {
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    position: relative;

    .main-img {
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (min-width: 1450px) {
            min-height: 100%;
            min-width: 100%;
            height: auto;
        }
    }

    .container {
        position: relative;
        .header-image-overlay-img {
            left: 0;
            transform: translate(0);
            max-width: 500px;
            position: absolute;
            height: auto;
            bottom: 50%;
            @include brk-mobile {
                top: 0;
                bottom: 18%;
                max-width: 75%;
                left: 8px;
                max-height: 100%;
            }
        }
    }

    h1 {
        z-index: 10;
        font-size: 24px;
        color: $white;
        margin-bottom: 20px;
        max-width: 52%;
    }

    .box {
        position: relative;
        height: 220px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        &:after {
            content: "";
            position: absolute;
            width: 300%;
            height: 179.5px;
            background: $blue70;
            right: -314.5%;
        }
    }

    .triangle {
        width: 115%;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 180px;
        background: linear-gradient(
            to top left,
            $blue70 49.6%,
            transparent 50%
        );
        position: absolute;
        overflow: hidden;
    }
}

@include brk-mobile {
    .header-image {
        min-height: 240px;

        .container {
            width: 100%;

            h1 {
                font-size: 14px;
                max-width: 180px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }

        .triangle {
            height: 110px;
            width: 100%;
            left: 0;
            position: absolute;
            overflow: hidden;
            background-image: url($img-path + "/elements/triangle.webp");
            background-position-x: left;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
