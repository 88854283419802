.pdf-preview{
  margin: 10px 0;

  a{
    color: $pink;
    font-weight: 300;
  }

  .pdf-cont{
    display: flex;
    .left{
      margin-right: 20px;
      img{
        width: 70px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        margin-bottom: 10px;
        color: $grey;
      }
    }
  }

  .pdf-container{
    max-width: 1000px;
    margin: 0 auto;
    width:100%;
    height:600px;

    @include brk-small{
      height:500px;
    }
    @include brk-mobile{
      height:410px;
    }
    @media (max-width:550px) {
      height:340px;
    }
    @media (max-width:450px) {
      height:270px;
    }
  }
}
