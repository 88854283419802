.quicklinks {
    padding: 20px 0;
    position: relative;
    &.collapse {
        .collapse-content {
            @include  brk-mobile{
                display: none;
            }
        }
        .collapsible-btn {
            border: none;
            background: transparent;
            transform-origin: center 35%;
            transition: transform 0.6s;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    .quicklinks-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    h4 {
        color: $blue70;
        font-size: 26px;
        display: inline-block;
    }
    label {
        float: right;
        color: $white;
        font-size: 26px;
        transition: all .6s ease;
        @include brk-desktop {
            display: none;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        transform: scale(0.1);
        top: 20px;
        right: 10px;
    }
    .quicklinks-links {
        flex-basis: 100%;
        li{
            padding: 8px 0;
            border-bottom: 1px solid #ececec;
            &:first-child{
                margin-top:10px;
                border-top: 1px solid #ececec;  
            }
            a {
                color: $light-blue;
                font-weight: 400;
                text-decoration: none;
                display: block;
                display: flex;
                justify-content: space-between;
                align-items: center;   

                @include brk-mobile{
                    color: $white;
                    outline-offset: -3px;
                }
            }
        }
    }
}

@include brk-mobile {
    .quicklinks {
        @include focus-pink-outline;
        background: $light-blue;
        
        h4 {
            color: $white;
            max-width: 80%;
        }
    }
}
